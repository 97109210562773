.drop-numbers {
  @include displayFlex;
  @include justifyContent(space-between);
}

.dropnumber {
  font-size: rem-calc(13);

  > .details, > .count {
    display: block;
  }

  > .count {
    font-size: rem-calc(32);
    font-weight: 300;
  }
  > .details {
    margin-top: rem-calc(3);
    line-height: 1.36;
    max-width: rem-calc(80);
  }
}

[dir="RTL"] .drop-numbers > .data + .data {
  margin-right: rem-calc(36);
  margin-left: 0;
}
