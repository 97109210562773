@import "../../common-style/css/settings";
@import "../../common-style/css/mixins";
@import "../../common-style/css/functions";
@import "../../common-style/css/inter";
@import "../../common-style/css/normalize";
@include normalize();
@import "../../common-style/css/base";
@import "../../common-style/css/objects";
@import "../../common-style/css/components";
.plans-progress {
  padding-left: rem-calc(30);
  padding-top: rem-calc(32);
  padding-right: rem-calc(30);
  .prog-search {
    width: 550px;
    padding-top: rem-calc(8);
  }
  .cohort-status {
    padding-top: rem-calc(24);
    .active-cohort-count-details {
      padding-bottom: rem-calc(4);
      .cohort-labels {
        color: rgb(74, 74, 74);
        font-size: rem-calc(14);
        font-weight: normal;
      }
    }
  }
}

.program-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.prog-name {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 47px;
  margin: 0 auto;
  font-size: 14px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.program-col {
  margin-bottom: rem-calc(24);
}

.widget-title {
  margin-bottom: rem-calc(8);
  font-size: rem-calc(22);
  font-weight: 500;
}

.program-card {
  height: 100%;
  padding: rem-calc(20 24);
  .program-title {
    font-weight: 600;
    font-size: rem-calc(18);
    padding-bottom: rem-calc(16);
  }
  .create-timeline-text {
    padding-bottom: rem-calc(20);
  }
  .create-timeline-btn {
    padding-left: rem-calc(100);
    @media only screen and (max-width: 1280px) {
      padding-left: 0;
      text-align: center;
    }
  }
}

.help-text {
  padding-top: rem-calc(4);
}

.upload-help-text {
  padding-top: rem-calc(4);
  padding-bottom: rem-calc(4);
}

.program-header {
  .bread-crumbs {
    margin-bottom: rem-calc(4);
    a:hover:not(.button):not([role]) {
      padding-bottom: 0;
    }
    .link {
      font-weight: bold;
      display: inline-flex;
      span {
        line-height: normal;
      }
      &::after {
        vertical-align: top;
      }
    }
  }
}

.edit-outline-card {
  padding: 0 !important;
  .header {
    &.section-header {
      padding: rem-calc(0 22 0 0);
      margin: 0;
      border-bottom: 1px solid rgb(46, 27, 70);
      .button-collection {
        padding: 0;
      }
    }
    .section-title {
      padding-left: rem-calc(4);
    }
  }
  .drag-svg-subsec {
    padding: rem-calc(34 20 34 24);
  }
  .drag-svg-section {
    padding: rem-calc(24 20 24 24);
  }
  .drag-svg-subsec,
  .drag-svg-section {
    &:focus {
      outline: none;
      border: none;
      background: #413c70;
      path {
        fill: #fff;
      }
    }
  }
  .body {
    &.section-requirements {
      margin: 0;
    }
    .subsection-label {
      padding: rem-calc(24 48 4 6);
      width: rem-calc(690);
      min-width: rem-calc(690);
      max-width: rem-calc(690);
    }
    .show-reqts {
      &:last-child {
        padding-bottom: rem-calc(24);
      }
    }
    &.no-requirements {
      margin: 0 !important;
    }
    &.sub-section {
      .no-requirements {
        padding-left: rem-calc(6);
      }
      .long-label {
        padding-bottom: rem-calc(24);
      }
    }
  }
  .button-collection {
    padding: rem-calc(24 22 0 0);
  }
  .-contnr {
    width: 100%;
    border-top: 1px solid rgb(222, 222, 222);
  }
}

.edit-outline-content {
  &.dragIsStarted:hover {
    .section-header,
    .subsection-content {
      &:hover {
        background: rgb(255, 255, 255);
      }
    }
  }
  .draggable-element {
    .section-header,
    .subsection-content {
      &:hover {
        cursor: pointer;
        background: rgb(232, 231, 244);
      }
      &:active {
        cursor: grabbing;
      }
    }
    &.keyboard-grabbed {
      box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%),
        0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
    }
  }
}

@media screen and (min-width: 1024px) {
  .edit-outline-content,
  .edit-outline-card {
    width: rem-calc(820) !important;
    max-width: rem-calc(820) !important;
    min-width: rem-calc(820) !important;
  }
  .licensure-plans-timeline {
    &.card-width,
    .card-width {
      width: rem-calc(820);
      max-width: rem-calc(820);
      min-width: rem-calc(820);
    }
  }
}

.licensure-plans-timeline {
  padding: rem-calc(40 0 64 0);
  .plan-title {
    padding: 0;
    color: rgb(74, 74, 74);
    .plan-title-label {
      padding: rem-calc(24 26 12);
      margin: 0;
      font-size: rem-calc(14);
      font-weight: 600;
      text-transform: capitalize;
    }
    .timeline-title {
      font-size: rem-calc(22);
      font-weight: normal;
      margin: 0;
      padding: rem-calc(0 26 24);
      text-transform: initial;
    }
    #edit-timeline-title {
      padding: rem-calc(34 24 32 0);
    }
  }
  .timeline-section {
    .header {
      padding: rem-calc(0 24);
    }
    .title {
      padding-bottom: rem-calc(20);
      margin: 0;
    }
    .body {
      padding: rem-calc(24);
    }
  }
  .requirement-contnr {
    border-bottom: 1px solid rgb(222, 222, 222);
    width: 100%;
    .requirement-type {
      color: rgb(80, 108, 124);
      font-weight: 600;
      background: rgba(127, 151, 173, 0.15);
      border-radius: rem-calc(5);
      padding: rem-calc(4 10);
      text-transform: uppercase;
      margin-top: rem-calc(4);
      display: inline-block;
    }
    .button-collection {
      padding: rem-calc(25 20 0 96);
    }
  }
  .requirement-details {
    width: rem-calc(608);
    min-width: rem-calc(608);
    max-width: rem-calc(608);
    padding-top: rem-calc(25);
    padding-left: rem-calc(4);
    + .requirement-details {
      padding-top: rem-calc(16);
      border-top: 1px solid rgb(222, 222, 222);
    }
    .requirement-header {
      padding-bottom: rem-calc(4);
      .requirement-title {
        color: rgb(74, 74, 74);
        font-size: rem-calc(14);
        font-weight: 600;
        line-height: rem-calc(20);
      }
    }
    .requirement-description {
      color: rgb(74, 74, 74);
      font-size: rem-calc(14);
      line-height: rem-calc(20);
      font-weight: normal;
    }
    &:last-child {
      padding-bottom: rem-calc(24);
    }
  }
  .sub-section-list {
    .sub-section {
      background: rgb(244, 243, 246);
      margin: rem-calc(24 24 0 57);
      padding: rem-calc(10 0 26 0);
      .requirement-details {
        width: rem-calc(544);
        min-width: rem-calc(544);
        max-width: rem-calc(544);
      }
      .accordion-label {
        color: rgb(74, 74, 74);
        font-size: rem-calc(16);
        line-height: rem-calc(24);
        padding-left: rem-calc(21);
        font-weight: 500;
      }
      .sub-add-btns {
        padding-left: rem-calc(26);
      }
      .button-collection {
        padding: rem-calc(25 25 0 86);
      }
    }
  }
}

.ghost-element-container {
  position: relative;
  display: none;

  .box {
    width: rem-calc(612);
    height: rem-calc(72);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: rem-calc(4);
    z-index: 3;
    background: rgb(229, 229, 239);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    padding: rem-calc(24 28 24 64);
    font-weight: 600;
  }
  .box:before {
    content: "";
    background: url("#{$staticpath}src/images/drag-drop.svg") no-repeat;
    width: rem-calc(24);
    height: rem-calc(24);
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: rem-calc(28);
  }

  .stack-top {
    width: rem-calc(600);
    height: rem-calc(72);
    z-index: 2;
    margin: rem-calc(8);
    background: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
  }

  .stack-top-top {
    width: rem-calc(588);
    height: rem-calc(72);
    z-index: 1;
    margin: rem-calc(16);
    background: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
  }
}

.view-timeline {
  &.dragIsStarted:hover {
    .sub-section-body {
      .drag-zone {
        &:hover {
          background: rgb(244, 243, 246);
        }
      }
    }

    .drag-zone {
      &:hover {
        background: rgb(255, 255, 255);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  #edit-timeline-flyout,
  .view-timeline {
    .immersive-container {
      &.flyout-data {
        height: -webkit-calc(100vh - 90px);
      }
    }
  }
  .licensure-plans-timeline {
    .requirement-details {
      width: rem-calc(416) !important;
      min-width: rem-calc(416) !important;
      max-width: rem-calc(416) !important;
    }
    .sub-section-list .sub-section .requirement-details {
      width: rem-calc(352);
      min-width: rem-calc(352);
      max-width: rem-calc(352);
    }
    .requirement-contnr {
      .button-collection {
        padding: rem-calc(25 9 0 96);
      }
    }
    .sub-section-list .sub-section {
      .button-collection {
        padding: rem-calc(25 13 0 14);
      }
    }
  }
  .edit-outline-card .body .subsection-label {
    width: rem-calc(490);
    min-width: rem-calc(490);
    max-width: rem-calc(490);
  }
}

#edit-title-modal {
  .info-text {
    color: rgb(74, 74, 74);
    font-size: 14px;
    font-weight: normal;
    font-style: italic;
  }
  .modal-body {
    color: rgb(74, 74, 74);
    font-size: 14px;
    font-weight: 500;
  }
}

.title-conatiner {
  padding: rem-calc(0 24 8 24);
  border-bottom: 1px solid rgb(46, 27, 70);
  .title {
    color: rgb(74, 74, 74);
    font-size: rem-calc(18);
    font-weight: 600;
  }
}

.panels {
  @include flex(1);
  min-height: 0;
  .mini-panel {
    @include displayFlex;
    @include flexDirection(column);
    @include flex(none);
    text-align: left;
    margin-left: rem-calc(30);
    background: #ffffff;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    @include box-shadow(0 0 14px 0 rgba(0, 0, 0, 0.14));
    margin-left: 0;
    width: rem-calc(480);
    z-index: 13;
    &.immersive-container {
      height: calc(100vh - 90px);
      height: -webkit-calc(100vh - 90px);
      overflow-y: auto;
      overflow-x: hidden;
      &.flyout-data {
        @media screen and (max-width: 1024px) {
          height: auto;
        }
      }
    }
    .paper-card {
      height: calc(100vh - 180px);
      height: -webkit-calc(100vh - 167px);
      overflow-y: auto;
      overflow-x: hidden;
      box-shadow: none;
      &.flyout-data {
        @media screen and (max-width: 1024px) {
          height: auto;
        }
      }
    }
    .mini-header {
      .title,
      .close-btn {
        margin: 0.75rem 0.875rem 0;
        color: rgb(74, 74, 74);
        font-size: rem-calc(18);
        font-weight: 600;
        line-height: 26px;
        padding: rem-calc(10 20);
      }
      border-bottom: 1px solid rgb(231, 231, 231);
    }
    .body {
      margin-top: -1.25rem;
      .requirement-title {
        color: rgb(74, 74, 74);
        font-size: rem-calc(16);
        font-weight: 500;
        line-height: rem-calc(24);
      }
      .description-field {
        padding-top: 0;
      }
    }
    .list-conditions {
      padding-left: 0;
    }
    ul {
      margin: 0;
      padding: rem-calc(0 16);
    }
    .confirmation-container {
      padding-left: rem-calc(16);
      padding-top: rem-calc(8);
      display: flex;
      .confirmation-label-text {
        display: block;
        color: rgb(74, 74, 74);
        font-size: rem-calc(14);
        font-style: italic;
        font-weight: normal;
        padding: rem-calc(12);
        background: rgb(250, 250, 250);
      }
    }
    .footer {
      padding: rem-calc(20 30 0);
      border-top: none;
      @include displayFlex();
      @include justifyContent(space-between);
      @include alignItems(center);
      min-height: 5rem;
    }
  }
  .licensure-pagecontent {
    @include displayFlex;
    @include flexDirection(column);
    padding: 0;
    overflow-y: auto;
    width: 100%;
  }
  .mini-header {
    .title,
    .close-btn {
      margin: 0.75rem 0.875rem 0;
      color: rgb(74, 74, 74);
      font-size: rem-calc(18);
      font-weight: 600;
      line-height: 26px;
      padding: rem-calc(10 20);
    }
    border-bottom: 1px solid rgb(231, 231, 231);
  }
}

.immersive-container {
  height: calc(100vh - 92.8px);
  height: -webkit-calc(100vh - 92.8px);
  overflow-y: auto;
  overflow-x: hidden;
  &.flyout-data {
    @media screen and (max-width: 1024px) {
      height: auto;
    }
  }
}

.card-width {
  width: rem-calc(616);
  max-width: rem-calc(616);
  min-width: rem-calc(616);
  margin: auto;
}

.-card-width {
  width: 40.6%;
  margin: auto;
  @media screen and (max-width: 1200px) {
    width: 60%;
  }
}

.timeline-card {
  padding-bottom: rem-calc(8);
  text-align: right;
}

.paper-card {
  &.drag-card {
    padding: 0;
    .header {
      margin-left: 0;
      margin-right: 0;
      padding-top: rem-calc(20);
    }
    .body {
      padding-top: 0;
    }
    .title-tab:focus-visible {
      outline: none;
    }
  }
  &.section-card {
    margin-bottom: rem-calc(20);
    padding-bottom: 0;

    &.edit {
      margin-bottom: rem-calc(24);
    }

    .collapsed {
      border-bottom: none;
    }
  }
  .add-btns {
    padding: rem-calc(24 0 0 24);
  }
  .sub-add-btns {
    margin-top: rem-calc(24);
  }
}

.-textonly {
  &.accordion-label {
    font-weight: 600;
    color: #575195;
    font-size: rem-calc(18);
    margin: 0;
    display: inline;
    cursor: pointer;
  }
}

.-icononly {
  color: #575195;
  cursor: pointer;
  border: 0;
  margin-right: rem-calc(5);
  background-color: transparent;
}

.section-button {
  margin-left: rem-calc(-16);
  &:hover {
    background: none;
    color: #575195;
  }
}

.sub-section-body {
  padding-top: rem-calc(16);
}

.program-link {
  color: rgb(87, 81, 149);
  font-size: rem-calc(18);
  font-weight: bold;
  &:hover:not(.button):not([role]) {
    padding-bottom: 0;
  }
}

.cohort-count-status {
  margin-top: rem-calc(16);
  .cohort-count-details {
    margin-top: 0;
    margin-bottom: rem-calc(4);
    .counter {
      font-weight: 600;
      &.cohort-published {
        color: rgb(8, 128, 0);
      }
      &.cohort-archived {
        color: rgb(80, 108, 124);
      }
      &.cohort-scheduled {
        color: rgb(65, 89, 105);
      }
    }
  }
}

.requirement-link {
  color: rgb(87, 81, 149);
  font-weight: 700;
}

.flyout-container {
  .flyout-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: rem-calc(24);
    font-weight: 600;
  }
  .-flyout-subheading {
    font-weight: 300;
  }
  .bread-crumb-text,
  .edit-timeline-subheading {
    font-weight: 400;
  }
}

.title-desc {
  font-size: rem-calc(14);
  font-weight: 400;
}

.btn-background {
  background-color: #fafafa;
}

.btn-color {
  color: rgb(87, 81, 149);
  position: relative;
  font-weight: bold;
  margin-bottom: rem-calc(24);
  text-align: center;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    height: 2.2px;
    background: linear-gradient(90deg, #575195 70%, transparent 0) repeat-x;
    background-size: 11.2px 2.2px;
  }
}

.flyout-content {
  padding-top: rem-calc(40);
  max-width: rem-calc(612);
  margin: auto;
  .section-header .title {
    margin: 0;
  }
  &.no-errors {
    height: calc(100vh - 117.8px);
    height: -webkit-calc(100vh - 117px);
    overflow: auto;
    max-width: none;
    padding-bottom: rem-calc(64);
  }
  &.-errors {
    height: calc(100vh - 136.8px);
    height: -webkit-calc(100vh - 136.8px);
    overflow: auto;
    max-width: none;
    &.flyout-data {
      @media screen and (max-width: 1024px) {
        height: auto;
      }
    }
  }
}

.subsection-label {
  font-size: rem-calc(16);
  font-weight: 500;
}

.modal-body {
  padding: rem-calc(24 32);
}

.textfieldlabel {
  font-size: rem-calc(14);
  font-weight: 600;
  padding-bottom: rem-calc(4);
}

.add-sub-section {
  border-top: 1px solid rgb(222, 222, 222);
  margin: rem-calc(0) !important;
  // padding-left: rem-calc(1) !important;
}

.no-sections-info {
  font-style: italic;
  margin: 0;
}

.max-count-label {
  display: none;
}

.option {
  display: inline-block;
  flex: 1 1 0px;
  margin-right: rem-calc(24);
  .option-box {
    width: 100%;
    display: inline-block;
    background: rgb(255, 255, 255);
    height: rem-calc(140);
    border-radius: rem-calc(5);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
    margin-bottom: rem-calc(8);
    &:hover {
      background: rgb(233, 231, 236);
    }
    .mdi {
      text-align: center;
      padding-left: rem-calc(8);
      padding-right: rem-calc(8);
      padding-top: rem-calc(32);
      color: rgb(87, 81, 149);
      font-weight: bold;
      &:before {
        font-size: rem-calc(28);
        display: block;
        padding-bottom: rem-calc(16);
        color: rgb(87, 81, 149);
      }
    }
    &.-disabled {
      box-shadow: none;
    }
  }
  &:last-child {
    margin-right: 0;
  }
  input[type="radio"] {
    clip-path: polygon(0 0);
    position: fixed;
    &:focus + .option-box {
      box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%),
        0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
      outline: none;
    }
    &:checked + .option-box {
      background: rgb(87, 81, 149);
      .mdi {
        color: rgb(255, 255, 255);
        &:before {
          color: rgb(255, 255, 255);
        }
      }
    }
    &:disabled + .option-box {
      background: rgb(250, 250, 250);
      .mdi {
        color: rgb(107, 107, 107);
        &:before {
          color: rgb(107, 107, 107);
        }
      }
    }
  }
}

.condition-msg {
  background: rgb(232, 231, 244);
  text-align: center;
  color: rgb(87, 81, 149);
  font-style: italic;
  margin: rem-calc(0 -30);
  padding: rem-calc(11);
}

.condition-types {
  &:first-child {
    padding-bottom: rem-calc(20);
    border-bottom: 1px solid #747474;
    margin-bottom: rem-calc(20);
  }
  .condition-name {
    padding-bottom: rem-calc(8);
    margin: 0;
  }
  .toggletip-container {
    display: flex;
    gap: rem-calc(8);
    align-items: center;
  }
  .student-ack-checkbox-info {
    margin: 0;
    max-width: 100%;
  }
  .student-ack-box-list {
    .student-ack-box-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .text-area-field {
        margin-top: rem-calc(24);
        width: 90%;
        .text-error-message {
          font-style: italic;
          margin-top: rem-calc(4);
          color: rgb(192, 57, 43);
        }
        .disabled-area {
          border: none;
        }
      }
    }
    .remove-option-btn {
      margin-right: 0;
      margin-top: rem-calc(38);
      &.error-option {
        margin-bottom: rem-calc(28);
      }
    }
  }
  .add-checkbox-btn {
    margin-top: rem-calc(16);
  }
  .ack-label {
    margin: 0;
  }
  .ack-checkbox-list {
    margin-bottom: rem-calc(28);
    margin-right: rem-calc(20);
    .checkbox-option {
      .label-display {
        display: -webkit-inline-box;
      }
    }
  }
}

.condition-card {
  .user-is-tabbing &:focus {
    box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%), 0 2px 2px 0 rgb(0 0 0 / 10%),
      0 0 4px 3px #61279e;
    outline: none;
  }
  .title {
    &.error {
      color: #c0392b;
      &:after {
        @include mdi-icon;
        content: "\F026";
        margin-left: rem-calc(5);
      }
    }
  }
  .student-acknowledgement {
    padding-left: rem-calc(20);
    .no-ack-label {
      font-size: rem-calc(14);
      letter-spacing: 0;
      font-style: italic;
      color: rgb(192, 57, 43);
      display: block;
      margin-top: rem-calc(5);
    }
    .alert-label,
    .alert-icon {
      color: rgb(192, 57, 43);
    }
    .alert-icon {
      margin-left: rem-calc(5);
    }
    .charcount.-textarea.alert-box {
      -webkit-box-shadow: 0 0 0 1px rgb(192, 57, 43) !important;
      -moz-box-shadow: 0 0 0 1px rgb(192, 57, 43);
      box-shadow: 0 0 0 1px rgb(192, 57, 43);
      border-color: rgb(192, 57, 43);
    }
    .textfield {
      textarea.textfieldinput {
        resize: none;
        min-height: rem-calc(120);
        overflow: auto;
        box-sizing: border-box;
      }
    }
    .help-text {
      padding-top: 0;
    }
    .-display-txtArea {
      display: contents;
    }
  }
}

.acknowleged-content {
  padding-left: rem-calc(20);
  .reqd {
    font-size: rem-calc(16);
  }
}

.submission-card {
  margin-bottom: rem-calc(72);
  .user-is-tabbing &:focus {
    box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%), 0 2px 2px 0 rgb(0 0 0 / 10%),
      0 0 4px 3px #61279e;
    outline: none;
  }
}

.condition-info {
  padding-top: rem-calc(4);
  font-style: italic;
}

.requirement-card {
  .body {
    .description-field {
      padding-top: rem-calc(24);
    }
    .textfield {
      &.is-focused .charcount.-textarea {
        padding-bottom: rem-calc(38);
      }
      textarea.textfieldinput {
        resize: none;
        min-height: rem-calc(120);
        overflow: hidden;
        box-sizing: border-box;
      }
    }
    .due-date-field {
      margin-top: rem-calc(20);
      .due-date-toggle {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        .due-date-label {
          font-size: rem-calc(16);
          font-weight: 500;
          letter-spacing: 0;
          line-height: rem-calc(24);
          display: inline-block;
          margin: 0;
          margin-right: rem-calc(12);
        }
        .toggle-switch {
          margin: 0;
          height: rem-calc(30);
          .toggle-text {
            position: absolute;
            color: rgb(74, 74, 74);
            font-size: rem-calc(12);
            font-weight: bold;
            line-height: rem-calc(24);
            right: rem-calc(8);
            &.on {
              left: rem-calc(8);
              right: auto;
            }
          }
        }
      }
      .due-date-info {
        margin: rem-calc(12 0 16 0);
        max-width: 100%;
      }
      .due-date-picker {
        display: inline-block;
        width: 43%;
        margin-bottom: rem-calc(4);
      }
      .due-time-picker {
        display: inline-block;
        width: 43%;
        margin-left: rem-calc(16);
      }
    }
  }
  &.reference-materials-card {
    .info-text {
      margin: 0;
    }
    .reference-subsection {
      padding-bottom: rem-calc(24);
      .reference-label {
        font-size: rem-calc(16);
        font-weight: 500;
        line-height: rem-calc(24);
        margin: 0;
        padding: rem-calc(24 0 16 0);
      }
      &.reference-links-subsection {
        border-bottom: 1px solid rgb(151, 151, 151);
      }
    }
  }
}

.requirement-details {
  .requirement-title {
    font-weight: 500;
    font-size: rem-calc(16);
  }
  .requirement-description {
    margin: 0;
    white-space: pre-wrap;
  }
  .other-details {
    font-style: italic;
    padding-bottom: rem-calc(4);
  }
}

.list-conditions {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: rem-calc(16);
}

.immersive-body {
  .draggable-element {
    &.no-keyboard-grab {
      .not-subsection {
        margin: rem-calc(0 6);
      }
    }
    .drag-zone {
      .detail-view {
        padding-top: rem-calc(20);
        .drag-zone {
          padding: rem-calc(0 30);
        }
        &:after {
          content: "";
          display: block;
          border-bottom: 1px solid rgb(222, 222, 222);
          margin: rem-calc(0 20);
        }
        &:hover {
          cursor: pointer;
          background: rgb(232, 231, 244);
        }
      }
      .requirement-details {
        .requirement-description {
          padding-top: 0;
          padding-bottom: rem-calc(16);
          white-space: pre-wrap;
        }
      }
      &:hover {
        cursor: pointer;
        background: rgb(232, 231, 244);
      }
      &.drag-started:hover {
        background: #ffffff !important;
      }
      &:active {
        cursor: grabbing;
      }
    }
    .requirement-details {
      .requirement-description {
        padding-top: 0;
        padding-bottom: rem-calc(16);
        white-space: pre-wrap;
      }
    }
    .drag-svg {
      padding: rem-calc(25 16);
      display: flex;
      align-items: center;
      &:focus {
        outline: none;
        border: none;
        background: #413c70;
        path {
          fill: #fff;
        }
      }
    }

    &.keyboard-grabbed {
      margin: rem-calc(0 6);
      box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%),
        0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
    }
  }
}

.sub-section-body .immersive-body .draggable-element {
  &.keyboard-grabbed {
    box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%), 0 2px 2px 0 rgb(0 0 0 / 10%),
      0 0 4px 3px #61279e;
    margin: 0;
  }
}

.select-file-type {
  max-width: rem-calc(305);
  margin-bottom: rem-calc(24);
}

.checkbox-option {
  position: relative;
  + .checkbox-option {
    margin-top: rem-calc(16);
  }
}

.confirmation-label {
  padding-top: rem-calc(16);
}

.reference-links-list {
  .reference-link-item {
    margin-bottom: rem-calc(16);
    display: flex;
    align-items: flex-end !important;
    .reference-link-container {
      width: 90%;
    }
    .reference-link-display-text {
      display: block;
      margin-bottom: rem-calc(4);
      .reference-anchor-link {
        word-break: break-all;
        color: rgb(87, 81, 149);
        font-weight: bold;
        line-height: rem-calc(20);
      }
      a:hover:not(.button):not([role]) {
        background: none;
        padding-bottom: 0;
      }
      .open-icon {
        margin-left: rem-calc(6);
      }
    }
    $startFixedChars: 1; // Number of chars before ellipsis - have priority over end chars
    $endFixedChars: 10; // Number of chars after ellipsis  - lower priority than start chars
    // Dervied from the 3 variables above
    $startWidth: rem-calc(6) * ($startFixedChars + 3);
    $endWidth: rem-calc(6) * $endFixedChars;
    .reference-link-url {
      position: relative;
      background: rgb(240, 240, 240);
      border-radius: rem-calc(3);
      padding: rem-calc(12 16);
      color: rgb(107, 107, 107);
      font-weight: normal;
      white-space: nowrap;
      overflow: hidden;
      .firstPart,
      .lastPart {
        display: inline-block;
        vertical-align: bottom;
        white-space: nowrap;
        overflow: hidden;
      }
      .firstPart {
        max-width: calc(100% - #{$endWidth});
        min-width: $startWidth;
        text-overflow: ellipsis;
      }
      .lastPart {
        max-width: calc(100% - #{$startWidth});
      }
    }
  }
}

.requirement-file-upload {
  padding-top: rem-calc(16);
  margin: 0;
  padding-bottom: rem-calc(4);
  font-size: 1.2rem;
  font-weight: bold;
}

.condition-card-error {
  border: 2px solid rgb(192, 57, 43);
}

.condition-error {
  margin: auto;
  margin-top: rem-calc(-20);
  font-size: rem-calc(14);
  margin-bottom: rem-calc(24);
  max-width: rem-calc(612);
}

.error-toolbar {
  background: rgb(192, 57, 43);
  border-radius: 0px;
  height: rem-calc(44);
  .error-details {
    font-size: rem-calc(14);
    color: rgb(255, 255, 255);
    font-style: italic;
    &:before {
      @include mdi-icon;
      content: "\F026";
      margin-right: 0.3125rem;
    }
  }
}

.requirement-header {
  padding-bottom: rem-calc(24);
}

.show-due-date {
  .label-due-date {
    font-size: rem-calc(16);
    font-weight: 500;
    margin-top: rem-calc(16) !important;
    margin-bottom: rem-calc(4) !important;
    padding-top: rem-calc(20);
    border-top: 1px solid rgb(151, 151, 151);
    &.no-border {
      padding-top: 0;
      border-top: none;
    }
    &.-view {
      margin-bottom: rem-calc(14);
    }
  }
  .textfieldlabel {
    padding-bottom: 0 !important;
  }
  .value-due-date {
    margin: 0;
  }
}

.cohort-card {
  margin-bottom: rem-calc(24);
}

.cohort-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-indent {
  margin-left: rem-calc(30);
}

.no-requirements {
  font-style: italic;
  color: rgb(107, 107, 107);
}

.licensure-plan-tab-data {
  max-width: rem-calc(616);
  margin-left: rem-calc(32);
  margin-top: rem-calc(40);
  .requirement-title {
    font-size: rem-calc(14);
    font-weight: 600;
    margin: 0;
  }
  .requirement-details {
    + .requirement-details {
      padding-top: rem-calc(12);
    }
  }
  .sub-section-list {
    .sub-section {
      border-top: 1px solid rgb(222, 222, 222);
      padding-top: rem-calc(12);
      padding-bottom: rem-calc(12);
      &.-expanded {
        padding-bottom: rem-calc(24);
      }
      &:first-child {
        margin-top: rem-calc(24);
      }
      &:last-child {
        padding-bottom: 0;
      }
      .-icononly {
        color: #575195;
        cursor: pointer;
        border: 0;
        margin-right: rem-calc(5);
        background-color: transparent;
      }
    }
    &.no-section-requirements {
      .sub-section {
        &:first-child {
          margin-top: 0;
          border-top: none;
          padding-top: 0;
        }
      }
    }
  }
}

#edit-timeline-flyout {
  .sub-section {
    &:nth-child(odd) {
      background: rgb(250, 250, 250);
    }
  }
  .paper-card {
    // z-index: 20;
    position: relative;
  }
}

.card-position {
  position: relative;
  z-index: 1;
}

.loader {
  &.-fullscreen {
    > .content {
      &.content-loader {
        background: url("../../images/content-loader.svg") no-repeat 50% 0;
      }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        &.content-loader {
          background: url("../../images/content-loader.gif") no-repeat 50% 0;
        }
      }
    }
  }
}

.student-cohorts-grid {
  padding: rem-calc(24 30);
  .cohort-col {
    padding-right: rem-calc(20);
    padding-bottom: rem-calc(20);
  }
  .student-cohort {
    padding: rem-calc(20);
    height: 100%;
    .cohort-detail-section {
      padding-bottom: rem-calc(8);
      border-bottom: 2px solid rgb(231, 231, 231);
      margin-bottom: rem-calc(8);
    }
    .sub-title {
      padding-bottom: rem-calc(4);
    }
    .overflow-title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .program-link {
      padding-bottom: rem-calc(8);
      margin-bottom: rem-calc(0);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        padding-bottom: rem-calc(8);
      }
    }
    .requirement-title {
      font-size: rem-calc(14);
      font-weight: 600;
      padding-bottom: rem-calc(8);
    }
    .status-count {
      @include displayFlex;
      @include justifyContent(space-between);
      padding-bottom: rem-calc(8);
      .not-submitted-count {
        color: rgb(58, 58, 58);
        font-size: rem-calc(22);
      }
      .awaiting-review-count {
        color: rgb(65, 89, 105);
        font-size: rem-calc(22);
      }
      .cond-met-count {
        color: rgb(87, 81, 149);
        font-size: rem-calc(22);
      }
      .met-count {
        color: rgb(17, 87, 91);
        font-size: rem-calc(22);
      }
      .not-met-count {
        color: rgb(175, 41, 46);
        font-size: rem-calc(22);
      }
      .status-tag {
        background: rgb(242, 240, 243);
        color: rgb(107, 107, 107);
        padding-top: rem-calc(6);
        &.awaiting-review-tag {
          background: rgb(225, 236, 245);
          color: rgb(80, 108, 124);
        }
        &.met-tag {
          background: rgb(224, 245, 247);
          color: rgb(17, 87, 91);
        }
        &.not-met-tag {
          background: rgb(254, 237, 234);
          color: rgb(175, 41, 46);
        }
        &.cond-met-tag {
          background: rgb(238, 237, 244);
          color: rgb(87, 81, 149);
        }
      }
    }
  }
  .cohort-details-container {
    .header {
      border-bottom: 1px solid #2e1b46;
      margin-left: -1.875rem;
      margin-right: -1.875rem;
      padding: rem-calc(10 24 20);
      position: relative;
      padding-top: 0;
      .no-req-lp-title {
        color: rgb(74, 74, 74);
        font-size: rem-calc(18);
        font-weight: 600;
      }
    }
    .details-view {
      padding-top: 20px;
    }
    .no-req-state {
      padding: rem-calc(50 0);
      .titles {
        color: rgb(74, 74, 74);
        font-size: rem-calc(20);
        font-weight: normal;
        text-align: center;
        padding-bottom: rem-calc(4);
      }
      .helper-text {
        color: rgb(74, 74, 74);
        font-size: rem-calc(14);
        font-weight: normal;
        text-align: center;
      }
    }
    .separator {
      border-left: 1px solid rgb(222, 222, 222);
    }
    .status-count {
      @include displayFlex;
      @include justifyContent(space-between);
      padding-bottom: rem-calc(12);
      .not-submitted-count {
        color: rgb(74, 74, 74);
        font-size: rem-calc(22);
        padding-left: rem-calc(16);
      }
      .awaiting-review-count {
        color: rgb(74, 74, 74);
        font-size: rem-calc(22);
        padding-left: rem-calc(16);
      }
      .cond-met-count {
        color: rgb(74, 74, 74);
        font-size: rem-calc(22);
      }
      .met-count {
        color: rgb(74, 74, 74);
        font-size: rem-calc(22);
        padding-left: rem-calc(16);
      }
      .not-met-count {
        color: rgb(74, 74, 74);
        font-size: rem-calc(22);
        padding-left: rem-calc(16);
      }
      .status-tag {
        background: rgb(107, 107, 107);
        color: rgb(255, 255, 255);
        padding-top: rem-calc(6);
        font-size: rem-calc(14);
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: rem-calc(0.3);
        &.awaiting-review-tag {
          background: rgb(219, 217, 239);
          color: rgb(83, 80, 121);
        }
        &.met-tag {
          background: rgb(224, 245, 247);
          color: rgb(17, 87, 91);
        }
        &.not-met-tag {
          background: rgb(254, 237, 234);
          color: rgb(175, 41, 46);
        }
        &.cond-met-tag {
          background: rgb(46, 27, 70);
          color: rgb(233, 231, 236);
        }
      }
    }
  }
}

.faculty-cohorts {
  .filter-chororts-by {
    @include displayFlex;
    margin-bottom: rem-calc(32);
    .filters-container {
      @include displayFlex;
      .filter-label {
        @include alignSelf(center);
        font-weight: 600;
        margin-right: rem-calc(12);
        min-width: rem-calc(64);
      }
    }
    @media screen and (max-width: 1200px) {
      @include flexDirection(column);
      .search-cohorts {
        margin-bottom: rem-calc(20);
        max-width: rem-calc(762);
        margin-right: 0;
      }
    }
  }
  margin: rem-calc(24 32);
  .faculty-cohort-list {
    margin-bottom: rem-calc(8);
  }
  .sub-title {
    padding-bottom: rem-calc(4);
  }
  .overflow-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .faculty-name {
    font-size: rem-calc(22);
    padding-bottom: rem-calc(16);
    font-weight: normal;
    margin: 0;
  }
  .search-cohorts {
    max-width: rem-calc(600);
    flex-grow: 1;
    margin-right: rem-calc(20);
  }
}

.highlight-text {
  background: rgb(233, 231, 236);
  border-radius: 0px;
  padding: rem-calc(4);
}

.cohort-status-filter {
  width: rem-calc(180);
  margin-right: rem-calc(16);
}

.plan-status-filter {
  width: rem-calc(240);
  margin-right: rem-calc(16);
}

.program-filter {
  width: rem-calc(240);
}

.uploader-container {
  margin-top: rem-calc(20);
  white-space: pre-line;
}

.helper-text {
  font-size: rem-calc(14);
}

.status-details {
  padding-right: 0.5rem;
}

.submission-progress {
  padding-left: 0.5rem;
  border-left: 2px solid #e7e7e7;
}

.licensure-plan-label {
  margin-bottom: 0;
  margin-top: rem-calc(24);
  margin-left: rem-calc(32);
  font-size: rem-calc(24);
  font-weight: normal;
}

.cohort-active {
  padding-left: rem-calc(140);
  font-size: 20px;
}

.empty-plan-view {
  padding-top: rem-calc(96);
  .title {
    color: rgb(74, 74, 74);
    font-size: rem-calc(24);
    font-weight: normal;
    text-align: center;
  }
  .helper-text {
    color: rgb(74, 74, 74);
    font-size: rem-calc(14);
    font-weight: normal;
    text-align: center;
    padding-bottom: rem-calc(16);
  }
  .btn-align {
    display: flex;
    justify-content: center;
    &.-icon {
      font-size: rem-calc(40);
    }
  }
}

#view-plan-flyt {
  .container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: unset;
    left: unset;
    &.view-plan-flyout {
      .immersive-header {
        top: 0;
        overflow-x: initial;
        background: rgb(255, 255, 255);
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
        .flyout-title,
        .title-desc {
          color: rgb(74, 74, 74) !important;
        }
        &.archive-view-header {
          background: rgb(53, 59, 72);
          box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2),
            0px 1px 18px 0px rgba(0, 0, 0, 0.12),
            0px 2px 10px 0px rgba(0, 0, 0, 0.14);
          border-radius: 0;
          padding: rem-calc(24 28);
          .flyout-title {
            color: rgb(250, 250, 250) !important;
            font-size: rem-calc(24);
            font-weight: 600;
            letter-spacing: 0;
            line-height: rem-calc(32);
            .title-desc {
              color: rgb(255, 255, 255) !important;
              font-size: rem-calc(14);
              font-weight: normal;
              letter-spacing: 0;
              line-height: rem-calc(20);
            }
          }
        }
      }
      .content {
        margin: 0;
        padding: 0;
        overflow: unset;
        .archive-timestamp {
          color: rgb(107, 107, 107);
          font-size: rem-calc(14);
          font-weight: normal;
          font-style: italic;
          letter-spacing: 0;
          line-height: rem-calc(20);
          padding: rem-calc(30 40);
          background: rgb(255, 255, 255);
          box-shadow: 0 2px 4px 0 rgb(0 0 0 / 9%);
          position: relative;
          width: 100%;
        }
      }
      .licensure-plans-timeline {
        margin-top: 0;
        margin-left: rem-calc(103);
        padding: rem-calc(24 0);
        line-height: rem-calc(20);
        &.-margin-auto {
          margin: auto;
        }
        .timeline-section .header {
          padding: rem-calc(0 24 18 24);
        }
        .timeline-section .header .title {
          padding: 0;
        }
        .timeline-section {
          &:last-child {
            margin-bottom: rem-calc(84);
          }
        }
        .update-info {
          padding-bottom: rem-calc(24);
          color: rgb(107, 107, 107);
          font-size: rem-calc(14);
          font-weight: normal;
          font-style: italic;
        }
        .requirement-details {
          width: rem-calc(774);
          min-width: rem-calc(774);
          max-width: rem-calc(774);
          &:last-child {
            padding-bottom: 0;
          }
          padding-top: 0;
          .requirement-header {
            padding-bottom: rem-calc(4);
            .requirement-title {
              color: rgb(74, 74, 74);
              font-size: rem-calc(14);
              font-weight: 600;
            }
          }
          .view-dtl-btn {
            padding-top: rem-calc(12);
          }
          + .requirement-details {
            margin-top: rem-calc(16);
            padding-top: rem-calc(16);
            border-top: 1px solid rgb(222, 222, 222);
          }
          @media screen and (max-width: 1024px) {
            width: rem-calc(540) !important;
            min-width: rem-calc(540) !important;
            max-width: rem-calc(540) !important;
          }
        }
        .add-border-btm {
          border-bottom: 1px solid rgb(222, 222, 222);
          padding-bottom: rem-calc(24);
        }
        .sub-section-list {
          .sub-section {
            margin: rem-calc(24 24 0 24);
            padding: rem-calc(24 24 24 20);
            .accordion-label {
              color: rgb(74, 74, 74);
              font-size: rem-calc(16);
              font-weight: 500;
              line-height: rem-calc(24);
              padding-left: 0;
            }
            .requirement-details {
              padding-left: rem-calc(4);
              width: rem-calc(688);
              min-width: rem-calc(688);
              max-width: rem-calc(688);
            }
          }
        }
      }
      .mini-panel {
        width: rem-calc(480) !important;
        min-width: rem-calc(480) !important;
        background: rgb(255, 255, 255);
        box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.09);
        .textfieldlabel {
          font-weight: 600;
        }
      }
    }
  }
}

#select-application-flyout {
  &.half-flyout {
    > .container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: unset;
      left: unset;
      background: rgb(255, 255, 255);
      box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.09);
      border-radius: 0;
      @media screen and (min-width: 800px) {
        left: unset;
        width: rem-calc(720);
        max-width: unset;
      }
      .select-app-header {
        border-bottom: 1px solid rgb(231, 231, 231);
        padding: rem-calc(20 28);
        box-shadow: none;
        .add-app-label {
          font-size: rem-calc(14);
          font-weight: 300;
          line-height: rem-calc(20);
          text-transform: uppercase;
          margin: rem-calc(0 0 4 0);
        }
        .select-app-title {
          font-size: rem-calc(18);
          font-weight: 600;
          line-height: rem-calc(26);
          margin: 0;
        }
        .go-back-link .bread-crumbs {
          color: rgb(74, 74, 74);
          font-size: rem-calc(14);
          font-weight: normal;
          letter-spacing: 0;
          line-height: rem-calc(20);
        }
      }
      .add-app-search {
        padding: rem-calc(24 28 20 28);
        border-bottom: solid rgba(46, 27, 70, 0.05);
        .add-application-search {
          display: block;
          width: 83%;
        }
        .add-app-info {
          font-style: italic;
          font-size: rem-calc(14);
          max-width: 100%;
          margin-bottom: 0;
        }
      }
      .select-apps-list {
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 296px);
        max-height: calc(100vh - 296px);
        max-height: -webkit-calc(100vh - 296px);
        padding: 0;
        margin: 0;
        .select-apps-list-item {
          display: flex;
          align-items: flex-start;
          padding: rem-calc(16 32 16 24);
          border-bottom: 2px solid rgba(46, 27, 70, 0.05);
          .app-name {
            width: 81%;
            position: relative;
            .app-radio-label {
              display: -webkit-inline-box;
              .app-title {
                padding-top: rem-calc(3);
                line-height: rem-calc(18);
              }
              .app-description {
                padding-top: rem-calc(4);
                font-style: italic;
              }
            }
          }
          .preview-link {
            font-size: rem-calc(14);
            font-weight: bold;
            line-height: rem-calc(20);
          }
        }
      }
      .select-app-footer {
        background: rgb(255, 255, 255);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
        display: flex;
        flex-direction: row-reverse;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: rem-calc(20 32);
      }
    }
  }
}

#log-category-flyout {
  &.half-flyout {
    > .container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: unset;
      left: unset;
      background: rgb(255, 255, 255);
      box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.09);
      border-radius: 0;
      @media screen and (min-width: 800px) {
        left: unset;
        width: rem-calc(720);
        max-width: unset;
      }
      .select-log-category-header {
        border-bottom: 1px solid rgb(231, 231, 231);
        padding: rem-calc(20 36);
        box-shadow: none;
        .header-section {
          width: 100%;
          display: flex;
          align-items: flex-start;
        }
        .timelog-label {
          font-size: rem-calc(14);
          line-height: rem-calc(20);
          font-weight: normal;
          margin: 0;
          margin-bottom: rem-calc(4);
        }
        .add-log-category-title {
          font-size: rem-calc(18);
          font-weight: 600;
          line-height: rem-calc(26);
          margin: 0;
          margin-bottom: rem-cal(2);
        }
        .info-text {
          margin: 0;
          font-size: rem-calc(14);
        }
        .bread-crumbs {
          font-size: rem-calc(14);
          margin-bottom: rem-calc(4);
          a:hover:not(.button):not([role]) {
            padding-bottom: 0;
          }
          .link {
            font-weight: bold;
            display: inline-flex;
            span {
              line-height: normal;
            }
            &::after {
              vertical-align: top;
            }
          }
          .bread-crumb-text {
            font-size: rem-calc(14);
            line-height: rem-calc(20);
          }
        }
      }
      .categories-main-body {
        height: calc(100vh - 198px);
        height: -webkit-calc(100vh - 198px);
        .category-selection-container,
        .course-selection-container {
          height: 100%;
          padding: rem-calc(16 30);
          .course-selection-info {
            margin: 0;
            padding-bottom: rem-calc(14);
            font-size: rem-calc(14);
            max-width: 85%;
          }
          .reqd {
            margin-left: rem-calc(4);
          }
          #none-selected,
          #no-selection {
            outline: none;
          }
          .none-selected-error {
            margin: 0;
            margin-bottom: rem-calc(8);
          }
          .select-category-text,
          .select-course-text {
            margin: 0;
            padding-bottom: rem-calc(4);
            font-size: rem-calc(14);
            font-weight: 600;
          }
          .search-div {
            padding-bottom: rem-calc(24);
            .log-category-search,
            .course-search {
              display: block;
              width: 93%;
            }
          }
          .data-table {
            .user-is-tabbing &:focus {
              box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%),
                0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
              outline: none;
            }
            .dataheader {
              float: left;
              width: 100%;
            }
            tr {
              width: 100%;
              display: table;
              text-align: left;
            }
            .dataheaderrow {
              height: rem-calc(40);
              th {
                padding-left: rem-calc(75);
                background: rgb(238, 237, 244);
                border-radius: rem-calc(5 5 0 0);
                font-size: rem-calc(12);
                text-transform: uppercase;
                text-align: left;
              }
            }
            .databody {
              overflow: auto;
              display: block;
              width: 100%;
              max-height: calc(100vh - 360px);
              max-height: -webkit-calc(100vh - 360px);
              &.category-selection-tbody {
                max-height: calc(100vh - 388px);
                max-height: -webkit-calc(100vh - 388px);
              }
              .category-name {
                position: relative;
              }
              .datarow {
                td {
                  word-break: break-all;
                  min-width: 0%;
                  width: unset;
                  padding: rem-calc(18 24);
                  font-size: rem-calc(14);
                }
                input[type="radio"].radio-input + .radio:before {
                  margin-right: rem-calc(40);
                }
              }
            }
          }
          .course-selection-table {
            .toggletip {
              font-size: rem-calc(14);
              font-weight: normal;
              line-height: rem-calc(20);
              text-transform: none;
              margin-left: rem-calc(8);
              top: rem-calc(1);
            }
            .dataheaderrow th {
              padding-left: rem-calc(24);
              .user-is-tabbing &:focus {
                box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
                  inset 0 2px 2px 0 rgb(0 0 0 / 10%), inset 0 0 4px 3px #61279e;
                outline: none;
              }
              .checkbox-option .chkbx {
                font-size: rem-calc(12);
                &:before {
                  margin-right: rem-calc(10);
                }
              }
            }
            .course-selection-tbody {
              display: block;
              width: 100%;
              height: calc(100vh - 408px);
              height: -webkit-calc(100vh - 408px);
              position: relative;
              z-index: 10;
              &.error-view {
                height: calc(100vh - 444px);
                height: -webkit-calc(100vh - 444px);
              }
              .user-is-tabbing &:focus {
                box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
                  0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
                outline: none;
              }
              .datarow {
                display: block;
              }
              .datarow .checkbox-option {
                .chkbx {
                  font-weight: normal;
                  &:before {
                    margin-right: rem-calc(10);
                  }
                }
              }
            }
          }
          @media screen and (max-width: 800px) {
            .data-table thead {
              display: table-header-group;
            }
          }
          .empty-flyout-view {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            .no-data-label {
              font-size: rem-calc(24);
            }
            .no-data-text {
              margin-top: rem-calc(8);
              font-size: rem-calc(14);
              font-weight: normal;
              line-height: rem-calc(20);
            }
          }
        }
      }
      .select-log-category-footer {
        background: rgb(255, 255, 255);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
        display: flex;
        flex-direction: row-reverse;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: rem-calc(20 32);
      }
    }
  }
}

.licensurePlan-support-link {
  font-weight: bold;
}

.charcount.-textarea .textfieldinput {
  resize: none;
  min-height: rem-calc(120);
  overflow: hidden;
  box-sizing: border-box;
}

.alert-label,
.alert-icon {
  color: rgb(192, 57, 43);
}
.alert-icon {
  margin-left: rem-calc(5);
}

#content-add-link-modal {
  .url-input-field {
    margin-bottom: rem-calc(24);
    .charcount input.textfieldinput:focus {
      padding-right: rem-calc(15);
    }
  }
  .display-txt-field,
  .url-input-field {
    width: 80%;
  }
  .info-text {
    font-size: rem-calc(14);
    font-style: italic;
    padding-bottom: rem-calc(22);
  }
}

#requirement-type-flyout {
  &.half-flyout {
    > .container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: unset;
      left: unset;
      background: rgb(255, 255, 255);
      box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.09);
      border-radius: 0;
      .select-type-header {
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
        padding: 0;
        border: none;
        .header-section {
          width: 100%;
        }
        .close-btn {
          padding: rem-calc(20 30);
        }
        .header-title {
          font-size: rem-calc(24);
          font-weight: 600;
          line-height: rem-calc(32);
        }
      }
      .select-type-body {
        padding: rem-calc(92 0);
        .card-component {
          max-width: 54%;
          width: 54%;
          .card-header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .card-header-title {
              margin: 0;
              font-size: rem-calc(18);
              font-weight: 600;
              line-height: rem-calc(26);
            }
          }
        }
        .card-body {
          padding: rem-calc(24 10);
          display: flex;
          justify-content: space-evenly;
          .select-type-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
            border-radius: rem-calc(5);
            padding: rem-calc(40 14);
            min-height: rem-calc(276);
            cursor: pointer;
            width: 30%;
            &.no-application {
              width: 40%;
            }
            .user-is-tabbing &:focus {
              box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
                inset 0 2px 2px 0 rgb(0 0 0 / 10%), inset 0 0 4px 3px #61279e;
              outline: none;
            }
            .select-type-icon {
              color: rgb(139, 134, 202);
              font-size: rem-calc(70);
            }
            .select-type-title {
              margin: 0;
              color: rgb(87, 81, 149);
              font-size: rem-calc(14);
              font-weight: bold;
              line-height: rem-calc(20);
              padding-bottom: rem-calc(4);
            }
            .select-type-description {
              margin: 0;
              font-size: rem-calc(14);
              text-align: center;
              line-height: rem-calc(20);
            }
          }
        }
      }
    }
  }
}

#detail-view {
  .detail-label {
    margin: 0;
    font-size: rem-calc(16);
    font-weight: 500;
    line-height: rem-calc(24);
  }
  .template-document-container {
    margin-top: rem-calc(24);
    padding-top: rem-calc(20);
    border-top: 1px solid rgb(151, 151, 151);
    .ref-links-list {
      padding: 0;
      margin: rem-calc(14 0);
      list-style-type: none;
      .ref-links-list-item {
        margin-bottom: rem-calc(10);
        .resource-link {
          display: flex;
          align-items: flex-end;
          word-break: break-all;
          width: fit-content;
        }
        .resource-link-display {
          overflow: hidden;
          display: -webkit-inline-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          padding-right: rem-calc(4);
        }
      }
      a:hover:not(.button):not([role]) {
        padding-bottom: 0 !important;
      }
    }
    .course-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      padding-top: rem-calc(4);
      li {
        padding: 0;
      }
    }
    .detail-value {
      margin: rem-calc(4 0);
    }
    h4 {
      padding-bottom: rem-calc(12);
    }
    .app-details-section {
      margin-bottom: rem-calc(16);
      .app-details-value {
        padding: 0;
        list-style-type: none;
        margin: 0;
        margin-bottom: rem-calc(4);
      }
      .app-details-label {
        font-size: rem-calc(14);
        font-weight: 600;
        padding-bottom: rem-calc(4);
        padding-right: rem-calc(4);
        margin: 0;
      }
    }
  }
  .list-conditions {
    margin-top: rem-calc(24);
    padding-top: rem-calc(20);
    border-top: 1px solid rgb(151, 151, 151);
    ul {
      margin-top: rem-calc(12);
    }
  }
}

#add-requirement-flyout,
#add-timelog-requirement-flyout {
  .go-back-link .bread-crumbs {
    color: rgb(74, 74, 74);
    font-size: rem-calc(14);
    font-weight: normal;
    letter-spacing: 0;
    line-height: rem-calc(20);
    margin-bottom: 0;
  }
}

#add-timelog-requirement-flyout {
  .flyout-content {
    padding-top: rem-calc(24);
  }
  .flyout-content.no-errors {
    padding-bottom: rem-calc(64);
    height: calc(100vh - 117px);
    height: -webkit-calc(100vh - 117px);
    overflow: auto;
    max-width: none;
  }
  .condition-types {
    .condition-name {
      padding: 0;
    }
    .subsection-info {
      margin: rem-calc(4 0 8);
      max-width: rem-calc(550);
    }
    .add-log-category-label {
      display: block;
      font-weight: 600;
      padding-bottom: rem-calc(8);
      &.error {
        color: rgb(192, 57, 43);
        padding-bottom: rem-calc(0);
      }
    }
    .add-log-category-error {
      margin: 0;
      padding-bottom: rem-calc(12);
      color: rgb(192, 57, 43);
      font-style: italic;
    }
    .log-category-list {
      .log-category-list-item {
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
        border-radius: rem-calc(5);
        padding: rem-calc(10 16);
        margin-top: rem-calc(8);
        .user-is-tabbing &:focus {
          box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%),
            0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
          outline: none;
        }
        .log-category-title {
          font-weight: 600;
          line-height: rem-calc(20);
          padding: rem-calc(0 10);
          color: rgb(74, 74, 74);
        }
        .log-category-info {
          font-style: italic;
          color: rgb(74, 74, 74);
          font-size: rem-calc(14);
          font-weight: normal;
        }
        .log-category-details {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-top: rem-calc(8);
        }
        .log-category-courses-list {
          list-style-type: "- ";
          padding-left: rem-calc(30);
          li {
            font-style: italic;
          }
        }
        .button-collection {
          #edit-log-category-btn {
            margin: 0;
          }
          #delete-log-category-btn {
            margin: rem-calc(0 0 0 8);
          }
        }
      }
    }
    .textfield .charcount {
      margin-top: rem-calc(2);
      width: 33%;
      input.textfieldinput:focus {
        padding-right: rem-calc(15);
      }
    }
  }
}
