.dropdown-select {
  position: relative;
  font-size: rem-calc(18);
  min-width: rem-calc(140);

  > .selectedoption {
    @include border-radius(3px);
    @include box-shadow(none);
    background: transparent;
    height: rem-calc(40);
    line-height: 1;
    display: block;
    width: 100%;
    border: solid 1px rgba(35, 35, 35, 0.6);
    padding: (10 / 14 * 1em) (30 / 14 * 1em) (8 / 14 * 1em) (15 / 14 * 1em);
    font-weight: 400;
    font-size: rem-calc(14);
    cursor: pointer;
    text-transform: none;
    text-align: left;
    line-height: normal;

    @media only screen and (max-width: 768px) {
      height: rem-calc(44);
      padding-top: 0;
      padding-bottom: 0;
    }

    [dir="RTL"] & {
      padding:(10 / 14 * 1em) (15 / 14 * 1em) (8 / 14 * 1em) (30 / 14 * 1em);
      text-align:right;
    }

    &:before {
      @include mdi-icon;
      content: "\f140";
      position: absolute;
      right: rem-calc(9);
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;

      [dir="RTL"] & {
        right:auto;
        left:rem-calc(9);
      }
    }
    &:disabled{
      background: #f0f0f0;
      color:#6B6B6B;
      border-color:#8A8A8A;
    }
    &:hover:not(:disabled):not(.-disabled):not(.-raised) {
      background: transparent;
      text-decoration: none;
      color: $button-default-text;
    }

    &:focus {
      outline: none;
    }

    .user-is-tabbing &:focus {
      @extend %focus-styles;
    }

    .overflowcontrol {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .optionscontainer {
    .searchcontainer {
      padding: rem-calc(15 20);
      border-bottom: 2px solid rgba(#2e1b46, 0.05);
      position: relative;
    }
    .searchbox {
      @include border-radius(3px);
      border: 1px solid rgba(35,35,35,.6);
      font-size: rem-calc(14);
      padding: rem-calc(0 15 0 35);
      width: 100%;
      height: rem-calc(36);

      @media only screen and (max-width: 768px) {
        height: rem-calc(44);
      }

      + .icon {
        position: absolute;
        top:rem-calc(27);
        left:rem-calc(32);
      }
    }
  }
  > .options, .optionscontainer .searchoptions {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    max-height: rem-calc(200);
  }
  > .options, .optionscontainer {
    @include box-shadow(0 4px 15px 0 rgba(0, 0, 0, 0.2));
    @include transition(transform .25s ease);
    @include scale($xVal: 1, $yVal: 0);
    @include transformOrigin(center top);
    @include border-radius(3px);
    position: absolute;
    top: rem-calc(40);
    right: 0;
    background: #fff;
    z-index: 100;
    width: 100%;
    font-size: rem-calc(14);

    @media only screen and (max-width: 768px) {
      top: rem-calc(44);
    }

    .option {
      position: relative;
      padding: (20 / 14 * 1em) (48 / 14 * 1em) (20 / 14 * 1em) (20 / 14 * 1em);
      background: #fff;
      color: $body-text-color;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 2px solid rgba(#2e1b46, 0.05);
      }

      &:hover, &.focus, &:focus {
        background: rgba(#2e1b46, 0.05);
        outline: none;
      }

      &.selected {
        background: rgba(#2e1b46, 0.05);
        font-weight: 500;
      }
    }

    &:focus {
      outline: none;
    }
  }
  &.open, .active {
    > .options, .optionscontainer  {
      @include scale($xVal: 1, $yVal: 1);
    }
  }
  .active {
    + .options, + .optionscontainer, + .optionscontainer .options  {
      @include scale($xVal: 1, $yVal: 1);
    }
  }

  &.-multiple {
    > .options .option.selected,
    .optionscontainer .option.selected {
      font-weight: 400;

      &:before {
        @include mdi-icon;
        content: "\f12c";
        position: absolute;
        right: rem-calc(9);
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        color: #61279e;
      }
    }
  }
}

[dir="RTL"] .dropdown-select {
  > .options, .optionscontainer {
    @include transformOrigin(left top);
    left: 0;
    right: auto;

    .option {
      padding-left: 48 / 14 * 1em;
      padding-right: 20 / 14 * 1em;
    }
  }
}
