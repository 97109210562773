.grid {
  padding: rem-calc(10);
  /* TODO: Add actual styling*/
}
.wm-grid {
  $gutter: rem-calc(20);
  $cols: 12;

  .wm-grid-row {
    @include displayFlex;
    @include flexWrap(wrap);
    @include flexDirection(row);
    margin-right: $gutter / 2 * -1;
    margin-left: $gutter / 2 * -1;
  }
  [class*="wm-grid-col"] {
    @include flexBasis(0);
    @include flexGrow(1);
    width: 100%;
    box-sizing: border-box;
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
  }
  @for $i from 1 through $cols {
    @include grid-calc($cols);
  }
  @media only screen and (max-width: 1280px) {
    @include grid-calc($cols, "xl");
  }
  @media only screen and (max-width: 1440px) {
    @include grid-calc($cols, "lg");
  }
  @media only screen and (max-width: 1024px) {
    @include grid-calc(10, "md");
  }
  @media only screen and (max-width: 768px) {
    @include grid-calc(8, "sm");
  }
  @media only screen and (max-width: 414px) {
    @include grid-calc(4, "xs");
  }
  @media only screen and (max-width: 321px) {
    @include grid-calc(4, "tiny");
  }
}
