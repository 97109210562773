.fixable-header {
  @include displayFlex;
  @include justifyContent(space-between);
  @include alignItems(center);
  @include flex(0 0 auto);
  @include transition(.5s ease 0s);
  flex-wrap: wrap;
  position: fixed;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  padding: rem-calc(0 36);
}
