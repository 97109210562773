#main-nav{
  overflow-y: auto;
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);
  border-radius: 0;
  @media screen and (max-width: 1024px) {
      z-index: 30;
  }
}

.hamburger-header{
  @media screen and (max-width: 1024px) {
    padding-left: 20px;
  }

}
