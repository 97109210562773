.modal-dialog {
  @include displayFlex;
  @include alignItems(center);
  @include justifyContent(center);
  @include transition(opacity .5s ease-out);
  @include flexDirection(column);
  background-color: rgba(25,25,25,.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3001;
  max-width: none;
  margin: 0;
  &.-addList{
    .content{
      overflow-y:auto;
        .-scrollcontainer{
          overflow-y:auto;
        }
    }
  }
  &.hide {
    display: none;
  }
  &.-dialog {
    > .content .header { border-bottom:none; }
    > .content .footer { border-top:none; }
  }

  > .content {
    @include displayFlex;
    @include flexDirection(column);
    @include shadow24;
    @include border-radius(5px);
    position: relative;
    z-index: 1002;
    background: #fff;
    width: 80vw;
    max-height: 80vh;
    max-width: 750px;

    // @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    //   height: 80vh;
    // }

    .header,
    .footer {
      @include flex(none);
    }

    .header {
      @include displayFlex;
      padding: rem-calc(20 30 10);
      border-bottom: 1px solid rgba(46, 27, 70, 0.05);

      &.-titleonly {
        padding-top: rem-calc(20);
      }

      + .footer {
        border-top: none;
      }

      .title {
        @include flex(1);
        font-weight: 500;
        font-size: rem-calc(18);
        margin: 0;
        letter-spacing: normal;

        &.-nosubtitle + .close {
          margin-top: 0;
          position: absolute;
          top: rem-calc(20);
          right: rem-calc(7);
        }
        &.error{
            color:#f00;
        }
        .subtitle {
          font-size: rem-calc(12);
          display: block;
          font-weight: 400;
          text-transform: uppercase;
          line-height: rem-calc(24);
          letter-spacing: .3px;
        }
        .error-msg{
            font-size: 12px;
            display: block;
            font-weight: 300;
            line-height: rem-calc(24);
        }
      }

      .close {
        @include border-radius(50%);
        margin-top: rem-calc(-6);
        width: rem-calc(40);
        height: rem-calc(40);
      }
    }
    .toolbar {
      @include flex(none);
      @include displayFlex;
      @include flexDirection(row);
      margin: rem-calc(10 30 0);
    }
    .body {
      @include flex(1 1 auto);
      min-height: 0;

      &.-noscroll {
        @include displayFlex;
        overflow-y: auto;
      }

      &:not(.-noscroll) {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: rem-calc(20 30);

        .loader {
          padding: rem-calc(20);
        }
      }
    }
    .-scrollcontainer {
      @include flex(1 1 auto);
      @include displayFlex;
      @include flexDirection(column);
      padding: rem-calc(20 30 0);
      min-height: 100%;

      .scrollelement {
        @include flex(1 1 auto);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        margin: rem-calc(0 -30);
        padding: rem-calc(4 30);
      }
      .scrollsibling {
        @include flex(none);
      }
    }
    .loader {
      text-align: center;
      width: 100%;
    }
    .footer {
      @include displayFlex;
      @include alignItems(center);
      @include justifyContent(space-between);
      border-top: 1px solid #e7e7e7;
      padding: rem-calc(20 30);

      &.-buttonsonly {
        @include justifyContent(flex-end);
      }
    }
  }
  &.-actiondialog{
    .content{
      width: rem-calc(666);
        .header{
          padding: rem-calc(20 30 25);
          border: none;
        }
        .body{
          padding:rem-calc(0 30);
        }
        .footer{
          padding: rem-calc(25 30);
          border: none;
            .button-collection{
              > .button + .button{
                margin-left:rem-calc(25);
              }
            }
        }
    }
  }
}
