@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-Thin.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-Thin.woff?v=3.15")
      format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-ThinItalic.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-ThinItalic.woff?v=3.15")
      format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-ExtraLight.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-ExtraLight.woff?v=3.15")
      format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-ExtraLightItalic.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-ExtraLightItalic.woff?v=3.15")
      format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-Light.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-Light.woff?v=3.15")
      format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-LightItalic.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-LightItalic.woff?v=3.15")
      format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-Regular.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-Regular.woff?v=3.15")
      format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-Italic.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-Italic.woff?v=3.15")
      format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-Medium.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-Medium.woff?v=3.15")
      format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-MediumItalic.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-MediumItalic.woff?v=3.15")
      format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-SemiBold.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-SemiBold.woff?v=3.15")
      format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-SemiBoldItalic.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-SemiBoldItalic.woff?v=3.15")
      format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-Bold.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-Bold.woff?v=3.15")
      format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-BoldItalic.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-BoldItalic.woff?v=3.15")
      format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-ExtraBold.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-ExtraBold.woff?v=3.15")
      format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-ExtraBoldItalic.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-ExtraBoldItalic.woff?v=3.15")
      format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-Black.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-Black.woff?v=3.15")
      format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("https://static.taskstream.com/Fonts/InterWeb/Inter-BlackItalic.woff2?v=3.15")
      format("woff2"),
    url("https://static.taskstream.com/Fonts/InterWeb/Inter-BlackItalic.woff?v=3.15")
      format("woff");
}
