.empty-state {
  text-align: center;

  > .wrapper {
    margin-top: rem-calc(65);

    > .header {
      font-size: rem-calc(24);
      font-weight: 400;
      margin: rem-calc(0 0 7);
    }

    > .callout {
      margin: 0;
      letter-spacing: normal;
      font-size: rem-calc(14);
      max-width: none;
    }

    > button.large, > button.-primary, wm-button {
      @include alignSelf(center);
      margin-top: rem-calc(20);
    }

    #manage-course-modal & {
      margin-top: rem-calc(130);
    }
  }
}

@media screen and (min-height: 650px) {
  .empty-state {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 105px;

    > .wrapper.-centered {
      position: relative;
      margin-top: 0;
      top: 50%;
      @include translate($distanceY: -50%);
    }
  }
}

.-relativeemptystate {
  @include displayFlex;
  @include flexDirection(column);

  > .page-header {
    @include flex(none);
  }

  .empty-state {
    @include flex(1);
    position: relative;
    top: calc(50% - 222px);

    > .wrapper {
      @media screen and (min-height: 650px) {
        @include translate($distanceY: -50%);
        position: absolute;
        margin-top: 0;
        top: 50%;
        left: 0;
        right: 0;
      }
    }
  }
}
