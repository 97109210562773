.immersive-layout {
  @include displayFlex;
  @include flexDirection(column);
  min-height: 100vh;

  > .maincontent {
    @include flex(1 1 auto);
    padding: rem-calc(30);
  }
}

.immersive-header {
  @include shadow6;
  @include displayFlex;
  @include justifyContent(space-between);
  @include alignItems(center);
  background: $header-background;
  color: set-color($header-background, $body-text-color, #fff);
  z-index: 20;
  min-height: rem-calc(87);
  padding: rem-calc(20 30);

  .page-banner {
    margin-left: 0;
    margin-right: 0;
  }

  .page-header {
    @include displayFlex;
    @include alignItems(center);
    margin-bottom: 0;
  }

  .title {
    margin-bottom: 0;
  }

  .headerinfo {
    font-size: rem-calc(14);

    > .info {
      display: inline-block;
      margin-right: rem-calc(40);
      margin-top: rem-calc(6);

      .header, .text {
        display: inline;
      }
      .header {
        font-weight: 500;
        font-size: rem-calc(14);

        &:after {
          content: "\a0";
          margin-right: rem-calc(10);
          display: inline-block;
        }
      }
      .text {
        font-weight: normal;
      }
    }
  }

  .subtitle {
    font-size: rem-calc(12);
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    line-height: rem-calc(24);
    margin-top: 0;
  }

  wm-button {
    &[icon="close"] {
      margin: rem-calc(0 30 0 0);

      [dir="RTL"] & {
        margin: rem-calc(0 0 0 30);
      }

      &:before {
        @include translate($distanceY: -50%);
        line-height: normal;
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
      }
    }

    &.cancel {
      margin: rem-calc(0 30 0 0);

      [dir="RTL"] & {
        margin: rem-calc(0 0 0 30);
      }
    }
  }

  .button {
    display: inline-block;

    &.cancel,
    &.goback {
      @include flex(none);
      align-self: center;
      line-height:1.65;
      margin-right:rem-calc(10);
      height:rem-calc(40);
      width:rem-calc(40);
      text-align:center;
    }

    &.cancel {
      margin: rem-calc(0 30 0 0);

      [dir="RTL"] & {
        margin: rem-calc(0 0 0 30);
      }

      &:before {
        @include translate($distanceY: -50%);
        line-height: normal;
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
      }
    }

    &.-secondary:not(.-disabled):not(:disabled), &.-icononly {
      background: set-color($header-background, $button-default-background, $button-dark-background);
      color: set-color($header-background, $button-default-text, #fff);
      border-color: set-color($header-background, $button-default-text, #fff);

      &:hover {
        background: set-color($header-background, mix(black, $button-default-background, 10%), #fff);
        color: set-color($header-background, #fff, $button-default-text);
      }
    }

    &.-primary:not(.-disabled):not(:disabled), &.-icononly {
      background: set-color($header-background, $button-primary-background, $button-primary-dark-background);
      color: set-color($header-background, #fff, $button-primary-background);
      border-color: set-color($header-background, $button-default-text, #fff);


      &:hover {
        background: set-color($header-background, mix(black, $button-primary-background, 20%), mix(black, $button-primary-dark-background, 10%));
        color: set-color($header-background, #fff, $button-default-text);
      }
    }

    &.-secondary:disabled, &.-secondary.-disabled {
      background: set-color($header-background, $button-default-disabled-background, $button-dark-background);
    }

    .user-is-tabbing &.-primary:focus, .user-is-tabbing &.-secondary:focus {
      @if (lightness($header-background) > 50) {
        @extend %focus-styles;
      } @else {
        @extend %focus-on-dark;
      }
    }

    &.-navigational {
      color: set-color($header-background, $button-default-text, #fff);

      &:hover {
        background: set-color($header-background, #d7d6d9, #fff);
        color: set-color($header-background, $button-default-text, $header-background);
      }
    }
  }

  .search-form.-navigate {
    margin-top: rem-calc(24);

    .field {
      color: #fff;
      background: $header-background;
      font-size: rem-calc(24);
      font-weight: 300;
    }

    > .count {
      padding-left: rem-calc(10);
      right: rem-calc(25);
      color: #949494;
      display: block;
    }
  }
}
