.node-stats {
  color:#7d7d7d;

  &.-sections {
    display:inline-block;
    max-width:rem-calc(700);
    width:100%;

    .group {
      display:block;
      max-width:inherit;
    }

    .info {
      @include type-level-6;
      font-weight: normal;
      font-style: italic;
      padding-left:rem-calc(6);
    }

    .heading {
      @include type-level-4;
      font-weight:normal;
      padding-right:rem-calc(150);
      position:relative;
      text-align:left;
      width:100%;

      > .date {
        @include type-level-6;
        font-weight: normal;
        position:absolute;
        right:0;
        top:3px;
      }
    }

    .stats {
      margin:rem-calc(0 0 0 15);

      .stat > .label:after {
        content:none;
      }
    }

    .stat > .label {
      padding-right:0;
      text-align:left;
      width:inherit;
    }
  }
  
  &.-program .stat {
    > .label {
      width:auto;
    }
    > .lookup {
      font-style:italic;
      padding-left:rem-calc(6);
    }
  }

  &.-selected {
    .stat {
      > .label {
        width:auto;
      }
      > .lookup {
        font-style:italic;
        padding-left:rem-calc(6);
      }
    }

    .stat.-shared > .info {
      &:after {
        content:",";
        display:inline-block;
        padding-right:rem-calc(10);
        font-weight:normal;
      }
      &:last-child:after {
        content:none;
      }
    }
  }

  .group {
    display:inline-block;
    padding-right:rem-calc(20);
    padding-bottom:rem-calc(20);
    vertical-align: top;
  }
  .heading {
    @include type-level-6;
    font-weight:bold;
    margin:0;
  }
  .heading,
  .stat > .label {
    display: inline-block;
    padding-right:rem-calc(6);
    vertical-align: top;
  }
  .stats {
    list-style-type: none;
    margin:0;
    padding:0;

    .stat {
      @include type-level-6;
      font-weight:bold;
      line-height:2;

      > .label {
        font-weight: normal;
        vertical-align: bottom;

        &:after {
          content:":";
          display:inline-block;
        }
      }

      > .count {
        display: inline-block;
      }
    }
  }
}
