*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  margin: 0;
}
body {
  padding: 0;
  margin: 0;
  background-color: #fafafa;
  color: #4a4a4a;
  line-height: 1.4;
}

wm-tab-list,
wm-tab-item,
wm-tab-panel,
wm-navigator,
wm-network-uploader,
wm-action-menu,
wm-menuitem,
wm-chart,
wm-datepicker,
wm-modal-footer,
wm-modal-header,
wm-search,
wm-uploader,
wm-chart-slice,
wm-modal,
wm-pagination,
wm-wrapper,
priv-chart-popover,
priv-datepicker,
priv-navigator-button,
priv-navigator-item,
wm-snackbar,
wm-option,
wm-select,
wm-button {
  visibility: hidden;
}

main {
  @include flex(1);
}

p {
  max-width: 37em;
}

a {
  color: $button-default-text;
  text-decoration: none;
  font-weight: 500;

  &:hover:not(.button):not([role]) {
    background: linear-gradient($button-default-text, $button-default-text)
      no-repeat;
    background-size: 100% 1px;
    background-position: 0 1.2em;
    padding-bottom: 0.2em;
  }

  &:not(.button):not([role]):focus {
    outline: none;

    .user-is-tabbing & {
      background: linear-gradient(90deg, #3862e9 66%, transparent 0) repeat-x;
      background-size: 6px 3px;
      background-position: 0 1.2em;
      padding-bottom: 0.2em;
    }
  }

  &:focus {
    outline: 0;
  }
}

.user-is-tabbing button.-textonly:focus {
  background: linear-gradient(90deg, #3862e9 66%, transparent 0) repeat-x;
  background-size: 6px 3px;
  background-position: 0 1em;
  border-radius: 0;
  line-height: normal;
}

::-webkit-input-placeholder {
  color: #787878;
}
:-moz-placeholder {
  color: #787878;
}
::-moz-placeholder {
  color: #787878;
}
:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder {
  color: #787878;
}

input[type="text"],
input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
}
