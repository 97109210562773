.tool-tip {
  position: absolute;
  top: 50%;
  right: rem-calc(66);
  transform: translateY(-50%);
  width: rem-calc(200);
  text-align: right;
  line-height: normal;

  .tooltip {
    background: rgba(black, .7); //#4c4c4c;
    color: #fff;
    font-size: 12px;
    padding: rem-calc(5);
    border-radius: 2px;
    transition: .3s 0s;
    transform: scaleY(0.1) scaleX(0.1) translateY(0px) translateX(80%);
    transform-origin: right;
    opacity: 0;
    display: inline-block;
    vertical-align: middle;
  }
}
