@import "components/material";
$light-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2),
  0 2px 2px 0 rgba(87, 81, 149, 0.12);
$light-hover-shadow: 0 4px 2px 0 rgba(239, 238, 241, 0.2),
  0 2px 2px 0 rgba(0, 0, 0, 0.1);
$dark-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
$dark-hover-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.1),
  0 2px 2px 0 rgba(0, 0, 0, 0.2);
$staticpath: "/" !default;

%focus-styles {
  @include box-shadow(
    0 2px 2px 0 rgba(244, 243, 246, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.1),
    0 0 4px 3px #61279e
  );
  outline: none;
}

%focus-styles-inset {
  @include box-shadow(
    inset 0 2px 2px 0 rgba(244, 243, 246, 0.2),
    inset 0 2px 2px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 4px 3px #61279e
  );
  outline: none;
}

%focus-on-dark {
  @include box-shadow(
    0 2px 2px 0 rgba(244, 243, 246, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.1),
    0 0 4px 3px #c6b4e3
  );
  outline: none;
}

%field-focus {
  @include box-shadow(0 0 0 1px #20cbd4);
  outline: none;
  border-color: #20cbd4;
}

%invalid {
  @include box-shadow(0 0 0 1px $error-color);
  border-color: $error-color;
}

%visible-tooltip {
  clip: auto;
  width: auto;
  height: auto;
  font-size: 14px;
  background: black;
  color: #fff;
  padding: 6px;
  top: 13px;
  right: 36px;
  left: auto;
  z-index: 30;
  white-space: nowrap;
  opacity: 1;
  transition: opacity 500ms 500ms;
  line-height: normal;

  &.-left {
    right: auto;
    left: calc(100% - 6px);
  }

  &.-bottom {
    @include translate($distanceX: -50%);
    right: auto;
    left: 50%;
    top: calc(100% - 6px);
  }

  &.-multiline {
    white-space: normal;
  }
}
/* ============================================
   Layout elements
   ============================================ */

#app {
  height: 100vh;
}

.pagewrapper {
  @include displayFlex;
  @include flexDirection(column);
  height: 100vh;
  min-height: 0;

  #appnonav & {
    margin-left: 0;
  }

  &.immerse {
    margin-left: 0;
    display: inline;
  }
  @media print {
    overflow: visible;
  }
  @media only screen and (max-width: $mobile-nav-width) {
    margin-left: 0 !important;
  }

  > .mainpage {
    @include flex(1 1 auto);
    @include displayFlex;
  }
}

.nav-main {
  @include displayFlex;
  @include flexDirection(row);
  @include flex(1);
  min-height: 0;
}

.contentwrapper {
  @include displayFlex;
  @include flexDirection(column);
  @include flex(1);
  overflow-y: auto;

  #maincontent {
    @include flex(1 1 auto);
  }
}

.content-wrapper {
  @include displayFlex;
  @include flexDirection(column);
}

#elmDiv {
  @include flex(1);
  @include displayFlex;
}

.mainpage {
  @include flex(1);
  min-width: 0;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.pagecontent {
  background: #fafafa;
  padding-bottom: rem-calc(36);
  padding-left: $main-content-padding;
  padding-right: $main-content-padding;
  position: relative;
}

.page-content {
  padding-top: rem-calc(30);
}

.subsection {
  margin-bottom: rem-calc(40);
}

.contentheader {
  font-size: rem-calc(24);
  font-weight: 400;
  margin: rem-calc(30 0 20);

  &:first-child {
    margin-top: 0;
  }
}

.toolbar {
  margin: rem-calc(-10 0 20);
}

.grid-row {
  display: -ms-grid;
  display: grid;
}

%overlay {
  @include transition(opacity 0.5s ease-out);
  background-color: rgba(25, 25, 25, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  cursor: pointer;
  opacity: 0;
}

.action-form ul {
  display: inline-flex;
  flex-direction: column;
}
/* ============================================
   Buttons and Inputs
   ============================================ */

%disabled {
  cursor: default;
  background: #737373;
}

.button,
button {
  @include transition(
    background-color 500ms cubic-bezier(0.4, 0, 0.2, 1),
    color 500ms cubic-bezier(0.4, 0, 0.2, 1)
  );
  @include border-radius(5px);
  border: 2px solid $button-primary-background;
  color: $button-default-text;
  font-size: rem-calc(12);
  font-weight: 700;
  height: 40 / 12 * 1em;
  padding: 0 (16 / 12 * 1em);
  cursor: pointer;
  position: relative;
  background: $button-default-background;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  @media only screen and (max-width: 768px) {
    height: rem-calc(44);
  }

  &.small {
    height: rem-calc(25);
    padding: rem-calc(0 8);
    line-height: rem-calc(25);
  }

  &:hover {
    background: mix(black, $button-primary-background, 20%);
    color: #fff;
  }

  &:active:not(:disabled):not(.-disabled):not(.-textonly):not(.-actionbutton):not(.selectedoption) {
    @include scale($xVal: 0.9, $yVal: 0.9);
  }

  &.-primary:not(.-textonly),
  &.large {
    @include box-shadow(
      0 2px 2px 0 rgba(87, 81, 149, 0.12),
      0 2px 2px 0 rgba(0, 0, 0, 0.2)
    );
    background: $button-primary-background;
    color: $button-primary-text;

    &.-disabled,
    &.-disabled:active,
    &.-disabled:hover,
    &:disabled,
    &:disabled:active,
    &:disabled:hover {
      background: $button-primary-disabled-background;
      color: $button-primary-disabled-color;
    }

    &:hover {
      background: mix(black, $button-primary-background, 20%);
    }
  }

  &.-neutral:not(.-textonly) {
    @include box-shadow(
      0 2px 2px 0 rgba(11, 144, 156, 0.12),
      0 2px 2px 0 rgba(0, 0, 0, 0.2)
    );
    background: #0da1ae;
    color: #fff;

    &:hover {
      @include box-shadow(
        0 4px 2px 0 rgba(13, 161, 174, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.2)
      );
      background: mix(black, #0da1ae, 10%);
    }
  }

  &.-secondary:disabled {
    @include box-shadow(none);
  }

  &.-primary:focus,
  &.-secondary:focus,
  &:focus {
    outline: none;
  }

  .user-is-tabbing &.-neutral:focus,
  .user-is-tabbing &.-primary:focus,
  .user-is-tabbing
    &.-secondary:focus:not(:disabled):not(.-disabled):not(.-textonly):not(.-actionbutton),
  .user-is-tabbing
    &:focus:not(:disabled):not(.-disabled):not(.-textonly):not(.-actionbutton):not(.selectedoption) {
    @extend %focus-styles;
  }

  &.-disabled,
  &.-disabled:active,
  &.-disabled:hover,
  &:disabled,
  &:disabled:active,
  &:disabled:hover {
    background: $button-default-disabled-background;
    border-color: $button-disabled-border-color;
    color: $button-default-disabled-color;
    cursor: default;
  }

  &.-textonly {
    @include box-shadow(none);
    letter-spacing: 0;
    text-transform: none;
    font-size: rem-calc(14);
    border: none;
    background: transparent;
    padding-left: 0;
    padding-right: 0;
    height: auto;
    border-radius: 0;
    line-height: normal;

    + .-textonly {
      margin-left: rem-calc(5);
    }

    &.small {
      font-size: rem-calc(10);
    }

    &:hover:not(:disabled):not(.-disabled):not(.-raised) {
      text-decoration: underline;
      color: #454077;
    }

    &.-disabled,
    &:disabled {
      cursor: default;
    }

    &.-action {
      padding: 0;
      line-height: normal;
      height: auto;

      &:hover {
        background: transparent !important;
      }
    }
  }

  &.-list {
    border-width: 1px;
    text-transform: capitalize;
    width: 100%;
    text-align: left;

    &:before {
      position: absolute;
      right: rem-calc(20);
    }

    &.-active {
      background: #d7d6d9;
      color: $button-default-text;
    }
  }

  &.-icononly,
  &.-navigational {
    @include box-shadow(none);
    @include border-radius(50%);
    font-size: rem-calc(16);
    padding: 0;
    height: auto;
    min-width: rem-calc(40);
    min-height: rem-calc(40);
    text-align: center;
    letter-spacing: normal;
    @media only screen and (max-width: 768px) {
      min-height: rem-calc(44);
      min-width: rem-calc(44);
    }

    &.-delete:before,
    &.cancel:not([class*="mdi"]):before,
    &.close:before {
      content: "\f156";
    }

    &.goback:before {
      content: "\f04d";
    }
  }

  &.-icononly {
    border-width: 1px;

    &:before {
      @include mdi-icon;
    }
  }

  &.-navigational {
    border: none;
    background-color: transparent;
    color: $button-default-text;

    &:before {
      @include mdi-icon;
    }

    &.selected,
    &:hover {
      background-color: #d7d6d9;
    }
  }

  &.panel-toggle.-active,
  &.panel-toggle:active {
    background: rgba($button-primary-background, 0.1);
    color: $button-default-text;
  }

  &.-actionbutton,
  &.button.-actionbutton {
    @include box-shadow(none);
    height: rem-calc(40);
    width: rem-calc(40);
    border-radius: rem-calc(40);
    font-size: rem-calc(18);
    padding: 0;

    &:active {
      width: rem-calc(30);
      height: rem-calc(30);
      border-radius: 50%;
    }

    &:focus {
      outline: none;

      .user-is-tabbing & {
        @extend %focus-styles;
      }
    }

    &.-small {
      width: rem-calc(30);
      height: rem-calc(30);

      &:before {
        font-size: rem-calc(16);
        line-height: normal;
      }
    }
  }

  &.assignlead:before {
    @include mdi-icon;
    content: "\f014";
    margin-right: rem-calc(5);
  }

  [dir="RTL"] & {
    &.assignlead:before {
      margin-left: rem-calc(5);
      margin-right: 0;
    }
  }
}

.button.-browse,
a.button {
  line-height: 36 / 12 * 1em;
  @media only screen and (max-width: 768px) {
    line-height: rem-calc(40);
  }

  &.-icononly {
    line-height: rem-calc(40);
    height: rem-calc(40);
    @media only screen and (max-width: 768px) {
      height: rem-calc(44);
    }
  }
}

.wm-actions {
  position: relative;
}

wmrk-action-item .icon {
  margin-right: rem-calc(10);
}

.actiondd {
  @include shadow8;
  @include translate($distanceX: -100%);
  background: #fff;
  display: none;
  z-index: 10;
  position: absolute;
  text-align: left;

  &.show {
    display: block;
    margin-left: rem-calc(46);
  }

  > .option {
    padding: rem-calc(20);
    cursor: pointer;
    color: $button-default-text;
    white-space: nowrap;

    &.hover,
    &:focus,
    &:hover {
      background: #f4f4f4;
    }

    &:focus {
      outline: none;
    }

    &:not(:first-child) {
      border-top: 1px solid #e7e7e7;
    }

    &.-edit:before {
      content: "\f3eb";
    }

    &.-delete:before {
      content: "\f1c0";
    }

    &:before {
      @include mdi-icon;
      font-size: rem-calc(16);
      margin-right: rem-calc(15);
    }

    .icon {
      margin-right: rem-calc(10);
    }
  }

  [dir="RTL"] & {
    @include translate($distanceX: 100%);

    &.show {
      margin-left: 0;
      margin-right: rem-calc(46);
    }

    > .option .icon {
      margin-left: rem-calc(10);
      margin-right: 0;
    }
  }
}
// Remove or edit once we have the icononly styles

.button.-toggle,
button.-toggle {
  padding: 0;
  margin-right: rem-calc(11);
  font-size: 16px;
  border: none;
  background: transparent;
}
// Remove or edit once we have the icononly styles

.button.tab,
button.tab {
  @include box-shadow(none);
  border: none;
}

.button-collection {
  white-space: nowrap;

  > .button + .button {
    margin-left: rem-calc(16);

    [dir="RTL"] & {
      margin-left: 0;
      margin-right: rem-calc(16);
    }
  }

  > wm-button + .button {
    margin-left: rem-calc(16);

    [dir="RTL"] & {
      margin-left: 0;
      margin-right: rem-calc(16);
    }
  }

  > wm-button + wm-button {
    margin-left: rem-calc(16);

    [dir="RTL"] & {
      margin-left: 0;
      margin-right: rem-calc(16);
    }
  }
}

.wm-actions + wm-button,
button + wm-action-menu,
wm-button + wm-button,
wm-select + wm-button {
  margin-left: rem-calc(16);
}

.radiolistheader {
  font-size: rem-calc(14);
  font-weight: normal;
  margin: 0;
}

input[type="checkbox"].chkbx-input,
input[type="radio"].radio-input {
  position: absolute;
  clip: rect(0, 0, 0, 0);

  + .chkbx,
  + .radio {
    display: inline-block;
    font-size: rem-calc(14);
    margin-bottom: 0;

    &:before {
      color: $button-default-text;
      margin-right: rem-calc(8);
      vertical-align: middle;
    }

    + label {
      font-size: rem-calc(14);
      margin-left: rem-calc(5);
      vertical-align: middle;
    }

    &.-multiline {
      vertical-align: text-top;

      + label {
        vertical-align: baseline;
      }
    }
  }

  + .chkbx:before {
    @include border-radius(2px);
    content: "";
    background: url("https://cdn.watermarkinsights.com/images/checkbox3.svg")
      no-repeat 0 0;
    width: 13px;
    height: 13px;
    display: inline-block;
  }

  + .radio:before {
    @include mdi-icon;
    font-size: rem-calc(16);
    content: "\f43d";
  }

  &:checked:not(:disabled) + .chkbx.-success:before {
    color: $input-checked;
  }

  &:disabled,
  &[disabled=""] {
    + .chkbx,
    + .radio {
      cursor: not-allowed;
      color: $disabled-color;
    }

    + .chkbx:before {
      background-image: url("https://cdn.watermarkinsights.com/images/checkbox3.svg");
      background-position: 0 -40px;
    }

    + .radio:before {
      color: $disabled-color;
    }

    &:checked + .chkbx,
    &:checked + .radio {
      color: $disabled-color;
    }

    &:checked + .chkbx:before {
      background-image: url("https://cdn.watermarkinsights.com/images/checkbox3.svg");
      background-position: 0 -60px;
    }
  }
}

.user-is-tabbing input[type="checkbox"].chkbx-input:focus + .chkbx:before,
.user-is-tabbing input[type="radio"].radio-input:focus + .radio:before,
.user-is-tabbing .toggle-switch input:focus + .switchtoggle {
  @extend %focus-styles;
}

.custom-chkbx,
.custom-radio {
  position: absolute;
}

.custom-chkbx + .chkbxlabel,
.custom-radio + .radiolabel {
  cursor: pointer;
  display: inline-block;
  padding-left: rem-calc(25);
}

input[type="checkbox"].chkbx-input:checked + .chkbx {
  font-size: rem-calc(14);

  &:before {
    background: url("https://cdn.watermarkinsights.com/images/checkbox3.svg")
      no-repeat;
    background-position: 0 -20px;
  }
}

input[type="radio"].radio-input:checked + .radio:before {
  content: "\f43e";
}

input[type="checkbox"].chkbx-input:checked:focus:not(:disabled)
  + .chkbx.-success:before,
input[type="radio"].radio-input:checked:focus:not(:disabled)
  + .chkbx.-success:before {
  text-shadow: 1px 1px 6px red;
}

.toggle-switch {
  border: none;
  margin-bottom: rem-calc(24);
  outline: 0;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    opacity: 0;
    padding: 0;
    position: absolute;
    clip: rect(0, 0, 0, 0);

    + .switchtoggle {
      @include border-radius(rem-calc(56));
      @include transition(left 0.15s ease-out);
      cursor: pointer;
      margin: 0;
      display: inline-block;
      vertical-align: baseline;
      color: transparent;
      background: rgba(255, 255, 255, 0.33);
      border: 1px solid $button-primary-background;
      width: rem-calc(56);
      height: rem-calc(26);
      position: relative;
      text-align: left;

      &:before {
        position: relative;
        content: attr(data-checked);
        border: none;
        background: none;
        width: rem-calc(39);
        height: 100%;
        color: $body-text-color;
        font-size: rem-calc(12);
        font-weight: bold;
        line-height: rem-calc(24);
        text-transform: uppercase;
        text-align: right;
        display: inline-block;
        left: rem-calc(6);
      }

      &:after {
        @include transition(left 0.15s ease-out);
        @include border-radius(50%);
        border: 1px solid $button-primary-background;
        background: #ffffff;
        content: "\A0";
        display: block;
        position: absolute;
        top: rem-calc(5);
        left: rem-calc(7);
        width: rem-calc(13);
        height: rem-calc(13);
      }
    }

    &:checked + .switchtoggle {
      border-color: $button-primary-background;

      &:before {
        content: attr(data-checked);
        text-align: left;
        left: rem-calc(6);
      }

      &:after {
        left: rem-calc(34);
        background: $button-primary-background;
      }
    }

    &:disabled + .switchtoggle {
      cursor: default;
      border-color: $gray;

      &:after {
        @include mdi-icon;
        background: $gray;
        font: normal normal normal 24px/1 "Material Design Icons";
        content: "\f33e";
        color: #ffffff;
        font-size: rem-calc(9);
        padding-top: rem-calc(2);
        padding-right: rem-calc(1);
        padding-left: rem-calc(1);
      }
    }
  }

  label {
    margin-right: rem-calc(5);
    font-size: rem-calc(12);
  }

  .switchtoggle + label {
    margin-left: rem-calc(5);
    margin-right: 0;
  }
}

.mdl-textfield {
  + .count {
    font-size: rem-calc(14);
    text-align: right;
    margin-top: rem-calc(5);
  }
}

/* ============================================
   Components
   ============================================ */
wm-action-menu {
  font-size: rem-calc(14);
}

/* ============================================
   Helpers
   ============================================ */

.flex1 {
  @include flex(1 1 auto);

  > label {
    display: block;
  }
}

.input-group {
  @include displayFlex;
  border-bottom: 1px solid #979797;
  position: relative;

  .field {
    @include flex(1);
    border: none;
    font-size: rem-calc(18);
    line-height: normal;
  }
}

.nobullets {
  list-style: none;
  margin: 0;
  padding-left: 0;

  [dir="RTL"] & {
    padding-right: 0;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  top: 0;
  left: 0;
}

button .tooltip {
  opacity: 0;
  font-weight: 400;
  text-transform: none;
}

.user-is-tabbing button:focus .tooltip {
  clip: auto;
  width: auto;
  height: auto;
  font-size: 14px;
  background: black;
  color: #fff;
  padding: 6px;
  top: 13px;
  right: 36px;
  left: auto;
  z-index: 30;
  white-space: nowrap;
  opacity: 1;
  transition: opacity 500ms 500ms;
  line-height: normal;

  &.-left {
    right: auto;
    left: calc(100% - 6px);
  }

  &.-bottom {
    @include translate($distanceX: -50%);
    right: auto;
    left: 50%;
    top: calc(100% - 6px);
  }

  &.-multiline {
    white-space: normal;
  }
}

@media (hover) {
  button:hover .tooltip {
    clip: auto;
    width: auto;
    height: auto;
    font-size: 14px;
    background: black;
    color: #fff;
    padding: 6px;
    top: 13px;
    right: 36px;
    left: auto;
    z-index: 30;
    white-space: nowrap;
    opacity: 1;
    transition: opacity 500ms 500ms;
    line-height: normal;

    &.-left {
      right: auto;
      left: calc(100% - 6px);
    }

    &.-bottom {
      @include translate($distanceX: -50%);
      right: auto;
      left: 50%;
      top: calc(100% - 6px);
    }

    &.-multiline {
      white-space: normal;
    }
  }
}

.reqd {
  color: #c4431c;
  font-size: rem-calc(16);

  .error & {
    color: $error-color;
  }
}

.required-notice {
  font-size: rem-calc(14);
  text-align: center;
  margin-bottom: rem-calc(20);
  margin-left: auto;
  margin-right: auto;
}
/* ============================================
   Lists
   ============================================ */

.people-list {
  list-style: none;
  padding: 0;

  > .person {
    @include displayFlex;
    @include alignItems(center);
    padding: rem-calc(10 0);

    .info,
    .name {
      @include flex(1);
      display: inline-block;
    }

    &.active {
      background-color: #f4f4f4;
    }

    .info {
      font-style: italic;
      margin-left: rem-calc(40);
    }
  }

  &.-selectionlist {
    .person {
      padding: rem-calc(10);

      &.focus,
      &:hover {
        margin-left: rem-calc(-30);
        margin-right: rem-calc(-30);
        padding-left: rem-calc(40);
        padding-right: rem-calc(40);
        background: rgba(46, 27, 70, 0.05);
      }
    }

    .selected {
      .info,
      .name {
        opacity: 0.5;
      }
    }
  }
}

.person {
  .avatar {
    @include border-radius(rem-calc(17));
    display: inline-block;
    width: rem-calc(34);
    height: rem-calc(34);
    line-height: rem-calc(34);
    color: #fff;
    background: #c8dfcc;
    text-align: center;
    margin-right: rem-calc(10);
    text-transform: uppercase;

    [dir="RTL"] & {
      margin-right: 0;
      margin-left: rem-calc(10);
    }

    &.icon-color-1 {
      background: #c8dfcc;
    }

    &.icon-color-2 {
      background: rgba(250, 213, 135, 0.6);
      color: #8d5d11;
    }

    &.icon-color-3 {
      background: rgba(133, 218, 223, 0.6);
      color: #0da1ae;
    }

    &.icon-color-4 {
      background: rgba(226, 231, 104, 0.6);
      color: #a2a938;
    }

    &.icon-color-5 {
      background: rgba(255, 181, 158, 0.6);
      color: #e56e49;
    }
  }

  .genericavatar {
    margin-right: rem-calc(3);
  }
}

.cslist li {
  display: inline;

  + li:before {
    content: ", ";
    white-space: pre;
  }
}

.loader {
  &.-fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: rgb(255, 255, 255);

    > .content {
      margin: -130px auto 0;
      top: 50%;
      text-align: center;
      position: relative;
      padding-top: 130px;
      color: #8360b2;

      // &.watermark-logo {
      //   background: url("#{$staticpath}images/watermark_loader.svg") no-repeat
      //     50% 0;
      // }

      // &.content-loader {
      //   background: url("#{$staticpath}images/content-loader.svg") no-repeat 50%
      //     0;
      // }
      // @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      //   &.content-loader {
      //     background: url("#{$staticpath}images/content-loader.gif") no-repeat
      //       50% 0;
      //   }
      // }
    }
  }

  &.-inline {
    display: inline-block;
    width: 1em;
    height: 1em;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
    border-radius: 50%;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: bouncing-loader 1.8s infinite ease-in-out;
    animation: bouncing-loader 1.8s infinite ease-in-out;
    top: -1em;

    &,
    &:after,
    &:before {
      border-radius: 50%;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation: bouncing-loader 1.8s infinite ease-in-out;
      animation: bouncing-loader 1.8s infinite ease-in-out;
    }

    &:after,
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: inherit;
      height: inherit;
    }

    &:before {
      right: 100%;
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    &:after {
      left: 100%;
    }
  }
}

.loader.-inline {
  color: #d5d1da;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes bouncing-loader {
  0%,
  100%,
  80% {
    -webkit-box-shadow: 0 1em 0 -1em;
    box-shadow: 0 1em 0 -1em;
  }

  40% {
    -webkit-box-shadow: 0 1em 0 -0.2em;
    box-shadow: 0 1em 0 -0.2em;
  }
}
@keyframes bouncing-loader {
  0%,
  100%,
  80% {
    -webkit-box-shadow: 0 1em 0 -1em;
    box-shadow: 0 1em 0 -1em;
  }

  40% {
    -webkit-box-shadow: 0 1em 0 -0.2em;
    box-shadow: 0 1em 0 -0.2em;
  }
}
