.flex-row {
  @include displayFlex;

  &.-justified {
    @include justifyContent(space-between);
  }
  &.-verticallycentered {
    @include alignItems(center);
  }
  &.-wrap {
    @include flexWrap(wrap);
  }
}
