@import "../../common-style/css/settings";
@import "../../common-style/css/mixins";
@import "../../common-style/css/functions";
@import "../../common-style/css/inter";
@import "../../common-style/css/normalize";
@include normalize();
html {
  scroll-behavior: smooth;
}

#submission-button-container-id {
  padding-bottom: rem-calc(32);
  padding-right: rem-calc(33);
}

.template-document-container {
  padding-top: rem-calc(20);
  padding-bottom: rem-calc(18);
  padding-right: rem-calc(33);
  &.template-document-container {
    padding-bottom: 0;
    padding-right: 0;
  }
}

.confirmation-checkbox {
  padding: rem-calc(0 33 16);
  .reqd-label {
    padding-left: rem-calc(4);
  }
}

.error-message {
  color: rgb(192, 57, 43);
  font-style: italic;
  font-size: rem-calc(14);
  &.helper-error-text {
    text-align: right;
    padding: rem-calc(0 30 42 0);
  }
}

#student-error {
  &.error-message {
    margin-right: rem-calc(24);
  }
}

.confirmation-error {
  color: rgb(192, 57, 43);
  font-weight: bold;
  &:after {
    display: inline-block;
    font: normal normal normal 24px/1 Material Design Icons;
    font-size: rem-calc(24);
    line-height: 1;
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\F026";
    margin-left: rem-calc(5);
  }
}

.file-submissions {
  min-height: rem-calc(60);
  padding-top: rem-calc(16);
  &.description-file-submission,
  &.reference-files {
    padding: rem-calc(8 0 0 0);
  }
  .file-container {
    padding: rem-calc(0 33 16);
    .-timestamp {
      margin-right: rem-calc(16);
    }
    &.description-file-upload {
      padding: rem-calc(0 0 16);
      &:last-child {
        padding-bottom: rem-calc(0);
      }
      .file-name {
        padding-right: rem-calc(4);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &:last-child {
      padding-bottom: rem-calc(32);
    }
    .file-details {
      background: rgb(250, 250, 250);
      border-radius: 3px;
      border: 1px solid rgb(107, 107, 107);
      padding: rem-calc(8 20);
    }
  }
}

.requirement-content {
  margin: auto;
  margin-top: rem-calc(24);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  &.review-student {
    flex-grow: 1;
    margin: unset;
    padding-top: rem-calc(24);
    overflow-y: auto;
  }
  .paper-card {
    .user-is-tabbing &:focus {
      box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%),
        0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
      outline: none;
    }
  }
  .requirement-submission-status {
    width: 100%;
    max-width: rem-calc(480);
    margin-right: rem-calc(30);
    @media screen and (max-width: 1024px) {
      margin: 0 auto;
    }
  }
  .student-submission-card {
    width: 100%;
    max-width: rem-calc(800);
    @media screen and (max-width: 1024px) {
      margin: 0 auto;
    }
    .paper-card .data-table {
      margin: rem-calc(0 -30);
      .dataheaderrow {
        height: rem-calc(40);
        th {
          padding-left: rem-calc(30);
          background-color: rgba(46, 27, 70, 0.05);
          font-size: rem-calc(12);
          text-transform: uppercase;
          width: 33%;
          text-align: left;
          &:hover {
            cursor: pointer;
          }
          &:focus {
            box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
              inset 0 2px 2px 0 rgb(0 0 0 / 10%), inset 0 0 4px 3px #61279e;
            outline: none;
          }
        }
        &.empty-row {
          background-color: rgba(46, 27, 70, 0.05);
        }
      }
      .databody {
        .datarow {
          height: rem-calc(60);
          border-bottom: 2px solid #f4f3f6;
          td {
            padding: rem-calc(12 12 12 30);
          }
        }
      }
    }
    .footer {
      padding: rem-calc(12 0);
    }
  }
  .submission-body {
    margin-top: rem-calc(20);
    padding-bottom: rem-calc(20);
    .submission-content {
      @include displayFlex;
      @include flexDirection(column);
      @include alignItems(center);
      @include justifyContent(center);
      height: 100%;
      &.awaiting-review-students {
        @include flexDirection(row);
        .awaiting-review-count {
          .submission-count {
            text-align: center;
          }
        }
        .awaiting-review-button {
          padding-left: rem-calc(24);
          &.review-app-btn {
            padding-left: 0;
          }
        }
      }
      &.submission-content-app {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: rem-calc(24);
      }
    }
    .submission-count {
      font-size: rem-calc(24);
    }
    .submission-status {
      font-size: rem-calc(16);
      font-weight: 500;
      padding-bottom: rem-calc(12);
    }
    .status-bar-chart {
      margin-top: rem-calc(24);
      padding-top: rem-calc(24);
      border-top: 1px solid rgb(220, 220, 220);
    }
  }
  .description-block,
  .app-details-section {
    margin-bottom: rem-calc(16);
  }
  .app-details-section {
    .app-details-value {
      margin: 0;
      margin-bottom: rem-calc(4);
      list-style-type: none;
      padding: 0;
      &.timelog-list {
        padding: 0;
        list-style-type: none;
      }
    }
    .date-value {
      color: rgb(107, 107, 107);
      font-size: rem-calc(14);
      font-style: italic;
      line-height: rem-calc(20);
      margin-left: rem-calc(2);
    }
  }
  .confirmation-label-section {
    margin-left: rem-calc(16);
    margin-top: rem-calc(4);
    padding: rem-calc(12);
    background: rgb(250, 250, 250);
  }
  .refence-link-content {
    margin-top: rem-calc(4);
    color: rgb(87, 81, 149);
    font-weight: bold;
  }
  .textfieldlabel,
  .app-details-label {
    font-size: rem-calc(14);
    font-weight: 600;
    padding-bottom: rem-calc(4);
    padding-right: rem-calc(4);
    margin: 0;
  }
  .file-details {
    background: rgb(250, 250, 250);
    border-radius: 3px;
    border: 1px solid rgb(107, 107, 107);
    padding: rem-calc(8 20);
    max-width: rem-calc(550);
  }
  .left-cards {
    margin-right: rem-calc(20);
    @media screen and (max-width: 1024px) {
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 1024px) {
    display: block;
  }
}

#cohort-requirement {
  flex-direction: column;
  .requirement-submission-content {
    display: flex;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
    .section-card {
      width: 50%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
      &.reqirement-card {
        margin-right: rem-calc(24);
      }
      &.application-details-card {
        width: 45%;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }
      &.application-status-card {
        width: 55%;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }
    }
  }
  .submission-status-card-body {
    .status-bar-chart {
      margin-top: 0;
      border-top: none;
      padding-top: 0;
      padding-bottom: rem-calc(20);
      margin-bottom: rem-calc(24);
      border-bottom: 1px solid rgb(220, 220, 220);
      &.app-bar-chart {
        padding-top: rem-calc(24);
        margin-bottom: 0;
        border: none;
        border-top: 1px solid rgb(220, 220, 220);
      }
      &.timelog-chart {
        margin-right: rem-calc(-2);
      }
    }
    .view-submissions {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
  .student-submission-card {
    max-width: unset;
  }
}

.immersive-container-student {
  height: calc(100vh - 100px);
  height: -webkit-calc(100vh - 163px);
  overflow-y: auto;
}

#review-student-flyout {
  & > .paper-card {
    padding-top: rem-calc(16);
    padding-bottom: rem-calc(12);
  }
  & > .immersive-container {
    height: calc(100vh - 100px);
    height: -webkit-calc(100vh - 100px);
    &.flyout-datas {
      @media screen and (max-width: 1000px) {
        height: auto;
      }
    }
    &.review-student-container {
      @include displayFlex;
      @include flexDirection(column);
      overflow-y: hidden;
      overflow-x: hidden;
    }
  }
}

.field-data {
  .textfieldlabel {
    display: inline-block;
  }
  .textfieldvalue {
    display: inline-block;
    padding-bottom: rem-calc(4);
  }
}

.textfieldlabel {
  font-size: rem-calc(14);
  font-weight: 600;
  padding-bottom: rem-calc(4);
  padding-right: rem-calc(4);
}

.cohort-contents {
  padding-top: rem-calc(4);
  padding-bottom: rem-calc(12);
  margin-bottom: rem-calc(16);
}

.cohort-contents {
  &.-height {
    height: 100%;
  }
  .field-data {
    .textfieldlabel {
      color: rgb(74, 74, 74);
      font-size: 14px;
      font-weight: 600;
      height: 20px;
      letter-spacing: 0px;
      line-height: 20px;
    }
    .textfieldvalue {
      color: rgb(74, 74, 74);
      font-size: 14px;
      font-weight: normal;
      height: 20px;
      letter-spacing: 0px;
      line-height: 20px;
      word-break: break-word;
    }
  }
}

.publish-button {
  text-align: center;
}

.cohort-details-container {
  padding-bottom: rem-calc(20);
  height: 100%;
  .user-is-tabbing &:focus {
    box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%), 0 2px 2px 0 rgb(0 0 0 / 10%),
      0 0 4px 3px #61279e;
    outline: none;
  }
  .-card {
    height: 100%;
    .user-is-tabbing &:focus {
      box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%),
        0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
      outline: none;
    }
  }
  .textfields {
    color: rgb(74, 74, 74);
    font-size: 14px;
    font-weight: 300;
    height: 20px;
    letter-spacing: 0px;
    text-transform: uppercase;
    margin-bottom: rem-calc(12);
  }
  .title {
    color: rgb(74, 74, 74);
    font-size: 18px;
    font-weight: bold;
    height: 20px;
    letter-spacing: 0px;
    margin-bottom: rem-calc(12);
  }
  .cohort-contents {
    margin-bottom: 0;
    .field-data {
      .textfieldlabel {
        color: rgb(74, 74, 74);
        font-size: 14px;
        font-weight: 600;
        height: 20px;
        letter-spacing: 0px;
        line-height: 20px;
      }
      .textfieldvalue {
        color: rgb(74, 74, 74);
        font-size: 14px;
        font-weight: normal;
        height: 20px;
        letter-spacing: 0px;
        line-height: 20px;
      }
    }
  }
}
.-cohort-card {
  position: relative;
  height: 100%;
}
.plan-published {
  background: rgba(8, 128, 0, 0.15);
  border-radius: rem-calc(5);
  min-height: rem-calc(16);
  color: rgb(7, 107, 0);
  font-size: rem-calc(14);
  font-weight: 600;
  max-width: rem-calc(256);
  padding: rem-calc(4 8);
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  bottom: rem-calc(16);
}

.plan-archived {
  background: rgba(127, 151, 173, 0.15);
  border-radius: rem-calc(5);
  min-height: rem-calc(24);
  color: rgb(80, 108, 124);
  font-size: rem-calc(14);
  font-weight: 600;
  max-width: rem-calc(220);
  padding: rem-calc(4 8);
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  bottom: rem-calc(16);
}

.plan-scheduled {
  background: rgb(225, 236, 245);
  border-radius: rem-calc(5);
  min-height: rem-calc(24);
  color: rgb(65, 89, 105);
  font-size: rem-calc(14);
  font-weight: 600;
  max-width: rem-calc(288);
  padding: rem-calc(4 8);
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  bottom: rem-calc(16);
}

.plan-scheduled-fac,
.plan-archived-fac,
.plan-published-fac {
  position: relative;
  bottom: 0;
}

.plan-scheduled-fac {
  max-width: rem-calc(210);
}

.current-program {
  padding-right: rem-calc(16);
}

.half-flyout {
  > .container {
    z-index: 16;
    left: 50%;
    max-width: 50%;
    position: relative;
    margin-left: 100%;
    @include box-shadow(
      #{rem-calc(0 11 15 0)} rgba(0, 0, 0, 0.2),
      #{rem-calc(0 9 46 8)} rgba(0, 0, 0, 0.12),
      #{rem-calc(0 24 38 3)} rgba(0, 0, 0, 0.14)
    );
    @include transition(all 0.2s cubic-bezier(0.4, 0, 0.2, 1));
    > .content,
    > .header,
    > .flyout-footer {
      background-color: #fff;
      @include transition(all 0.2s cubic-bezier(0.4, 0, 0.2, 1));
    }
    > .content {
      padding: 0 !important;
    }
    > .header {
      box-shadow: none;
      border-bottom: rem-calc(1) solid #f4f3f6;
      wm-button[icon="close"] {
        margin-right: 0 !important;
      }
      .cohort-header-details {
        max-width: 90%;
      }
      .title {
        font-size: rem-calc(18);
        font-weight: 600;
        margin-top: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .subtitle {
        font-size: rem-calc(14);
        font-weight: 300;
        margin-bottom: rem-calc(4);
        text-transform: uppercase;
      }
    }
    > .flyout-footer {
      padding: rem-calc(20 30);
      border-top: rem-calc(1) solid #f4f3f6;
      @include displayFlex();
      @include justifyContent(space-between);
      @include alignItems(center);
      margin-left: 100%;
      button,
      wm-button {
        &:not(:last-child) {
          margin-right: rem-calc(16);
        }
        &:before {
          margin-right: rem-calc(5);
        }
      }
      > .action-buttons {
        @include flex(0 0 #{rem-calc(250)});
      }
    }
  }
  &.show {
    > .container {
      margin-left: 0;
      > .flyout-footer {
        transition-duration: 0.1s;
        margin-left: 0;
      }
    }
  }
}

.empty-cohort-view {
  margin-top: rem-calc(148);
  text-align: center;
  .no-students-label {
    font-size: rem-calc(24);
    margin-bottom: rem-calc(8);
  }
  .no-students-info {
    font-size: rem-calc(14);
    max-width: rem-calc(588);
    margin: auto;
  }
  .no-programs-label {
    font-size: rem-calc(24);
    margin-bottom: rem-calc(8);
  }
  .no-programs-info {
    font-size: rem-calc(14);
    max-width: rem-calc(500);
    margin: auto;
  }
}

.view-cohort-students {
  &.table-container .data-table {
    margin-top: 0;
  }
  .students-info {
    margin: rem-calc(20 30);
    max-width: rem-calc(550);
    font-size: rem-calc(14);
  }
  .dataheaderrow {
    height: rem-calc(40);
    th {
      background-color: rgba(46, 27, 70, 0.05);
      font-size: rem-calc(12);
      text-transform: uppercase;
    }
    .student-name {
      padding-left: rem-calc(30);
      width: 45%;
    }
  }
  tbody {
    background: rgb(250, 250, 250);
    &:nth-child(even) {
      background-color: #fff;
    }
  }
  .datarow {
    height: rem-calc(61);
    border-bottom: rem-calc(2) solid #f4f3f6;
    font-size: rem-calc(14);
  }
  .student-fullname {
    padding-left: rem-calc(30);
    padding-right: rem-calc(12);
  }
  .data-table thead {
    @media screen and (max-width: 800px) {
      display: block;
    }
  }
}

.cohort-sub-tab {
  margin-top: rem-calc(24);
  .cohort-label {
    margin: rem-calc(24 32 16);
    color: rgb(74, 74, 74);
    font-size: rem-calc(22);
    font-weight: normal;
  }
  .cohort-cards {
    padding-top: rem-calc(24);
    .cohort-header {
      height: rem-calc(40);
    }
    .program-card {
      padding-top: rem-calc(16);
      padding-bottom: rem-calc(24);
    }
  }
  .filter-plan-status {
    margin-right: rem-calc(30);
    .filter-label {
      font-weight: 600;
      font-size: rem-calc(14);
      margin-left: auto;
      padding-right: rem-calc(8);
      padding-left: rem-calc(30);
    }
    .select-status {
      width: rem-calc(248);
      background-color: #fff;
    }
  }
  .no-cohorts-connected {
    @include displayFlex;
    @include flexDirection(column);
    @include alignItems(center);
    margin-top: rem-calc(40);
    .mdi-alert-circle {
      font-size: rem-calc(36);
    }
    .empty-state-header {
      font-size: rem-calc(18);
      font-weight: 600;
    }
    .empty-state-content {
      font-size: rem-calc(14);
      margin: 0;
    }
  }
  .-search-bar {
    @media screen and (min-width: 1024px) {
      width: rem-calc(580);
      max-width: rem-calc(580);
    }
    @media screen and (max-width: 768) {
      width: rem-calc(350);
      max-width: rem-calc(350);
    }
    margin-left: rem-calc(30);
  }
  .-filter-by {
    margin-right: rem-calc(10);
  }
}

.cohort-type-header {
  font-size: rem-calc(22);
  padding-bottom: rem-calc(8);
}

.active-cohorts {
  & + .archived-cohorts {
    padding-top: rem-calc(16);
  }
  .prog-titles {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: rem-calc(270);
  }
}

.go-to-licensure-plan {
  .bread-crumbs {
    margin-bottom: 0;
    .link {
      font-weight: bold;
      display: inline-flex;
      &:after {
        vertical-align: top;
      }
    }
  }
}

.student-submission-card {
  table {
    display: table;
    width: 100%;
  }
  table thead,
  table tbody {
    float: left;
    width: 100%;
  }
  table tbody {
    overflow: auto;
    max-height: calc(100vh - 330px);
    max-height: -webkit-calc(100vh - 330px);
    &.table-pagination {
      max-height: calc(100vh - 410px);
      max-height: -webkit-calc(100vh - 410px);
    }
    @media screen and (max-width: 1024px) {
      max-height: calc(100vh - 100px);
      max-height: -webkit-calc(100vh - 100px);
    }
  }
  table tr {
    width: 100%;
    display: table;
    text-align: left;
  }
  table th,
  table td {
    width: 33%;
  }
  .filter-by {
    .filter-label {
      font-weight: 600;
      margin-right: rem-calc(12);
    }
    .select-status {
      width: rem-calc(300);
    }
    &.filter-by-status {
      @include justifyContent(space-between);
      @media screen and (max-width: 800px) {
        @include flexDirection(column);
        @include alignItems(flex-start);
      }
      .search-students {
        width: rem-calc(450);
        margin-right: rem-calc(20);
        @media screen and (max-width: 800px) {
          margin-bottom: rem-calc(20);
          width: 100%;
        }
      }
      .filter-status {
        display: inline-flex;
        align-items: center;
      }
    }
  }
  .data-table thead {
    @media screen and (max-width: 800px) {
      display: block;
    }
  }
}

.note-action-message {
  font-style: italic;
  margin-bottom: rem-calc(4);
}

.static-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.content-height {
  height: -webkit-calc(100vh - 182px);
  height: calc(100vh - 182px);
}

.navigate-students {
  background: rgb(255, 255, 255);
  height: rem-calc(80);
  padding: rem-calc(20 32);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
}

.student-count-info {
  font-style: italic;
  font-size: rem-calc(14);
}

.footer-buttons {
  min-width: rem-calc(164);
}

.pagination-footer {
  background: rgb(255, 255, 255);
  height: rem-calc(80);
  padding-top: rem-calc(20);
  padding-bottom: rem-calc(20);
}

.no-filtered-students {
  text-align: center;
  font-size: rem-calc(24);
  padding-top: rem-calc(16);
  height: rem-calc(68);
}

.pre-wrap {
  white-space: pre-wrap;
}

.filter-header {
  background: rgba(74, 74, 74, 0.04);
  .filter-body {
    padding: rem-calc(12 0);
    margin-left: rem-calc(100);
    .filter-label {
      font-weight: 600;
      margin-right: rem-calc(12);
      font-size: rem-calc(14);
    }
    .filter-by-cohorts {
      width: rem-calc(300);
      background: rgb(255, 255, 255);
    }
  }
}

.cohort-loader {
  height: calc(100vh - 270px);
  height: -webkit-calc(100vh - 270px);
  width: -webkit-fill-available;
  .content-loader {
    background: url("../../images/content-loader.gif") no-repeat 50% 0;
    &.content {
      position: relative;
      top: 50%;
      text-align: center;
      padding-top: rem-calc(100);
      color: #8360b2;
    }
  }
}

.studentview-flyout-title {
  margin: 0;
}

.cohort-section {
  @include flexDirection(column);
  @include displayFlex;
  height: 100vh;
  width: auto;
  &.-scroll {
    scroll-behavior: smooth;
  }
  .page-header {
    .flyout-title {
      margin: 0;
      color: rgb(250, 250, 250);
      font-size: rem-calc(24);
      font-weight: 600;
      line-height: rem-calc(32);
      .title-desc {
        color: rgb(255, 255, 255);
        font-size: rem-calc(14);
        font-weight: 300;
        line-height: rem-calc(20);
      }
    }
  }
  .main-header {
    display: block;
    position: relative;
    &.main-header-admin {
      z-index: 20;
    }
    .text-content {
      padding-left: rem-calc(88);
      padding-right: rem-calc(56);
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0;
      &.tab-header-content {
        height: rem-calc(120);
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1024px) {
          padding-left: rem-calc(20);
        }
      }
      .pending-msg {
        margin: 0;
        font-style: italic;
        color: rgb(107, 107, 107);
        margin-top: rem-calc(-12);
        margin-right: rem-calc(-34);
      }
    }
    .flyout-title {
      margin: 0;
      color: rgb(74, 74, 74);
      font-size: rem-calc(24);
      font-weight: 600;
      line-height: rem-calc(32);
    }
    .header-description {
      margin: 0;
      padding-top: rem-calc(4);
      max-width: rem-calc(588);
    }
  }
  .panels {
    @include flex(1);
    min-height: 0;
    &.lp-tab-panels {
      height: calc(100vh - 270px);
      .mini-panel,
      .cohort-pagecontent {
        padding-bottom: rem-calc(24);
      }
      .flyout-title {
        margin-top: 0;
      }
    }
    .mini-panel {
      @include displayFlex;
      @include flexDirection(column);
      @include flex(none);
      text-align: left;
      margin-left: rem-calc(30);
      background: #ffffff;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      @include box-shadow(0 0 14px 0 rgba(0, 0, 0, 0.14));
      margin-left: 0;
      width: rem-calc(320);
      min-width: rem-calc(320);
      z-index: 13;
      .panel-heading {
        padding: rem-calc(20 24 4 24);
        color: rgb(74, 74, 74);
        font-size: rem-calc(16);
        font-weight: 500;
        line-height: rem-calc(24);
      }
      .menu {
        padding: 0;
        margin: 0;
        cursor: pointer;
        .item {
          display: inline-flex;
          padding: rem-calc(16 24);
          border-radius: 0;
          text-align: left;
          width: 100%;
          &.-expanded {
            padding-bottom: rem-calc(8);
          }
          &.-req-expanded {
            padding-bottom: rem-calc(16);
          }
          &:hover {
            background: rgb(244, 243, 246);
          }
          &.active {
            border-left: 6px solid rgb(87, 81, 149);
            background: rgb(238, 237, 244);
            padding-left: rem-calc(18);
          }
          .section-button {
            text-align: left;
            margin-left: 0;
            display: inline-flex;
            padding-right: rem-calc(4);
            &:before {
              color: #575195;
            }
            &:hover {
              text-decoration: none;
            }
            &.-align {
              padding-top: rem-calc(3);
            }
          }
          .section-button-text {
            &:hover {
              text-decoration: underline;
              color: rgb(87, 81, 149);
            }
            .line-clamp-requirement {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              &.-sub-title {
                color: rgb(87, 81, 149);
                font-size: rem-calc(14);
                font-weight: bold;
              }
            }
          }
          .line-clamp {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: rem-calc(14);
            color: #4a4a4a;
            font-weight: 600;
            &:hover {
              text-decoration: none;
              color: #454077;
            }
            &.-line-label {
              color: #575195;
            }
            &.timeline-title {
              color: rgb(74, 74, 74);
              font-size: rem-calc(16);
              font-weight: 500;
            }
          }
        }
        .menu-item {
          border-top: 1px solid rgb(231, 231, 231);
          display: block;
          padding-bottom: rem-calc(4);
          &:last-child {
            border-bottom: 1px solid rgb(231, 231, 231);
          }
          &:first-child {
            margin: 0;
            border: none;
            padding-bottom: 0;
            padding-top: rem-calc(12);
          }
        }
        .sub-menu {
          display: block;
          padding: 0;
          cursor: pointer;
          .item {
            display: inline-flex;
            padding: rem-calc(8 56);
            padding-right: rem-calc(24);
            &.-width {
              padding: rem-calc(12 56);
            }
            &.active {
              padding-left: rem-calc(50);
            }
          }
          .sub-requirement {
            .item {
              padding: rem-calc(8 88);
              padding-right: rem-calc(24);
              &.active {
                padding-left: rem-calc(82);
              }
            }
          }
        }
        .publish-button {
          text-align: center;
          margin: rem-calc(24 90 0 76);
        }
      }
    }
    .cohort-pagecontent {
      @include displayFlex;
      @include flexDirection(column);
      padding: 0;
      overflow-y: auto;
      width: 100%;
      .main-section {
        padding-bottom: rem-calc(24);
        &.main-section-tab {
          overflow-y: auto;
          height: calc(100vh - 270px);
        }
        &.main-section-no-tab {
          overflow-y: auto;
        }
        .details-paper-card,
        .section-card {
          width: 89%;
          margin: auto;
          margin-top: rem-calc(24);
          padding-bottom: 0;
          margin-bottom: rem-calc(24);
          .user-is-tabbing &:focus {
            box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%),
              0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
            outline: none;
          }
        }
        .details {
          padding-top: 0;
          padding-bottom: 0;
          .row {
            @include displayFlex;
            &:not(:last-child) {
              border-bottom: 1px solid rgb(231, 231, 231);
            }
            .col {
              @include flex(0 0 33.33%);
              margin: 0;
              padding: rem-calc(24 0);
              .end-term {
                padding-top: rem-calc(8);
              }
              &.row-title {
                color: rgb(74, 74, 74);
                font-size: rem-calc(14);
                font-weight: 600;
                &.faculty-title {
                  display: flex;
                  gap: rem-calc(8);
                  align-items: center;
                  padding-top: rem-calc(12);
                  padding-bottom: rem-calc(20);
                }
                .toggletip {
                  font-weight: normal;
                }
              }
              .view-students-button {
                padding-left: rem-calc(16);
              }
              &.licensurePlan-btn {
                text-align: right;
              }
              &.faculty-text {
                color: rgb(107, 107, 107);
                font-style: italic;
              }
            }
            &.connect-plan-opt {
              .licensure-plan-col {
                padding-top: rem-calc(34);
              }
              .licensure-plan-text,
              .faculty-text {
                color: rgb(107, 107, 107);
                font-style: italic;
              }
            }
            &.edit-connection-opt {
              .licensure-plan-col {
                padding-top: rem-calc(34);
              }
              .licensure-plan-text {
                color: rgb(74, 74, 74);
                font-style: italic;
                span {
                  font-style: normal;
                }
              }
              .licensurePlan-btn {
                padding: rem-calc(24 0 0 0);
              }
            }
            &.view-plan-opt {
              .col {
                padding: rem-calc(24 0 24 0);
              }
            }
          }
        }
      }
      .requirement-pagecontent {
        margin-top: rem-calc(24);
        padding-left: rem-calc(60);
        padding-right: rem-calc(60);
        @media screen and (max-width: 800px) {
          padding-left: 0;
          padding-right: 0;
        }
      }
      .deleted-banner {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        width: 75%;
        border: 2px solid rgb(192, 57, 43);
        border-radius: rem-calc(2);
        .user-is-tabbing &:focus {
          box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
            inset 0 2px 2px 0 rgb(0 0 0 / 10%), inset 0 0 4px 3px #61279e;
          outline: none;
        }
        .warning-icon {
          z-index: -1;
          padding: rem-calc(18 12);
          font-size: rem-calc(24);
          background-color: rgba(192, 57, 43, 1);
          color: rgb(255, 255, 255);
        }
        .deleted-message {
          width: 75%;
          padding: rem-calc(14);
          font-size: rem-calc(14);
          line-height: rem-calc(22);
          &.archived-state {
            width: 90%;
          }
        }
        .deleted-banner-btn {
          width: 21%;
          display: flex;
          padding: rem-calc(20);
        }
        @media screen and (max-width: 1400px) {
          width: 80%;
          .deleted-banner-btn {
            width: 22%;
          }
        }
        @media screen and (max-width: 1200px) {
          width: 100%;
        }
      }
    }
    .details-view {
      padding-top: 20px;
      width: 100%;
    }
    .no-req-state {
      padding: rem-calc(115 50);
      .titles {
        color: rgb(74, 74, 74);
        font-size: rem-calc(20);
        font-weight: normal;
        text-align: center;
        padding-bottom: rem-calc(4);
      }
      .helper-text {
        color: rgb(74, 74, 74);
        font-size: rem-calc(14);
        font-weight: normal;
        text-align: center;
      }
    }
  }
  .tab-btn-bar {
    background: rgb(53, 59, 72);
    padding-right: rem-calc(56);
    #extract-submission-btn {
      width: rem-calc(220);
    }
  }
  .main-container {
    padding: rem-calc(18 100 50 100);
    height: calc(100vh - 270.8px);
    overflow-y: auto;
    overflow-x: hidden;
    @media screen and (max-width: 1024px) {
      padding: rem-calc(18 10 50 10);
      width: 100%;
    }
    .lp-title {
      font-size: rem-calc(22);
      font-weight: 400;
      line-height: rem-calc(26);
      color: rgba(0, 0, 0, 1);
      @media screen and (max-width: 1024px) {
        padding-left: rem-calc(10);
      }
    }
    .filters-container {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
        padding-left: rem-calc(10);
      }
      .search-requirements {
        width: rem-calc(610);
        margin-top: rem-calc(20);
      }
      .filter-wrapper {
        display: flex;
        gap: rem-calc(60);
        .sort-by-container {
          margin-left: rem-calc(102);
          width: rem-calc(280);
          @media screen and (max-width: 1024px) {
            margin-left: auto;
          }
          .sort-by-label {
            font-size: rem-calc(14);
            font-weight: 600;
            line-height: rem-calc(22);
          }
        }
        .filter-by-container {
          width: rem-calc(280);
          .filter-by-label {
            font-size: rem-calc(14);
            font-weight: 600;
            line-height: rem-calc(22);
          }
        }
      }
    }
    .accordion-container {
      margin-top: rem-calc(24);
      margin-left: rem-calc(9);
      display: flex;
      flex-direction: column;
      gap: rem-calc(24);
      @media screen and (max-width: 1024px) {
        margin-right: rem-calc(9);
      }
      .accordion-header-content {
        display: flex;
        flex-direction: column;
        gap: rem-calc(12);
        .accordion-header {
          display: flex;
          justify-content: space-between;
          .accordion-left-section {
            display: flex;
            gap: rem-calc(30);
            align-items: center;
          }
          .accordion-label {
            font-size: rem-calc(18);
            font-weight: 600;
            line-height: rem-calc(22);
            color: rgba(0, 0, 0, 1);
          }
          .review-text {
            font-size: rem-calc(14);
            line-height: rem-calc(22);
            font-style: italic;
            font-weight: 400;
            color: rgba(0, 0, 0, 1);
          }
        }
        .accordion-content {
          list-style: none;
          margin: 0;
          padding-left: rem-calc(20);
        }
        .sub-section-container {
          display: flex;
          flex-direction: column;
          gap: rem-calc(15);
          .requirement-container {
            display: flex;
            flex-direction: column;
            gap: rem-calc(20);
          }
          .sub-section {
            padding-left: rem-calc(60);
          }
        }
        .requirement-accordion {
          .requirement-accordion-header {
            width: rem-calc(961);
            min-height: rem-calc(61);
            box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.12);
            padding: rem-calc(10 20);
            display: flex;
            flex-direction: column;
            border-radius: 5px;
            @media screen and (max-width: 1024px) {
              width: 100%;
            }
            .close-view {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: rem-calc(0 -20);
              padding: rem-calc(0 20);
              .requirement-accordion-label {
                font-size: rem-calc(18);
                font-weight: 600;
                line-height: rem-calc(22);
                color: rgba(74, 74, 74, 1);
              }
              .status-container {
                display: flex;
                gap: rem-calc(10);
                align-items: center;
                .review-text {
                  font-size: rem-calc(22);
                  line-height: rem-calc(27);
                  font-weight: 400;
                  color: rgba(0, 0, 0, 1);
                }
                .text-color {
                  color: rgba(46, 27, 70, 1);
                  border-radius: rem-calc(25);
                  padding: rem-calc(5 12);
                  font-size: rem-calc(12);
                }
              }
              &.open {
                border-bottom: 1px solid grey;
                padding-bottom: rem-calc(10);
              }
            }
            .chart-container {
              display: flex;
              gap: rem-calc(60);
              margin-top: rem-calc(15);
              margin-bottom: rem-calc(15);
              padding-left: rem-calc(34);
              padding-right: rem-calc(34);
              @media screen and (max-width: 1024px) {
                gap: rem-calc(10);
                flex-direction: column;
              }
              .chart-left-container {
                display: flex;
                flex-direction: column;
                width: 61%;
                .desc-container {
                  margin-top: rem-calc(14);
                  display: flex;
                  gap: rem-calc(12);
                  .desc-heading {
                    font-size: rem-calc(14);
                    font-weight: 600;
                    line-height: rem-calc(22);
                    color: rgba(0, 0, 0, 1);
                  }
                  .desc {
                    font-size: rem-calc(14);
                    font-weight: 400;
                    line-height: rem-calc(22);
                    color: rgba(0, 0, 0, 1);
                  }
                }
              }
              .status-details {
                display: flex;
                gap: rem-calc(20);
                flex-direction: column;
                .status-count {
                  display: flex;
                  gap: rem-calc(20);
                  align-items: center;
                  .count,
                  .not-submitted-count,
                  .awaiting-review-count,
                  .cond-met-count,
                  .met-count,
                  .not-met-count {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    width: rem-calc(20);
                    font-size: rem-calc(22);
                    font-weight: 400;
                    color: rgba(0, 0, 0, 1);
                  }
                  .tag-border {
                    border-radius: rem-calc(25);
                    font-size: rem-calc(12);
                    padding: rem-calc(5 12);
                  }
                  .not-submitted-tag {
                    background-color: rgba(233, 231, 236, 1);
                    color: rgba(74, 74, 74, 1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.extracts-overview {
  .main-header {
    display: block;
    position: relative;
    z-index: 20;
    .text-content {
      padding-left: rem-calc(88);
      padding-right: rem-calc(88);
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0;
    }
    .flyout-title {
      margin: 0;
      color: rgb(74, 74, 74);
      font-size: rem-calc(24);
      font-weight: 600;
      line-height: rem-calc(32);
    }
    .header-description {
      margin: 0;
      padding-top: rem-calc(4);
      max-width: rem-calc(588);
    }
  }
  .extracts-empty-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    .main-text {
      margin: 0;
      padding: rem-calc(80 0 4 0);
      font-size: rem-calc(24);
    }
    .sub-text {
      margin: 0;
      padding-bottom: rem-calc(16);
      font-size: rem-calc(14);
      line-height: rem-calc(20);
    }
  }
  .extracts-main-content {
    padding: rem-calc(24 90);
    height: calc(100vh - 270px);
    height: -webkit-calc(100vh - 270px);
    .extracted-submissions-header {
      margin: 0;
      font-size: rem-calc(22);
      font-weight: normal;
      line-height: rem-calc(30);
    }
    .extracted-submissions-sub-header {
      margin: 0;
      padding: rem-calc(4 0 24);
      font-size: rem-calc(14);
      line-height: rem-calc(20);
    }
    .toggletip {
      font-size: rem-calc(14);
      font-weight: normal;
      line-height: rem-calc(20);
      text-transform: none;
      z-index: 30;
      position: relative;
      margin-left: rem-calc(8);
      top: rem-calc(2);
    }
    .extracted-submissions-table {
      background: rgb(255, 255, 255);
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
      border-radius: rem-calc(5);
      .dataheader .dataheaderrow {
        display: table-row;
      }
      .dataheaderrow th {
        padding-left: rem-calc(24);
        font-size: rem-calc(12);
        text-transform: uppercase;
        text-align: left;
        background-color: rgb(238, 237, 244);
        &.creation-date-header {
          border-radius: rem-calc(5 0 0 0);
          width: 25%;
        }
        &.students-header {
          width: 23%;
        }
        &.requirements-header {
          width: 25%;
        }
        &.status-header {
          width: 18%;
        }
        &.download-header {
          border-radius: rem-calc(0 5 0 0);
          width: 9%;
        }
        .user-is-tabbing &:focus {
          box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
            inset 0 2px 2px 0 rgb(0 0 0 / 10%), inset 0 0 4px 3px #61279e;
          outline: none;
        }
      }
      .databody .datarow {
        border-bottom: 1px solid rgb(236, 238, 243);
        .td-content {
          word-break: break-all;
          min-width: 0%;
          padding: rem-calc(20 24);
          font-size: rem-calc(14);
          letter-spacing: 0;
          line-height: rem-calc(20);
        }
      }
    }
    .data-table .dataheader .dataheaderrow {
      background: rgb(238, 237, 244);
      border-radius: rem-calc(5 5 0 0);
      height: rem-calc(40);
      width: 100%;
    }
    tbody tr {
      background: rgb(250, 250, 250);
      &:nth-child(even) {
        background-color: rgb(255, 255, 255);
      }
    }
    tbody:last-child tr {
      border-radius: rem-calc(0 0 5 5);
    }
    @media screen and (max-width: 800px) {
      .data-table .dataheader {
        display: table-header-group;
      }
      .template-list-table {
        .dataheader,
        .datarow {
          display: revert;
        }
        .template-status {
          margin-top: rem-calc(28);
        }
      }
    }
    .pagination-footer {
      background: rgb(250, 250, 250);
      height: rem-calc(80);
      margin-top: rem-calc(20);
      padding-bottom: rem-calc(20);
    }
  }
  .pending {
    font-weight: normal;
    font-style: italic;
  }
  .failed {
    font-weight: 600;
    color: rgb(191, 60, 60);
    background: rgb(255, 240, 238);
    border-radius: rem-calc(5);
    padding: rem-calc(4 10);
    text-transform: uppercase;
  }
  .completed {
    font-weight: 600;
    color: rgb(7, 107, 0);
    background: rgba(8, 128, 0, 0.15);
    border-radius: rem-calc(5);
    padding: rem-calc(4 10);
    text-transform: uppercase;
  }
}

#extract-details-flyout {
  &.half-flyout {
    > .container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: unset;
      left: unset;
      background: rgb(255, 255, 255);
      box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.09);
      border-radius: 0;
      @media screen and (min-width: 800px) {
        left: unset;
        width: rem-calc(720);
        max-width: unset;
      }
      .extract-details-content {
        height: 100%;
        .header-content {
          padding: rem-calc(38 28 24);
          border-bottom: 1px solid rgb(231, 231, 231);
          .heading {
            margin: 0;
            font-size: rem-calc(18);
            font-weight: 600;
            line-height: rem-calc(26);
          }
        }
        .body-content {
          padding: rem-calc(14 44);
          overflow: auto;
          height: calc(100vh - 104px);
          height: -webkit-calc(100vh - 104px);
          .row {
            display: flex;
            align-items: flex-start;
            padding-top: rem-calc(14);
            .col {
              width: 30%;
              .col-title {
                margin: 0;
                font-size: rem-calc(16);
                font-weight: 500;
                line-height: rem-calc(24);
              }
            }
            .col-value {
              font-size: rem-calc(14);
              width: 70%;
            }
          }
          .pending {
            margin-top: rem-calc(24);
            font-size: rem-calc(14);
          }
          .extract-download-button {
            margin-top: rem-calc(24);
          }
        }
      }
    }
  }
}

.empty-list {
  text-align: center;
  .no-results {
    margin-top: rem-calc(80);
    font-size: rem-calc(18);
  }
  .try-again {
    font-size: rem-calc(14);
    margin-top: rem-calc(8);
    padding-bottom: rem-calc(128);
  }
  .empty-table {
    padding-left: 1.875rem;
    background-color: rgba(46, 27, 70, 0.05);
    width: 100%;
    height: rem-calc(40);
  }
}

.empty-tbl {
  .data-table {
    box-shadow: none !important;
    background: rgb(238, 237, 244);
    border-radius: rem-calc(5 5 0 0);
    min-width: rem-calc(1241);
    min-height: rem-calc(40);
  }
  @media screen and (max-width: 830px) {
    .data-table thead {
      background: rgb(238, 237, 244);
      border-radius: rem-calc(5 5 0 0);
      max-width: rem-calc(700);
      min-height: rem-calc(40);
    }
  }
}
#empty-students {
  &.empty-list {
    .try-again {
      padding-bottom: rem-calc(20);
    }
  }
}

#cohort-students {
  margin-top: rem-calc(20);
  th {
    text-align: left;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
        inset 0 2px 2px 0 rgb(0 0 0 / 10%), inset 0 0 4px 3px #61279e;
      outline: none;
    }
  }
}

.student-search {
  width: rem-calc(550);
}

.go-back-link {
  color: #575195;
  font-weight: bold;
}

.approver-name {
  text-transform: capitalize;
}

.student-progress {
  max-width: rem-calc(616);
  margin-left: rem-calc(100);
  margin-top: rem-calc(40);
  &.-left {
    margin-left: rem-calc(60);
  }
  &.student-view {
    margin-top: rem-calc(20);
  }
  .requirement-name {
    margin-right: rem-calc(8);
  }
  .-icononly {
    color: #575195;
    cursor: pointer;
    border: 0;
    margin-right: rem-calc(5);
    background-color: transparent;
    &:hover {
      color: #575195;
      background: none;
    }
  }
  .req-container {
    &:last-child {
      padding-bottom: rem-calc(48);
    }
    &:not(:last-child) {
      &::after {
        display: block;
        content: "";
        border: 1px solid rgb(222, 222, 222);
        margin: rem-calc(28 0);
        width: rem-calc(726);
        max-width: rem-calc(726);
      }
    }
    .req-title {
      color: rgb(87, 81, 149);
      font-size: rem-calc(16);
      font-weight: 500;
      color: rgb(87, 81, 149);
    }
    .header {
      .title {
        color: rgb(74, 74, 74);
        font-size: rem-calc(22);
        padding-bottom: rem-calc(16);
        margin: 0;
        font-weight: 500;
      }
      .sub-title {
        color: rgb(74, 74, 74);
        font-size: rem-calc(18);
        font-weight: 600;
        text-transform: capitalize;
        padding-bottom: rem-calc(12);
        margin: 0;
      }
    }
    .sub-section-detail {
      .sub-title {
        color: rgb(74, 74, 74);
        font-size: rem-calc(18);
        font-weight: 600;
        text-transform: capitalize;
        padding-bottom: rem-calc(12);
        padding-top: rem-calc(16);
        margin: 0;
      }
    }
    .body {
      .requirement-details {
        background: rgb(255, 255, 255);
        border-radius: rem-calc(5);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
        margin-bottom: rem-calc(12);
        &.req-dets {
          @media screen and (max-width: 1024px) {
            max-width: rem-calc(430);
            width: rem-calc(430);
          }
          @media screen and (min-width: 1025px) {
            max-width: rem-calc(724);
            width: 724px;
          }
        }
        .user-is-tabbing &:focus {
          box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%),
            0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
          outline: none;
        }
        &:hover {
          background: rgb(255, 255, 255);
          border-radius: 5px;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
          cursor: pointer;
        }
        &.future-application {
          .req-title {
            color: rgb(87, 81, 149);
            font-size: rem-calc(14);
            font-weight: 500;
            line-height: rem-calc(24);
          }
          &:hover {
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
            cursor: default;
          }
        }
        .not-submitted-icon {
          background: rgb(242, 240, 243);
          border-radius: 0px;
          width: 86px;
          min-width: 86px;
          font-size: 40px;
        }
        .-icon-color-not-submitted {
          color: rgb(107, 107, 107);
          display: flex;
          justify-content: center;
          margin-top: 10px;
        }
        .awaiting-review-icon {
          background: rgb(225, 236, 245);
          border-radius: 0px;
          width: 86px;
          min-width: 86px;
          font-size: 40px;
        }
        .-icon-color-awaiting-review {
          color: rgb(127, 151, 173);
          display: flex;
          justify-content: center;
          margin-top: 10px;
        }
        .met-icon {
          background: rgb(224, 245, 247);
          border-radius: 0px;
          width: 86px;
          min-width: 86px;
          font-size: 40px;
        }
        .-icon-color-met-icon {
          color: rgb(25, 161, 169);
          display: flex;
          justify-content: center;
          margin-top: 10px;
        }
        .not-met-icon {
          background: rgb(254, 237, 234);
          color: rgb(255, 95, 78);
          border-radius: 0px;
          width: 86px;
          min-width: 86px;
          font-size: 40px;
        }
        .-icon-color-not-met {
          color: rgb(255, 95, 78);
          display: flex;
          justify-content: center;
          margin-top: 10px;
        }
        .in-progress-icon {
          background: rgb(221, 219, 240);
          color: rgb(87, 81, 149);
          border-radius: 0px;
          width: 86px;
          min-width: 86px;
          font-size: 40px;
        }
        .-icon-color-in-progress {
          color: rgb(87, 81, 149);
          display: flex;
          justify-content: center;
          margin-top: 10px;
        }
        .-icon {
          display: flex;
          justify-content: center;
          padding-top: rem-calc(18);
          &:hover {
            background: none;
          }
        }
        .req-content {
          margin: rem-calc(10 20 20);
          width: 100%;
          .status {
            padding-bottom: rem-calc(12);
            padding-top: rem-calc(12);
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .label-due {
              font-size: rem-calc(14);
            }
          }
          .draft-label {
            color: rgb(107, 107, 107);
            font-size: rem-calc(14);
            font-style: italic;
            font-weight: normal;
            padding-left: rem-calc(12);
            text-transform: capitalize;
          }
          .due-date-data {
            display: inline-block;
            margin-left: rem-calc(215);
            width: 204px;
            height: 20px;
            color: rgb(107, 107, 107);
            font-size: 14px;
            font-family: Inter-Italic;
            font-weight: normal;
            font-style: italic;
            letter-spacing: 0px;
            line-height: 20px;
            .due-date-label {
              display: inline-block;
              margin-left: rem-calc(37);
            }
            .due-date-value {
              display: inline-block;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
}

.status-tag {
  display: inline-block;
  text-align: center;
  padding: rem-calc(4 8);
  text-transform: uppercase;
  font-size: rem-calc(14);
  border-radius: rem-calc(5);
  background: rgb(107, 107, 107);
  color: rgb(255, 255, 255);
  font-weight: 600;
  word-break: break-word;
  &.awaiting-review-tag {
    background: rgb(219, 217, 239);
    color: rgb(83, 80, 121);
  }
  &.met-tag {
    background: rgb(229, 247, 248);
    color: rgb(13, 105, 110);
  }
  &.not-met-tag {
    background: rgb(254, 237, 234);
    color: rgb(175, 41, 46);
  }
  &.conditional-tag {
    background: rgb(46, 27, 70);
    color: rgb(233, 231, 236);
  }
  &.draft-tag {
    background: rgb(242, 240, 243);
    color: rgb(58, 58, 58);
  }
  &.pending-tag {
    background: rgb(242, 240, 243);
    color: rgb(58, 58, 58);
  }
  &.in-progress-tag {
    background: rgb(46, 27, 71);
    color: (233, 231, 236);
  }
  &.merged-tag {
    background: rgba(8, 128, 0, 0.15);
    color: rgba(7, 107, 0, 1);
  }
  &.merged-pending-tag {
    background: rgba(238, 237, 244, 1);
    color: rgba(87, 81, 149, 1);
  }
}

.status-block {
  margin-bottom: rem-calc(24);
}

.-btn-align {
  display: flex;
  justify-content: flex-end;
}

.status-button-collection {
  text-align: right;
}

.reject-btn {
  background: rgb(202, 70, 56) !important;
  border-color: rgb(202, 70, 56) !important;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 10%),
    0px 2px 2px 0px rgb(244 243 246 / 20%);
  color: #fff !important;
  &:before {
    margin-right: rem-calc(4);
  }
  &:hover {
    background: rgb(202, 70, 56);
    border-color: rgb(202, 70, 56);
  }
}

.approve-btn {
  background: rgb(13, 105, 110) !important;
  border-color: rgb(13, 105, 110) !important;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(87, 81, 149, 0.12);
  color: #fff !important;
  &:before {
    margin-right: rem-calc(4);
  }
  &:hover {
    background: rgb(13, 105, 110);
    border-color: rgb(13, 105, 110);
  }
}

.requirement-file-content {
  margin-left: rem-calc(100);
  margin-right: rem-calc(100);
  margin-top: rem-calc(24);
  @include displayFlex();
  @include flexDirection(column);
  &.review-student-file {
    flex-grow: 1;
    min-height: calc(100vh - 180px);
    min-height: -webkit-calc(100vh - 180px);
  }
  &.req-submission-content {
    margin-left: rem-calc(64);
    margin-right: rem-calc(64);
    .req-details-card {
      padding: rem-calc(24);
    }
  }
  .student-cards {
    padding-top: rem-calc(16);
    .review-file-subbmission {
      @include displayFlex();
      @include flexDirection(row);
      @media screen and (max-width: 1024px) {
        @include flexDirection(column);
      }
      #requirement-submission-details {
        margin-right: rem-calc(20);
        height: max-content;
        @media screen and (max-width: 1024px) {
          margin-right: 0;
          max-width: 100%;
        }
        .note-status-btn-container {
          margin-bottom: rem-calc(24);
          border-bottom: 1px solid rgb(151, 151, 151);
        }
        .not-submitted-condition-status,
        .description-container {
          margin-bottom: rem-calc(24);
          padding-bottom: rem-calc(24);
          border-bottom: 1px solid rgb(151, 151, 151);
        }
        .status-block {
          padding-top: rem-calc(24);
        }
        .button-collection {
          margin-bottom: rem-calc(24);
        }
        .show-due-date {
          display: block;
          margin-bottom: rem-calc(24);
          padding-bottom: rem-calc(24);
          border-bottom: 1px solid rgb(151, 151, 151);
          .due-date-text {
            margin: 0;
            display: inline-block;
            padding-left: rem-calc(4);
            &.no-requirements {
              padding-left: 0;
            }
          }
          &.-student-view {
            margin-top: rem-calc(24);
            padding-top: rem-calc(24);
            border-top: 1px solid rgb(151, 151, 151);
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
          }
        }
        .toggle-label,
        .ref-materials-label {
          font-size: rem-calc(16);
          line-height: rem-calc(24);
          margin-bottom: rem-calc(4);
          outline: none;
          border: none;
        }
        .toggle-label:hover,
        button:hover {
          background: rgb(255, 255, 255);
          color: rgb(70, 65, 119);
        }
        .toggleBtn {
          margin: 0;
          width: rem-calc(22);
          min-width: rem-calc(22);
          height: rem-calc(22);
          min-height: rem-calc(23);
          margin-right: rem-calc(6);
        }
        .textfield {
          &.is-focused .charcount.-textarea {
            padding-bottom: rem-calc(0);
          }
          textarea.textfieldinput {
            resize: none;
            min-height: rem-calc(106);
            max-height: rem-calc(106);
            overflow-y: auto;
            box-sizing: border-box;
            &:focus {
              min-height: rem-calc(68);
              max-height: rem-calc(68);
              margin-bottom: rem-calc(38);
            }
          }
        }
        .no-requirements,
        .pre-wrap,
        .description-file-submission,
        .ref-links-list {
          padding-left: rem-calc(28);
        }
        .template-document-container {
          padding-top: 0;
          .ref-materials-label {
            font-weight: 500;
            margin-top: rem-calc(24);
            padding-top: rem-calc(24);
            border-top: 1px solid rgb(151, 151, 151);
          }
          .ref-links-list,
          .reference-links {
            margin: rem-calc(10 0);
            list-style-type: none;
            .ref-links-list-item {
              margin-bottom: rem-calc(10);
              .resource-link {
                display: flex;
                align-items: flex-end;
                word-break: break-all;
                width: fit-content;
              }
              .resource-link-display {
                overflow: hidden;
                display: -webkit-inline-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                padding-right: rem-calc(4);
              }
            }
            a:hover:not(.button):not([role]) {
              padding-bottom: 0 !important;
            }
          }
          .reference-links {
            padding-left: 0;
          }
        }
      }
      #student-req-submission-card {
        min-width: rem-calc(680);
        max-width: rem-calc(680);
        margin-right: rem-calc(20);
        height: max-content;
        @media screen and (max-width: 1024px) {
          max-width: rem-calc(500);
        }
        .submission-details {
          margin-top: rem-calc(24);
          padding-top: rem-calc(24);
          border-top: 1px solid rgb(151, 151, 151);
        }
        .status-block {
          padding-top: rem-calc(24);
        }
        .textfield {
          &.is-focused .charcount.-textarea {
            padding-bottom: rem-calc(0);
          }
          textarea.textfieldinput {
            resize: none;
            min-height: rem-calc(106);
            max-height: rem-calc(106);
            overflow-y: auto;
            box-sizing: border-box;
            &:focus {
              min-height: rem-calc(68);
              max-height: rem-calc(68);
              margin-bottom: rem-calc(38);
            }
          }
        }
      }
      #student-file-details {
        min-width: rem-calc(730);
        max-width: rem-calc(730);
        height: max-content;
        @media screen and (max-width: 1024px) {
          margin-right: rem-calc(20);
          max-width: rem-calc(610);
          min-width: rem-calc(448);
        }
      }
    }
    .activity-log-card {
      max-width: rem-calc(448);
      @media screen and (max-width: 1024px) {
        margin-right: rem-calc(20);
        max-width: rem-calc(610);
      }
    }
    #activity-log-card {
      margin-right: rem-calc(20);
    }
  }
  .textfieldlabel {
    font-size: rem-calc(14);
    font-weight: 600;
    padding-bottom: rem-calc(4);
    padding-right: rem-calc(4);
  }
  .requirement-title-content {
    font-size: rem-calc(30);
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.archive-content {
  background: rgb(238, 237, 244);
  border-radius: 0px;
  height: 40px;
  margin-left: rem-calc(-100);
  margin-right: rem-calc(-100);
  margin-top: rem-calc(-24);
  margin-bottom: rem-calc(24);
  z-index: -15;
  .archive-warning-text {
    color: rgb(87, 81, 149);
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    text-align: center;
    padding-top: rem-calc(11);
  }
}

.archive-application-content {
  background: rgb(238, 237, 244);
  border-radius: 0px;
  height: 40px;
  margin-left: rem-calc(-100);
  margin-right: rem-calc(-100);
  z-index: -15;
  .archive-warning-text {
    color: rgb(87, 81, 149);
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    text-align: center;
    padding-top: rem-calc(11);
  }
}

.checkbox-email {
  padding-top: rem-calc(24);
}

.checkbox-connection-confirm {
  padding-top: rem-calc(24);
}

.release-date {
  font-weight: bold;
}

.cohort-connect-instrcution {
  margin-top: rem-calc(16);
  padding-top: rem-calc(16);
  border-top: 1px solid rgb(151, 151, 151);
}

.connect-cohort-flyout-content {
  margin-left: rem-calc(100);
  margin-top: rem-calc(24);
  max-width: rem-calc(616);
  .release-date-label {
    font-weight: 600;
  }
  .release-confirmation {
    padding-top: rem-calc(16);
  }
  .licensure-plan-title {
    font-size: rem-calc(22);
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(8);
  }
  .licensure-plan-tab-data {
    margin: 0;
  }
}

.activity-history {
  & + .activity-history {
    padding-top: rem-calc(12);
  }
}

.logged-note {
  margin-left: rem-calc(20);
  margin-bottom: rem-calc(8);
  color: rgb(107, 107, 107);
  font-size: 14px;
  font-style: italic;
}

.edit-note-btn {
  text-align: right;
}

.status-log {
  font-size: rem-calc(14);
}

#tag-modal-body {
  display: flex;
  padding: rem-calc(20 28 0 28);
  overflow: auto;
}

.no-requirements {
  font-style: italic;
  color: rgb(107, 107, 107);
}

.selected-users {
  font-size: rem-calc(14);
  margin-bottom: rem-calc(4);
}

.ttip {
  margin-left: rem-calc(-9);
}

.empty-text {
  font-size: rem-calc(14);
  font-style: italic;
  font-weight: normal;
  margin: 0;
}

#bulk-status-flyout {
  &.half-flyout {
    > .container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: unset;
      left: unset;
      @media screen and (min-width: 1024px) {
        left: unset;
        width: 90%;
        max-width: unset;
      }
      > .header {
        @include justifyContent(flex-start);
        .cohort-header-details {
          margin-left: rem-calc(24);
        }
      }
      > .content {
        overflow: hidden;
        .bulk-status-content {
          height: 100%;
          @include displayFlex();
          .student-section {
            padding-left: rem-calc(48);
            padding-top: rem-calc(16);
            flex: 2;
            border-right: 1px solid rgb(151, 151, 151);
            .subsection-label {
              margin: 0;
            }
            .filter-by-status {
              margin-top: rem-calc(16);
              margin-bottom: rem-calc(20);
              margin-right: rem-calc(30);
              @include justifyContent(flex-start);
              .search-students {
                width: unset;
                flex: 2;
              }
              .filter-status {
                width: unset;
                flex: 1;
                .filter-label {
                  width: rem-calc(56);
                  font-size: rem-calc(14);
                }
                .select-status {
                  width: rem-calc(240);
                }
              }
            }
            .data-table {
              margin: rem-calc(12 0 0 0);
              .dataheaderrow {
                height: rem-calc(40);
                th {
                  padding-left: rem-calc(30);
                  background-color: rgba(46, 27, 70, 0.05);
                  font-size: rem-calc(12);
                  text-transform: uppercase;
                  width: 33%;
                  text-align: left;
                  &:hover {
                    cursor: pointer;
                  }
                  .checkbox-option {
                    .chkbx {
                      font-size: rem-calc(12);
                      display: -webkit-inline-box;
                      &:before {
                        margin-right: rem-calc(12);
                      }
                    }
                  }
                  &:focus {
                    box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
                      inset 0 2px 2px 0 rgb(0 0 0 / 10%),
                      inset 0 0 4px 3px #61279e;
                    outline: none;
                  }
                }
              }
              .databody {
                .datarow {
                  height: rem-calc(60);
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr;
                  &:last-child {
                    border-bottom: 2px solid #f4f3f6;
                  }
                  td {
                    word-break: break-all;
                    min-width: 0%;
                    width: unset;
                    padding: rem-calc(12 12 12 30);
                    font-size: rem-calc(14);
                  }
                  .checkbox-option {
                    .chkbx {
                      font-weight: 600;
                      display: -webkit-inline-box;
                      &:before {
                        margin-right: rem-calc(12);
                      }
                    }
                  }
                }
              }
            }
            table tbody {
              overflow: auto;
              height: calc(100vh - 330px);
              &.-selected {
                height: calc(100vh - 265px);
              }
            }
          }
          .change-status-section {
            overflow: auto;
            flex: 1;
            padding-left: rem-calc(24);
            padding-top: rem-calc(16);
            padding-right: rem-calc(30);
            .subsection-label {
              margin: 0;
            }
            .required-fields {
              margin-top: rem-calc(24);
              margin-bottom: rem-calc(24);
              font-size: rem-calc(14);
              text-align: center;
            }
            .no-sections-info {
              font-size: rem-calc(14);
              padding-top: rem-calc(4);
            }
            .status-block {
              margin-top: rem-calc(24);
              margin-bottom: 0;
            }
            .student-ack {
              margin-top: rem-calc(24);
              margin-bottom: rem-calc(24);
              padding: rem-calc(16);
              background: rgb(250, 250, 250);
              .ack-label {
                font-size: rem-calc(14);
                font-weight: 600;
              }
              .ack-info {
                font-size: rem-calc(14);
                padding-top: rem-calc(4);
                padding-bottom: rem-calc(8);
              }
              .no-sections-info {
                margin-left: rem-calc(16);
              }
            }
            .textfield {
              &.is-focused .charcount.-textarea {
                padding-bottom: rem-calc(0);
              }
              textarea.textfieldinput {
                resize: none;
                min-height: rem-calc(106);
                max-height: rem-calc(106);
                overflow-y: auto;
                box-sizing: border-box;
                &:focus {
                  min-height: rem-calc(68);
                  max-height: rem-calc(68);
                  margin-bottom: rem-calc(38);
                }
              }
            }
          }
        }
      }
      button,
      wm-button {
        &:not(:last-child) {
          margin-right: rem-calc(0);
        }
      }
    }
  }
}

#connect-lp-flyout {
  &.half-flyout {
    > .container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: unset;
      left: unset;
      @media screen and (min-width: 1440px) {
        left: unset;
        width: rem-calc(1272);
        max-width: unset;
      }
      > .header {
        box-shadow: none;
        border: 1px solid rgb(231, 231, 231);
        @include justifyContent(flex-start);
        .header-details {
          margin-left: rem-calc(24);
          .subtitle {
            color: rgb(74, 74, 74);
            font-size: rem-calc(14);
            font-weight: 300;
            text-transform: uppercase;
            line-height: rem-calc(20);
          }
        }
      }
      > .content {
        background: rgb(255, 255, 255);
        box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.09);
        overflow: auto;
        .main-section {
          padding: rem-calc(0 42 24 102);
          .required-fields-text {
            margin: rem-calc(24 0);
            font-size: rem-calc(14);
            text-align: center;
          }
          .first-row {
            display: flex;
            @media screen and (max-width: 1024px) {
              flex-direction: column;
            }
            .section-card {
              width: 73%;
              @media screen and (max-width: 1024px) {
                width: 100%;
              }
              &.select-paper-card {
                box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
                border-radius: rem-calc(5);
                margin-right: rem-calc(24);
                margin-bottom: rem-calc(24);
                .select-row {
                  max-width: rem-calc(550);
                }
              }
            }
          }
          .releaseDate-paper-card {
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
            border-radius: rem-calc(5);
            padding-bottom: 0;
            margin-bottom: rem-calc(24);
          }
          .review-paper-card {
            @media screen and (max-width: 1024px) {
              width: 100%;
            }
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
            border-radius: rem-calc(5);
            padding-bottom: 0;
            width: 55.5%;
            min-height: rem-calc(280);
            .body {
              &.no-plan-content {
                @include displayFlex;
                @include flexDirection(column);
                @include alignItems(center);
                @include justifyContent(center);
                height: 100%;
                padding-top: rem-calc(60);
                padding-bottom: rem-calc(84);
                text-align: center;
                .not-selected {
                  font-size: rem-calc(24);
                  font-weight: normal;
                }
              }
              &.plan-selected {
                @include displayFlex;
                @include flexDirection(column);
                @include alignItems(flex-start);
                @include justifyContent(flex-start);
                color: rgb(74, 74, 74);
                .review-plan-section {
                  border-bottom: 1px solid rgb(222, 222, 222);
                  padding-bottom: rem-calc(24);
                  &:not(:first-child) {
                    padding-top: rem-calc(24);
                  }
                  .title {
                    font-size: rem-calc(16);
                    font-weight: 500;
                    line-height: rem-calc(24);
                    margin: rem-calc(0 0 6 0);
                  }
                  .requirement-title {
                    font-size: rem-calc(14);
                    font-weight: 600;
                    line-height: rem-calc(20);
                    margin: 0;
                  }
                  .accordion-label {
                    font-size: rem-calc(16);
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: rem-calc(24);
                    padding: rem-calc(0 0 11 16);
                    margin-bottom: 0;
                  }
                  .requirement-body {
                    padding-left: rem-calc(16);
                    .requirement-details {
                      padding-bottom: rem-calc(6);
                    }
                  }
                }
              }
            }
          }
        }
      }
      .flyout-footer {
        background: rgb(255, 255, 255);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
        #close-flyout-button {
          margin: 0;
        }
        #connect-btn {
          margin-right: rem-calc(30);
        }
      }
    }
  }
}

#view-changestatus-flyout {
  &.half-flyout {
    > .container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 30%;
      max-width: unset;
      left: unset;
      @media screen and (min-width: 1440px) {
        left: unset;
        width: 480px;
        max-width: unset;
      }
      > .header {
        &.change-status-header {
          .title {
            color: rgb(74, 74, 74);
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
      > .content {
        overflow: auto;
        .content-card {
          padding: rem-calc(32 36 0 36);
          .required-field {
            margin-bottom: rem-calc(24);
            font-size: rem-calc(14);
            text-align: center;
          }
          .status-block {
            margin-top: rem-calc(24);
            margin-bottom: 0;
          }
          .helper-text {
            color: rgb(74, 74, 74);
            font-size: rem-calc(14);
            font-style: italic;
            font-weight: normal;
            line-height: 20px;
            padding-top: rem-calc(4);
          }
          .textfield {
            &.is-focused .charcount.-textarea {
              padding-bottom: rem-calc(0);
            }
            textarea.textfieldinput {
              resize: none;
              min-height: rem-calc(165);
              max-height: rem-calc(165);
              overflow-y: auto;
              box-sizing: border-box;
              &:focus {
                min-height: rem-calc(165);
                max-height: rem-calc(165);
                margin-bottom: rem-calc(38);
              }
            }
          }
        }
      }
      > .change-status-flyout-footer {
        background-color: #fff;
        @include transition(all 0.2s cubic-bezier(0.4, 0, 0.2, 1));
        padding: rem-calc(20 30);
        border-top: rem-calc(1) solid #f4f3f6;
        @include displayFlex();
        @include justifyContent(flex-end);
        @include alignItems(center);
      }
      button,
      wm-button {
        &:not(:last-child) {
          margin-right: rem-calc(0);
        }
      }
    }
  }
}

.left-contents {
  margin-left: rem-calc(20);
  .no-requirements {
    font-size: rem-calc(14);
    margin-right: rem-calc(24);
  }
}

#edit-note-flyout {
  .container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 480px;
    max-width: unset;
    left: unset;
  }
  .edit-note-content {
    padding: rem-calc(24 24 0 30);
  }
  .textfield {
    &.is-focused .charcount.-textarea {
      padding-bottom: rem-calc(0);
    }
    textarea.textfieldinput {
      resize: none;
      min-height: rem-calc(106);
      max-height: rem-calc(106);
      overflow-y: auto;
      box-sizing: border-box;
      &:focus {
        min-height: rem-calc(68);
        max-height: rem-calc(68);
        margin-bottom: rem-calc(38);
      }
    }
  }
  .status-block {
    margin-bottom: rem-calc(8);
    margin-top: rem-calc(24);
  }
  .no-sections-info {
    font-size: rem-calc(14);
    color: rgb(74, 74, 74);
  }
  button,
  wm-button {
    &:not(:last-child) {
      margin-right: 0;
    }
    &:before {
      margin-right: rem-calc(5);
    }
  }
}

.student-cohort-name {
  font-size: rem-calc(18);
  font-weight: bold;
  &:hover:not(.button):not([role]) {
    padding-bottom: 0;
  }
}

.mini-panel {
  position: relative;
  margin-left: 0;
  width: rem-calc(430);
  min-width: rem-calc(430);
  background: rgb(255, 255, 255);
  box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.09);
  @media only screen and (max-width: 1200px) {
    width: auto;
  }
  .header {
    border-bottom: 1px solid rgb(238, 237, 244);
    padding: rem-calc(20 20 12 30);
    position: relative;
    .title {
      margin: 0;
      color: rgb(74, 74, 74);
      font-size: rem-calc(18);
      font-weight: 600;
    }
  }
  .body {
    @include flex(1);
    overflow: auto;
    padding: rem-calc(20 30 20 30);
    .log-section {
      &:not(:last-child) {
        &::after {
          display: block;
          content: "";
          border: 1px solid rgb(222, 222, 222);
          margin: rem-calc(12 0 4 -30);
        }
      }
      .-align {
        text-align: center;
      }
      .timestamp {
        color: rgb(107, 107, 107);
        font-size: rem-calc(14);
        font-style: italic;
        font-weight: normal;
      }
      .log-text {
        color: rgb(74, 74, 74);
        font-size: rem-calc(14);
        font-weight: 600;
      }
    }
  }
  button.-icononly {
    border-width: 0;
    .icon {
      margin-right: rem-calc(3);
    }
  }
}

.licensure-plan-main {
  padding-left: rem-calc(40);
  .licensureplan-sub-tab {
    width: 91%;
    .empty-cohort-view {
      margin-top: rem-calc(48);
    }
    .licensureplan-label {
      margin: rem-calc(24 0 24);
      color: rgb(74, 74, 74);
      font-size: rem-calc(22);
      font-weight: normal;
    }
    .count {
      color: rgb(107, 107, 107);
      font-weight: normal;
      font-style: italic;
      margin: rem-calc(31 0 27 8);
      font-size: rem-calc(14);
    }
    .show-archived-plans {
      color: rgb(74, 74, 74);
      font-size: rem-calc(14);
      font-weight: normal;
      margin: 0;
      .toggle-switch {
        margin: rem-calc(8 8 0 0);
      }
      .switch-label {
        padding: rem-calc(4 16 0 0);
      }
      .toggle-text {
        position: absolute;
        color: $body-text-color;
        font-size: rem-calc(12);
        font-weight: bold;
        line-height: rem-calc(24);
        right: rem-calc(6);

        &.on {
          left: rem-calc(9);
          right: auto;
        }
      }
    }
    .search-select-section {
      margin-bottom: rem-calc(24);
      .search-bar {
        background-color: rgb(255, 255, 255);
        border-bottom: rgb(74, 74, 74);
        // border-radius: 3px;
        @media screen and (min-width: 1024px) {
          width: rem-calc(414);
          max-width: rem-calc(414);
        }
        @media screen and (max-width: 768) {
          width: rem-calc(350);
          max-width: rem-calc(350);
        }
      }
      .filter-label {
        font-weight: 600;
        font-size: rem-calc(14);
        margin: rem-calc(14 12 13 24);
        @media screen and (max-width: 850px) {
          margin-top: 0;
        }
      }
      .filter-by {
        width: rem-calc(208);
        background-color: rgb(255, 255, 255);
        margin-right: rem-calc(18);
        span {
          padding: rem-calc(12 0 12 16);
        }
      }
    }
    .data-table {
      background: rgb(255, 255, 255);
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
      border-radius: 5px;
    }
    .data-table .dataheaderrow th {
      background-color: rgb(238, 237, 244);
      text-transform: uppercase;
      text-align: left;

      .user-is-tabbing &:focus {
        box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
          inset 0 2px 2px 0 rgb(0 0 0 / 10%), inset 0 0 4px 3px #61279e;
        outline: none;
      }
      &.plan-title-header {
        border-radius: rem-calc(5 0 0 0);
        width: 60%;
      }
      &.plan-lastUpdated-header {
        width: 25%;
      }
      &.plan-status-header {
        border-radius: rem-calc(0 5 0 0);
        width: 15%;
      }
      @media screen and (max-width: 850px) {
        &.plan-title-header {
          width: 45%;
        }
        &.plan-lastUpdated-header,
        &.plan-status-header {
          width: 30%;
          padding: 0;
        }
      }
    }
    .data-table .databody .datarow {
      border: 1px solid rgb(236, 238, 243);
      .td-content {
        word-break: break-all;
        min-width: 0%;
        padding: rem-calc(12 12 12 30);
        font-size: rem-calc(14);
        @media screen and (max-width: 850px) {
          padding: rem-calc(12 6 12 20);
        }
        .student-cohort-name {
          font-size: rem-calc(18);
          font-weight: bold;
          &:hover:not(.button):not([role]) {
            padding-bottom: 0;
          }
        }
      }
      .plan-name {
        color: rgb(87, 81, 149);
        font-size: rem-calc(18);
        font-weight: bold;
        width: 60%;
      }
      .plan-updated {
        width: 25%;
        color: rgb(107, 107, 107);
        font-size: rem-calc(14);
        font-weight: normal;
        font-style: italic;
      }
      .actions-kebab {
        padding-right: rem-calc(20);
        width: 5%;
      }
      @media screen and (max-width: 850px) {
        .plan-name {
          width: 45%;
        }
        .plan-updated {
          width: 32%;
        }
      }
    }
    .plan-status {
      width: 15%;
      font-size: rem-calc(14);
      font-weight: 600;
      text-transform: uppercase;
      .not-in-use {
        background: rgb(242, 240, 243);
        border-radius: rem-calc(5);
        padding: rem-calc(4 8);
      }
      .not-connected {
        max-width: rem-calc(87);
        min-height: rem-calc(16);
        color: rgb(74, 74, 74);
        letter-spacing: rem-calc(0.3);
        text-transform: capitalize;
      }
      .in-use {
        background: rgba(8, 128, 0, 0.15);
        border-radius: rem-calc(5);
        color: rgb(7, 107, 0);
        letter-spacing: 0px;
        padding: rem-calc(4 8);
      }
      .archived {
        background: rgb(238, 237, 244);
        border-radius: rem-calc(5);
        color: rgb(87, 81, 149);
        letter-spacing: rem-calc(0.3);
        padding: rem-calc(4 8);
      }
    }
    tbody:last-child tr {
      border-radius: rem-calc(0 0 5 5);
    }
    .data-table thead,
    .data-table tbody,
    .view-plans-list {
      @media screen and (max-width: 850px) {
        display: block;
        .plan-status {
          width: 30%;
        }
      }
    }
  }
  .no-licensure-plan {
    @include displayFlex;
    @include flexDirection(column);
    @include alignItems(center);
    margin-top: rem-calc(40);
    .mdi-folder-clock-outline {
      font-size: rem-calc(36);
      color: rgb(107, 107, 107);
    }
    .title {
      font-size: rem-calc(24);
      color: rgb(74, 74, 74);
      font-weight: normal;
    }
    .helper-text {
      font-size: rem-calc(14);
      margin: 0;
      color: rgb(74, 74, 74);
      font-weight: normal;
    }
  }
  .create-plan-btn {
    margin-top: rem-calc(16);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(87, 81, 149, 0.12);
    border-radius: rem-calc(5);
  }
}

.data-table .dataheaderrow th {
  padding-left: rem-calc(30);
  background-color: rgba(46, 27, 70, 0.05);
  font-size: rem-calc(12);
  text-transform: uppercase;
  width: 33%;
  text-align: left;
  .plan-name {
    padding-left: rem-calc(30);
    padding-right: rem-calc(12);
  }
}

.view-plans-list .dataheaderrow {
  height: rem-calc(40);
}

.create-plan-btn {
  margin-top: rem-calc(16);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(87, 81, 149, 0.12);
  border-radius: rem-calc(5);
}

.middle-panel {
  text-align: center;
  padding-top: rem-calc(20);
  padding-bottom: rem-calc(20);
  width: rem-calc(48);
  min-width: rem-calc(48);
  background: #ffffff;
  z-index: 15;
  box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.09);
}

.selected-section {
  width: rem-calc(230);
  position: absolute;
  top: rem-calc(220);
  right: rem-calc(40);
  bottom: rem-calc(70);
  overflow-y: auto;
  .req-title {
    color: rgb(74, 74, 74);
    font-size: 14px;
    font-weight: normal;
    &::before {
      border-left: 4px solid rgb(87, 81, 149);
    }
  }
  .sub-req-title {
    margin-left: rem-calc(20);
    color: rgb(87, 81, 149);
    font-size: 14px;
  }
  .req-selected-title {
    color: rgb(87, 81, 149);
    font-size: 14px;
    font-weight: 600;
  }
  .body {
    padding-top: rem-calc(16);
    padding-right: rem-calc(8);
  }
  .requirement-container {
    margin-bottom: rem-calc(8);
    .btn-title {
      border: 10px;
      box-sizing: border-box;
      display: inline-block;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      cursor: pointer;
      text-decoration: none;
      margin: 0px;
      padding: 0px;
      outline: none;
      font-size: 14px;
      font-weight: inherit;
      position: relative;
      height: 36px;
      color: rgb(74, 74, 74);
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      border-radius: 2px;
      user-select: none;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0);
      text-align: center;
      text-transform: capitalize;
      &.btn-sub-title {
        height: 24px;
      }
    }
    .panel-sections {
      border-left: 4px solid rgb(74, 74, 74);
      padding-left: rem-calc(16);
      margin-top: 4px;
      min-height: 43px;
      display: flex;
      align-items: center;
      a {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &:hover {
          background: none;
          text-decoration: underline;
          position: relative;
        }
      }
    }
    .panel-section-selected {
      border-left: 4px solid rgb(87, 81, 149);
      padding-left: rem-calc(16);
      margin-top: 4px;
      min-height: 43px;
      display: flex;
      align-items: center;
      a {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-weight: 600;
        &:hover {
          background: none;
          text-decoration: underline;
          position: relative;
        }
      }
    }
    .panel-sub-sections {
      border-left: 4px solid rgb(74, 74, 74);
      margin-top: 4px;
      min-height: 3px;
      margin-left: 16px;
      display: flex;
      align-items: center;
      a {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-weight: 600;
        &:hover {
          background: none;
          text-decoration: underline;
          position: relative;
        }
      }
    }
    .panel-sub-section-selected {
      border-left: 4px solid rgb(87, 81, 149);
      margin-top: 4px;
      min-height: 3px;
      margin-left: 16px;
      display: flex;
      align-items: center;
      a {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-weight: 600;
        &:hover {
          background: none;
          text-decoration: underline;
        }
      }
    }
  }
}

.multi-panel-tablist {
  width: 100%;
}

#extract-submissions-flyout {
  &.half-flyout {
    > .container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: unset;
      left: unset;
      background: rgb(255, 255, 255);
      box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.09);
      border-radius: 0;
      @media screen and (min-width: 800px) {
        left: unset;
        width: rem-calc(720);
        max-width: unset;
      }
      .extract-submissions-header {
        box-shadow: none;
        border-bottom: 1px solid rgb(231, 231, 231);
        padding: rem-calc(20 36);
        .header-section {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          .select-label {
            font-size: rem-calc(14);
            line-height: rem-calc(20);
            margin: 0;
            font-weight: normal;
          }
          .bread-crumbs {
            font-size: rem-calc(14);
            margin-bottom: rem-calc(4);
            a:hover:not(.button):not([role]) {
              padding-bottom: 0;
            }
            .extract-submissions-header {
              box-shadow: none;
              border-bottom: 1px solid rgb(231, 231, 231);
              padding: rem-calc(20 36);
              .header-section {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .select-label {
                  font-size: rem-calc(14);
                  line-height: rem-calc(20);
                  margin: 0;
                  font-weight: normal;
                }
                .bread-crumbs {
                  font-size: rem-calc(14);
                  margin-bottom: rem-calc(4);
                  a:hover:not(.button):not([role]) {
                    padding-bottom: 0;
                  }
                  .link {
                    font-weight: bold;
                    display: inline-flex;
                    span {
                      line-height: normal;
                    }
                    &::after {
                      vertical-align: top;
                    }
                  }
                  .bread-crumb-text {
                    font-size: rem-calc(14);
                    line-height: rem-calc(20);
                  }
                }
                .extract-submission-title {
                  margin: 0;
                  margin-bottom: rem-calc(2);
                  font-size: rem-calc(18);
                  font-weight: 600;
                  line-height: rem-calc(26);
                }
                .extract-submission-info-text {
                  margin: 0;
                  font-size: rem-calc(14);
                }
              }
            }
            .extracts-main-body {
              height: calc(100vh - 198px);
              height: -webkit-calc(100vh - 198px);
              .student-export-info-text {
                padding-left: rem-calc(130);
                text-align: center;
                margin: rem-calc(20 0 0 0);
                font-size: rem-calc(14);
              }
              .student-selection-container,
              .req-selection-body {
                padding: rem-calc(30);
                .reqd {
                  margin-left: rem-calc(4);
                }
                #none-selected {
                  outline: none;
                }
                .none-selected-error {
                  margin: 0;
                }
                .select-student-text {
                  margin: 0;
                  font-size: rem-calc(14);
                  font-weight: 600;
                }
                .search-div {
                  width: 95%;
                  margin: rem-calc(4 0 24);
                }
                .data-table {
                  margin: rem-calc(12 0 0 0);
                  .dataheaderrow {
                    height: rem-calc(40);
                    th {
                      padding-left: rem-calc(30);
                      background-color: rgba(46, 27, 70, 0.05);
                      font-size: rem-calc(12);
                      text-transform: uppercase;
                      text-align: left;
                      &.student-name {
                        width: 40%;
                      }
                      &.student-email {
                        width: 60%;
                      }
                      &:hover {
                        cursor: pointer;
                      }
                      .checkbox-option {
                        .chkbx {
                          font-size: rem-calc(12);
                          display: -webkit-inline-box;
                          &:before {
                            margin-right: rem-calc(36);
                          }
                        }
                      }
                      &:focus {
                        box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
                          inset 0 2px 2px 0 rgb(0 0 0 / 10%),
                          inset 0 0 4px 3px #61279e;
                        outline: none;
                      }
                    }
                  }
                  .databody {
                    overflow: auto;
                    .datarow {
                      height: rem-calc(60);
                      &:last-child {
                        border-bottom: 2px solid #f4f3f6;
                      }
                      td {
                        word-break: break-all;
                        min-width: 0%;
                        width: unset;
                        padding: rem-calc(12 12 12 30);
                        font-size: rem-calc(14);
                      }
                      .checkbox-option {
                        .chkbx {
                          font-weight: 600;
                          display: -webkit-inline-box;
                          &:before {
                            margin-right: rem-calc(36);
                          }
                        }
                      }
                    }
                  }
                }
                .student-submission-content .data-table {
                  .dataheader {
                    float: left;
                    width: 100%;
                  }
                  tr {
                    width: 100%;
                    display: table;
                    text-align: left;
                  }
                  .databody {
                    display: block;
                    width: 100%;
                    max-height: calc(100vh - 416px);
                    max-height: -webkit-calc(100vh - 416px);
                    .datarow .student-email {
                      width: 60%;
                    }
                    .datarow .student-name {
                      width: 40%;
                    }
                  }
                }
                .req-selection-table {
                  .toggletip {
                    font-size: rem-calc(14);
                    font-weight: normal;
                    line-height: rem-calc(20);
                    text-transform: none;
                  }
                  .dataheaderrow {
                    th {
                      display: flex;
                      gap: rem-calc(8);
                      align-items: center;
                    }
                  }
                  .dataheaderrow th .checkbox-option .chkbx {
                    &:before {
                      margin-right: rem-calc(10);
                    }
                  }
                  .req-selection-tbody {
                    display: block;
                    width: 100%;
                    height: calc(100vh - 378px);
                    height: -webkit-calc(100vh - 378px);
                    &.student-flow-tbody {
                      height: calc(100vh - 416px);
                      height: -webkit-calc(100vh - 416px);
                    }
                    .datarow {
                      display: block;
                    }
                    .datarow .secreq-selection-cell,
                    .datarow .subsec-selection-cell {
                      padding-left: rem-calc(50);
                    }
                    .datarow .subsecreq-selection-cell {
                      padding-left: rem-calc(70);
                    }
                    .label-div {
                      margin-right: rem-calc(30);
                      word-break: break-word;
                    }
                    .datarow .checkbox-option {
                      .chkbx {
                        font-weight: normal;
                        &:before {
                          margin-right: rem-calc(10);
                        }
                      }
                    }
                  }
                }
                @media screen and (max-width: 800px) {
                  .data-table thead {
                    display: table-header-group;
                  }
                }
              }
              .pagination-footer {
                display: flex;
                padding-bottom: 0;
                padding-top: rem-calc(10);
                height: auto;
                align-items: center;
                justify-content: space-around;
              }
            }
            .flyout-footer {
              background: rgb(255, 255, 255);
              box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
              flex-direction: row-reverse;
              position: absolute;
              bottom: 0;
              width: 100%;
              #btn-secondary {
                margin: 0;
              }
            }
          }
          .extract-submission-title {
            margin: 0;
            margin-bottom: rem-calc(2);
            font-size: rem-calc(18);
            font-weight: 600;
            line-height: rem-calc(26);
          }
          .extract-submission-info-text {
            margin: 0;
            font-size: rem-calc(14);
          }
        }
      }
      .extracts-main-body {
        height: calc(100vh - 198px);
        height: -webkit-calc(100vh - 198px);
        .student-export-info-text {
          padding-left: rem-calc(130);
          text-align: center;
          margin: rem-calc(20 0 0 0);
          font-size: rem-calc(14);
        }
        .student-selection-container,
        .req-selection-body {
          padding: rem-calc(30);
          .reqd {
            margin-left: rem-calc(4);
          }
          #none-selected {
            outline: none;
          }
          .none-selected-error {
            margin: 0;
          }
          .select-student-text {
            margin: 0;
            font-size: rem-calc(14);
            font-weight: 600;
          }
          .search-div {
            width: 95%;
            margin: rem-calc(4 0 24);
          }
          .data-table {
            margin: rem-calc(12 0 0 0);
            .dataheaderrow {
              height: rem-calc(40);
              th {
                padding-left: rem-calc(30);
                background-color: rgba(46, 27, 70, 0.05);
                font-size: rem-calc(12);
                text-transform: uppercase;
                text-align: left;
                &.student-name {
                  width: 40%;
                }
                &.student-email {
                  width: 60%;
                }
                &:hover {
                  cursor: pointer;
                }
                .checkbox-option {
                  .chkbx {
                    font-size: rem-calc(12);
                    display: -webkit-inline-box;
                    &:before {
                      margin-right: rem-calc(36);
                    }
                  }
                }
                &:focus {
                  box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
                    inset 0 2px 2px 0 rgb(0 0 0 / 10%),
                    inset 0 0 4px 3px #61279e;
                  outline: none;
                }
              }
            }
            .databody {
              overflow: auto;
              .datarow {
                height: rem-calc(60);
                &:last-child {
                  border-bottom: 2px solid #f4f3f6;
                }
                td {
                  word-break: break-all;
                  min-width: 0%;
                  width: unset;
                  padding: rem-calc(12 12 12 30);
                  font-size: rem-calc(14);
                }
                .checkbox-option {
                  .chkbx {
                    font-weight: 600;
                    display: -webkit-inline-box;
                    &:before {
                      margin-right: rem-calc(36);
                    }
                  }
                }
              }
            }
          }
          .student-submission-content .data-table {
            .dataheader {
              float: left;
              width: 100%;
            }
            tr {
              width: 100%;
              display: table;
              text-align: left;
            }
            .databody {
              display: block;
              width: 100%;
              max-height: calc(100vh - 416px);
              max-height: -webkit-calc(100vh - 416px);
              .datarow .student-email {
                width: 60%;
              }
              .datarow .student-name {
                width: 40%;
              }
            }
          }
          .req-selection-table {
            .toggletip {
              font-size: rem-calc(14);
              font-weight: normal;
              line-height: rem-calc(20);
              text-transform: none;
            }
            .dataheaderrow th .checkbox-option .chkbx {
              &:before {
                margin-right: rem-calc(10);
              }
            }
            .req-selection-tbody {
              display: block;
              width: 100%;
              height: calc(100vh - 378px);
              height: -webkit-calc(100vh - 378px);
              .datarow {
                display: block;
              }
              .datarow .secreq-selection-cell,
              .datarow .subsec-selection-cell {
                padding-left: rem-calc(50);
              }
              .datarow .subsecreq-selection-cell {
                padding-left: rem-calc(70);
              }
              .label-div {
                margin-right: rem-calc(30);
                word-break: break-word;
              }
              .datarow .checkbox-option {
                .chkbx {
                  font-weight: normal;
                  &:before {
                    margin-right: rem-calc(10);
                  }
                }
              }
            }
          }
          @media screen and (max-width: 800px) {
            .data-table thead {
              display: table-header-group;
            }
          }
        }
        .pagination-footer {
          display: flex;
          padding-bottom: 0;
          padding-top: rem-calc(10);
          height: auto;
          align-items: center;
          justify-content: space-around;
        }
      }
      .flyout-footer {
        background: rgb(255, 255, 255);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
        flex-direction: row-reverse;
        position: absolute;
        bottom: 0;
        width: 100%;
        #btn-secondary {
          margin: 0;
        }
      }
    }
  }
}

#export-settings-flyout {
  .breadCrumb-container {
    display: flex;
    gap: rem-calc(3);
    align-items: center;
    .secondary-text {
      margin-top: rem-calc(4);
      font-weight: 400;
      font-size: rem-calc(14);
      line-height: rem-calc(22);
      color: rgba(74, 74, 74, 1);
    }
  }
  .content-section {
    width: 100%;
    padding: rem-calc(24 30 24 30);
    max-height: calc(100vh - 243px);
    max-height: -webkit-calc(100vh - 243px);
    overflow-y: auto;
    overflow-x: hidden;
    .export-format-section {
      margin-top: rem-calc(2);
      display: flex;
      align-items: center;
      gap: rem-calc(4);
      .export-format-text {
        font-size: rem-calc(14);
        font-weight: 600;
        line-height: rem-calc(22);
        color: rgba(0, 0, 0, 1);
      }
      .required-text {
        color: rgb(192, 57, 43);
        font-size: rem-calc(14);
      }
    }
    .radio-options {
      display: flex;
      flex-direction: column;
      gap: rem-calc(12);
      margin-top: rem-calc(12);
      .option {
        position: relative;
      }
    }
    .export-settings-section {
      margin-top: rem-calc(24);
      .export-settings-text {
        margin-top: rem-calc(5);
        font-weight: 600;
        font-size: rem-calc(14);
        line-height: rem-calc(22);
        color: rgba(0, 0, 0, 1);
      }
      .export-settings-options {
        display: flex;
        flex-direction: column;
        gap: rem-calc(12);
        margin-top: rem-calc(5);
        .checkbox-options {
          position: relative;
        }
        .section-title {
          font-size: rem-calc(14);
          font-weight: 400;
          line-height: rem-calc(22);
          color: rgba(74, 74, 74, 1);
          cursor: pointer;
        }
      }
    }
    .accordion-container {
      margin-top: rem-calc(24);
      .accordion-header {
        display: flex;
        align-items: center;
        .left-container {
          width: rem-calc(300px);
          display: flex;
          #selected-student-toggle-btn {
            display: flex;
          }
          .accordion-label {
            font-size: rem-calc(14);
            font-weight: 600;
            line-height: rem-calc(22);
            color: rgba(0, 0, 0, 1);
          }
          .count-text {
            font-size: rem-calc(14);
            font-weight: 400;
            line-height: rem-calc(22);
            color: rgba(0, 0, 0, 1);
            font-style: italic;
            margin-left: rem-calc(12);
          }
        }

        .update-student-container {
          margin-left: rem-calc(71);
          #update-selected-students-btn {
            display: flex;
          }
        }
      }
      .accordion-content {
        margin: 0;
        margin-left: rem-calc(5);
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: rem-calc(8px);
        list-style: none;
        .section-container {
          margin-top: rem-calc(16);
          .section-name {
            margin-top: rem-calc(16);
            font-size: rem-calc(14);
            font-weight: 400;
            line-height: rem-calc(22);
            color: rgba(0, 0, 0, 1);
            font-style: italic;
          }
          .req-name:first-child {
            margin-top: rem-calc(12);
          }
          .req-name {
            font-size: rem-calc(14);
            font-weight: 400;
            line-height: rem-calc(22);
            color: rgba(0, 0, 0, 1);
          }
          .section-req-container {
            display: flex;
            flex-direction: column;
            list-style: none;
            gap: rem-calc(8);
            padding: 0;
            margin: 0;
            padding-left: rem-calc(18);
          }
          .subsection-container {
            list-style: none;
            padding-left: rem-calc(36);
            .subsection-container-item {
              margin-top: rem-calc(12);
              .subsection-name {
                font-size: rem-calc(14);
                font-weight: 400;
                line-height: rem-calc(22);
                color: rgba(0, 0, 0, 1);
                font-style: italic;
              }
              .subsection-req-container {
                padding-left: rem-calc(18);
                display: flex;
                flex-direction: column;
                gap: rem-calc(8);
                list-style: none;
              }
            }
          }
        }
        .item-name:first-child {
          margin-top: rem-calc(16);
        }
        .item-name {
          display: flex;
          gap: rem-calc(20);
          font-size: rem-calc(14);
          font-weight: 400;
          line-height: rem-calc(22);
          color: rgba(0, 0, 0, 1);
          .name-text {
            width: rem-calc(172);
          }
        }
      }
    }
  }
  .footer-section {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: rem-calc(20 30);
    gap: rem-calc(97);
    border-top: 1px solid rgba(210, 208, 212, 1);
    background-color: #ffffff;
    .info-section {
      display: flex;
      gap: rem-calc(15);
      .count-text {
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(22);
        font-style: italic;
        color: rgba(74, 74, 74, 1);
        &.error {
          color: rgb(192, 57, 43);
        }
      }
      .show-selected-section {
        position: relative;
        display: flex;
        gap: rem-calc(10);
        .show-selected-text {
          font-size: rem-calc(14);
          font-weight: 400;
          line-height: rem-calc(22);
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }
}

#student-flyout {
  .breadCrumb-container {
    display: flex;
    gap: rem-calc(3);
    align-items: center;
    .secondary-text {
      margin-top: rem-calc(4);
      font-weight: 400;
      font-size: rem-calc(14);
      line-height: rem-calc(22);
      color: rgba(74, 74, 74, 1);
    }
  }
  .content-section {
    width: 100%;
    padding: rem-calc(24 30 0 30);
    .select-section {
      display: flex;
      align-items: center;
      gap: rem-calc(4);
      .select-section-text {
        font-size: rem-calc(16);
        font-weight: 500;
        line-height: rem-calc(20);
        color: rgba(0, 0, 0, 1);
        &.error {
          color: rgb(192, 57, 43);
        }
      }
      .required-text {
        color: rgb(192, 57, 43);
        font-size: rem-calc(14);
      }
    }
    .error-text {
      color: rgba(192, 57, 43, 1);
      font-size: rem-calc(14);
      font-style: italic;
      font-weight: 400;
      line-height: rem-calc(22);
    }
    .search-section {
      margin-top: rem-calc(5);
      display: flex;
      gap: rem-calc(20);
      width: rem-calc(400);
      justify-content: inherit;
      @media screen and (max-width: 1024px) {
        width: 90%;
      }
    }
    .section-card {
      margin: rem-calc(16 0);
      border-radius: rem-calc(4);
      box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
      max-height: calc(100vh - 420px);
      max-height: -webkit-calc(100vh - 420px);
      overflow-y: auto;
      overflow-x: hidden;
      .student-table {
        border-collapse: collapse;
        width: 100%;
        .dataheader {
          position: sticky;
          top: 0;
          z-index: 2;
        }
        .dataheaderrow {
          height: rem-calc(46);
          background-color: rgba(233, 231, 236, 1);
          th:first-child {
            padding-left: rem-calc(24);
          }
          .title {
            font-size: rem-calc(12);
            font-weight: 700;
            line-height: rem-calc(15);
            color: rgba(74, 74, 74, 1);
            text-transform: uppercase;
          }
        }
        .databody tr {
          font-size: rem-calc(14);
          font-weight: 400;
          line-height: rem-calc(22);
          color: rgba(74, 74, 74, 1);
          height: rem-calc(60);
          border-bottom: 2px solid #f4f3f6;
          td:first-child {
            padding-left: rem-calc(24);
          }
          &:nth-child(even) {
            background-color: rgba(250, 250, 250, 1);
          }
          .section-title {
            cursor: pointer;
          }
        }
      }
    }
    .empty-list {
      margin: rem-calc(50 0);
    }
  }
  .footer-section {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem-calc(20 30);
    gap: rem-calc(97);
    border-top: 1px solid rgba(210, 208, 212, 1);
    background-color: #ffffff;
    .info-section {
      display: flex;
      gap: rem-calc(15);
      .count-text {
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(22);
        font-style: italic;
        color: rgba(74, 74, 74, 1);
        &.error {
          color: rgb(192, 57, 43);
        }
      }
      .show-selected-section {
        position: relative;
        display: flex;
        gap: rem-calc(10);
        .show-selected-text {
          font-size: rem-calc(14);
          font-weight: 400;
          line-height: rem-calc(22);
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }
}

#requirement-flyout {
  .content-section {
    width: 100%;
    padding: rem-calc(24 30);
    .select-section {
      display: flex;
      align-items: center;
      gap: rem-calc(4);
      .select-section-text {
        font-size: rem-calc(16);
        font-weight: 500;
        line-height: rem-calc(20);
        color: rgba(0, 0, 0, 1);
        &.error {
          color: rgb(192, 57, 43);
        }
      }
      .required-text {
        color: rgb(192, 57, 43);
        font-size: rem-calc(14);
      }
    }
    .error-text {
      color: rgba(192, 57, 43, 1);
      font-size: rem-calc(14);
      font-style: italic;
      font-weight: 400;
      line-height: rem-calc(22);
    }
    .search-section {
      margin-top: rem-calc(5);
      display: flex;
      gap: rem-calc(20);
      width: rem-calc(400);
      justify-content: inherit;
      @media screen and (max-width: 1024px) {
        width: 90%;
      }
    }
    .section-card {
      margin-top: rem-calc(20);
      border-radius: rem-calc(4);
      box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
      max-height: calc(100vh - 350px);
      max-height: -webkit-calc(100vh - 380px);
      overflow-y: auto;
      overflow-x: hidden;
      .heading {
        position: sticky;
        top: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        gap: rem-calc(10);
        padding: rem-calc(12);
        background-color: rgba(233, 231, 236, 1);
        .heading-checkbox {
          position: relative;
        }
        .title {
          font-size: rem-calc(14);
          font-weight: 400;
          line-height: rem-calc(22);
          color: rgba(74, 74, 74, 1);
        }
      }
      .section-groups {
        list-style: none;
        padding: 0;
        margin: 0;
        .section-item {
          padding: rem-calc(18 12);
          display: flex;
          gap: rem-calc(12);
          align-items: center;
          &:nth-child(even) {
            background-color: rgba(250, 250, 250, 1);
          }
          .checkbox-option {
            position: relative;
          }
          .section-title {
            font-size: rem-calc(14);
            font-weight: 400;
            line-height: rem-calc(22);
            color: rgba(74, 74, 74, 1);
          }
        }
      }
    }
    .empty-list {
      margin: rem-calc(50 0);
    }
  }
  .footer-section {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem-calc(20 30);
    gap: rem-calc(97);
    border-top: 1px solid rgba(210, 208, 212, 1);
    background-color: #ffffff;
    .info-section {
      display: flex;
      gap: rem-calc(15);
      .count-text {
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(22);
        font-style: italic;
        color: rgba(74, 74, 74, 1);
        &.error {
          color: rgb(192, 57, 43);
        }
      }
      .show-selected-section {
        position: relative;
        display: flex;
        gap: rem-calc(10);
        .show-selected-text {
          font-size: rem-calc(14);
          font-weight: 400;
          line-height: rem-calc(22);
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }
}
.sideFlyout {
  .container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: unset;
    left: unset;
    background: rgb(255, 255, 255);
    box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.09);
    border-radius: 0;
    @media screen and (min-width: 1300px) {
      left: unset;
      width: rem-calc(720);
      max-width: unset;
    }
    .header-section {
      display: flex;
      align-items: center;
      width: 100%;
      padding: rem-calc(20 30);
      gap: rem-calc(16);
      border-bottom: 1px solid rgba(210, 208, 212, 1);
      .header-title {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: rem-calc(4);
        color: rgb(74, 74, 74);
        .modal-header {
          font-size: rem-calc(14);
          font-weight: 300;
          text-transform: uppercase;
          line-height: rem-calc(17);
          margin: 0;
        }
        .modal-subHeader {
          font-size: rem-calc(18);
          font-weight: 600;
          line-height: rem-calc(22);
          margin: 0;
        }
      }
      .close-btn {
        margin: 0;
      }
    }
    .required-info-msg {
      padding: rem-calc(24 30 0 30);
      .modal-info-text {
        font-weight: 400;
        font-size: rem-calc(14);
        line-height: rem-calc(22);
        color: rgba(0, 0, 0, 1);
      }
      .required-fields {
        display: flex;
        align-items: center;
        gap: rem-calc(3);
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(22);
        margin-top: rem-calc(9);
        &.center {
          justify-content: center;
        }
        .reqd {
          color: rgb(192, 57, 43);
          font-size: rem-calc(14);
        }
      }
    }
  }
}

.lp-export-message-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .lp-primary-text {
    font-size: rem-calc(24);
    font-weight: 600;
    line-height: rem-calc(29);
    color: rgba(0, 0, 0, 1);
    margin-top: rem-calc(32);
    &.lp-error {
      color: rgba(192, 57, 43, 1);
    }
  }
  .lp-secondary-text-container {
    width: rem-calc(480);
    margin-top: rem-calc(16);
    line-height: rem-calc(22);
    color: rgba(0, 0, 0, 1);
    font-size: rem-calc(14);
    text-align: center;
    &.lp-error-text {
      width: rem-calc(453);
    }
    .lp-secondary-text {
      font-weight: 400;
    }
    .lp-help-text {
      font-weight: 600;
    }
  }
  #lp-cancel-export {
    margin-top: rem-calc(20);
  }
}
