@import "../../common-style/css/settings";
@import "../../common-style/css/mixins";
@import "../../common-style/css/functions";
@import "../../common-style/css/inter";
@import "../../common-style/css/normalize";
@include normalize();
@import "../../common-style/css/base";
@import "../../common-style/css/objects";
@import "../../common-style/css/components";

.prog-app-landing-page {
  .heading-card {
    border-radius: 0;
    color: rgb(74, 74, 74);
    padding: rem-calc(40 44 24 30);

    .heading-title {
      font-size: rem-calc(30);
      font-weight: bold;
      letter-spacing: 0;
      line-height: rem-calc(40);
    }

    .heading-description {
      font-size: rem-calc(14);
      font-weight: normal;
      letter-spacing: 0;
      line-height: rem-calc(20);
      max-width: 100%;
      margin-top: rem-calc(4);
    }

    &.student-heading-card {
      box-shadow: none;
      #submit-application {
        margin-bottom: rem-calc(10);
      }
    }
  }
  .applications-sub-tab,
  .app-template-sub-tab {
    background: rgb(250, 250, 250);

    .applications-label,
    .app-template-label {
      margin: rem-calc(18 0);
      color: rgb(74, 74, 74);
      font-size: rem-calc(22);
      font-weight: normal;
      letter-spacing: 0;
      line-height: rem-calc(30);
    }
    .applications-label {
      margin-left: rem-calc(32);
    }
    .template-subtab-heading {
      margin: rem-calc(6 32);
    }
    .search-filter-section {
      margin: rem-calc(0 0 24 32);
      width: 86%;
      justify-content: inherit;
      @media screen and (max-width: 1024px) {
        width: 90%;
      }
      .search-bar {
        width: 44%;
        background: rgb(255, 255, 255);
      }
      .filter-by {
        width: 26%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-left: rem-calc(12);
        @media screen and (max-width: 1024px) {
          width: 27%;
          margin-left: rem-calc(4);
        }
        .filter-select {
          background: rgb(255, 255, 255);
          width: rem-calc(160);
          &#filter-cohort-status {
            width: rem-calc(164);
          }
        }
        .sort-filter {
          width: rem-calc(300);
        }
      }
      .filter-label {
        font-size: rem-calc(14);
        font-weight: 600;
      }
    }
  }
  .empty-applications-view,
  .empty-app-template-view {
    margin-top: rem-calc(12);
    text-align: center;
    color: rgb(74, 74, 74);
    .app-icon {
      font-size: rem-calc(40);
      &.student-app-icon {
        color: rgb(107, 107, 107);
      }
    }
    .no-apps-label,
    .no-app-template-label {
      margin-top: rem-calc(8);
      font-size: rem-calc(24);
      font-weight: normal;
    }
    .no-apps-info,
    .no-app-template-info {
      margin-top: rem-calc(4);
      margin-bottom: rem-calc(20);
      font-size: rem-calc(14);
      font-weight: normal;
      line-height: rem-calc(20);
    }
    .no-apps-text {
      margin: rem-calc(12 0 24 0);
    }
    &.student-empty-app-view {
      padding: rem-calc(125 0 110 0);
    }
  }
  .app-template-card {
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
    border-radius: rem-calc(5);
    margin: rem-calc(6 32);

    .data-table .dataheader .dataheaderrow {
      background: rgb(238, 237, 244);
      border-radius: rem-calc(5 5 0 0);
      height: rem-calc(40);
      width: 100%;
      display: table;
    }
    .empty-app-template-view {
      margin-top: rem-calc(100);
      margin-bottom: rem-calc(80);
    }

    .template-list-table {
      .dataheader .dataheaderrow {
        display: table-row;
      }

      .dataheaderrow th {
        padding-left: rem-calc(24);
        font-size: rem-calc(12);
        text-transform: uppercase;
        text-align: left;
        background-color: rgb(238, 237, 244);

        &.template-title-header {
          border-radius: rem-calc(5 0 0 0);
          width: 30%;
        }
        &.template-organization-header {
          width: 20%;
        }
        &.template-lastUpdated-header {
          width: 25%;
        }
        &.template-status-header {
          border-radius: rem-calc(0 5 0 0);
          width: 25%;
        }
        .user-is-tabbing &:focus {
          box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
            inset 0 2px 2px 0 rgb(0 0 0 / 10%), inset 0 0 4px 3px #61279e;
          outline: none;
        }
      }

      .databody .datarow {
        border-bottom: 1px solid rgb(236, 238, 243);

        .td-content {
          word-break: break-all;
          min-width: 0%;
          padding: rem-calc(14 24);
          font-size: rem-calc(14);
          letter-spacing: 0;
          line-height: rem-calc(20);
        }
        .template-name {
          font-weight: bold;
        }
        .template-organization-name {
          color: rgb(74, 74, 74);
          font-weight: normal;
        }
        .template-updated {
          color: rgb(107, 107, 107);
          font-weight: normal;
          font-style: italic;
        }
        .template-status {
          font-weight: 600;
          text-transform: uppercase;
          .template-action-menu {
            text-transform: none;
          }
          .Draft {
            background: rgb(242, 240, 243);
            border-radius: rem-calc(5);
            color: rgb(58, 58, 58);
            letter-spacing: rem-calc(0.3);
            padding: rem-calc(4 8);
          }
          .Published {
            background: rgba(8, 128, 0, 0.15);
            border-radius: rem-calc(5);
            color: rgb(7, 107, 0);
            letter-spacing: 0;
            padding: rem-calc(4 8);
          }
          .Archived {
            background: rgb(238, 237, 244);
            border-radius: rem-calc(5);
            color: rgb(87, 81, 149);
            letter-spacing: 0;
            padding: rem-calc(4 8);
          }
          @media screen and (max-width: 1513px) {
            margin: rem-calc(10 0);
          }
        }
      }
    }
    tbody tr {
      background: rgb(250, 250, 250);
      &:nth-child(even) {
        background-color: rgb(255, 255, 255);
      }
    }
    tbody:last-child tr {
      border-radius: rem-calc(0 0 5 5);
    }
    @media screen and (max-width: 800px) {
      .data-table .dataheader {
        display: block;
      }
      .template-list-table {
        .dataheader,
        .datarow {
          display: revert;
        }
        .template-status {
          margin-top: rem-calc(28);
        }
      }
    }
  }
}

.application-card-title {
  color: rgb(87, 81, 149);
  font-size: rem-calc(18);
  font-weight: 600;
  letter-spacing: 0;
  line-height: rem-calc(26);
  text-decoration: none;
  &:hover:not(.button):not([role]) {
    padding-bottom: 0;
  }
}

.application-cards {
  margin: rem-calc(24 324 30 30);
  .student-application-card {
    max-width: initial;
    width: 100%;
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
    border-radius: rem-calc(5);
    margin-bottom: rem-calc(20);
    &.fixed-card {
      height: rem-calc(286);
    }
    @media screen and (max-width: 1180px) {
      &.fixed-card {
        height: rem-calc(334);
      }
    }
    .card-body {
      padding-right: rem-calc(20);
    }
    .status-timestamp {
      color: rgb(107, 107, 107);
      font-size: rem-calc(14);
      font-weight: normal;
      font-style: italic;
      letter-spacing: 0;
      line-height: rem-calc(20);
      padding-top: rem-calc(5);
    }
    .status-icon {
      margin-top: rem-calc(20);
      color: rgb(127, 151, 173);
      font-size: rem-calc(40);
      text-align: center;
      letter-spacing: 0;
      &.mdi-flag-variant {
        color: rgb(107, 107, 107);
      }
      &.mdi-seal {
        color: rgb(25, 161, 169);
      }
      &.mdi-progress-check {
        color: rgb(87, 81, 149);
      }
      &.mdi-alert-octagram {
        color: rgb(255, 95, 78);
      }
    }
    .status-text {
      padding: rem-calc(16 0 0 16);
      color: rgb(74, 74, 74);
      font-size: rem-calc(14);
      font-weight: normal;
      letter-spacing: 0;
      line-height: rem-calc(20);
    }
    .status-content {
      justify-content: flex-start;
    }
    .card-btn {
      display: flex;
      justify-content: center;
      margin: rem-calc(14 0);
    }
  }
  @media screen and (max-width: 1440px) {
    margin-right: rem-calc(300);
  }
  @media screen and (max-width: 1280px) {
    .label-stamp {
      flex-direction: column-reverse;
      align-items: flex-start;
      .status-timestamp {
        padding-top: rem-calc(12);
      }
    }
  }
  @media screen and (max-width: 1024px) {
    margin-right: rem-calc(200);
    .wm-grid .wm-grid-col-md-6 {
      -webkit-flex-basis: 50%;
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      max-width: 50%;
    }
  }
  @media screen and (max-width: 700px) {
    margin-right: rem-calc(16);
  }
}

.application-template-view {
  .header-title {
    color: rgb(74, 74, 74);
    font-size: rem-calc(24);
    font-weight: 600;
    letter-spacing: 0;
    line-height: rem-calc(32);
    margin: 0;
  }
  .create-template-header {
    position: relative;
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    z-index: 2;
  }
  .template-subheader {
    position: relative;
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
    border-radius: rem-calc(5);
    z-index: 1;

    .template-fields {
      display: flex;
      align-items: center;
    }

    .template-title,
    .select-org {
      margin-left: rem-calc(6);
      width: rem-calc(550);
      max-width: rem-calc(550);
      border-radius: rem-calc(3);
      position: relative;
    }
    .select-org {
      margin-left: rem-calc(16);
      padding-top: rem-calc(1.2);
    }
    .preview-template-btn {
      align-self: center;
      margin: rem-calc(18 0 0 24);
    }
  }
  .main-template-panel {
    text-align: center;
    margin: rem-calc(28);
    .standard-components-card {
      margin-bottom: rem-calc(12);
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
      margin: auto;
      padding: 0;
      max-width: 53%;
      width: 53%;
      .card-header {
        display: flex;
        justify-content: flex-start;
        padding: 0;
        .header-body {
          padding-left: rem-calc(14);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .header-info {
            margin: 0;
            text-align: left;
            max-width: rem-calc(576);
          }
        }
        .drag-svg-section {
          padding: rem-calc(40 28);
          display: flex;
          align-items: center;
          &.enable:focus {
            outline: none;
            border: none;
            background: #413c70;
            path {
              fill: #fff;
            }
          }
        }
      }
      .card-body {
        padding: rem-calc(15 30 24);
        .toggle-btn {
          display: flex;
          align-items: flex-end;
          width: 100%;
          &.show {
            justify-content: flex-end;
          }
          &.hide {
            justify-content: space-between;
            flex-direction: column;
          }
        }
        .composite-body {
          padding: rem-calc(15 0 24 60);
          width: 100%;
          text-align: left;
          .composite-components-list {
            border-bottom: 1px solid rgb(222, 222, 222);
            margin-left: rem-calc(-56);
            .composite-components-item {
              width: 63%;
              padding-bottom: rem-calc(20);
              margin-left: rem-calc(56);
            }
          }
        }
      }
    }
    .insert-text {
      color: rgb(74, 74, 74);
      font-size: rem-calc(24);
      font-weight: normal;
      text-align: center;
      letter-spacing: 0;
      margin: rem-calc(36 0 28 0);
    }
    .insert-component-btn {
      margin-bottom: rem-calc(96);
    }
    &.list-components {
      padding: 0;
      text-align: left;
      .components-container {
        padding: rem-calc(24 0 12 0);
        width: 53%;
        max-width: 53%;
        margin: auto;
        .draggable-element {
          &:hover {
            cursor: pointer;
            background: rgb(232, 231, 244);
          }
          &.drag-started:hover {
            background: #ffffff !important;
          }
          &:active {
            cursor: grabbing;
          }
          &.keyboard-grabbed {
            margin: rem-calc(0 6);
            box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%),
              0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
          }
        }
        .standard-components-card {
          margin-bottom: rem-calc(12);
          width: 100%;
          max-width: 100%;
        }
      }
      .component-card {
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
        border-radius: rem-calc(4);
        margin-bottom: rem-calc(12);
        padding: 0;
        .drag-svg-section {
          padding: rem-calc(40 28);
          display: flex;
          align-items: center;
          &:focus {
            outline: none;
            border: none;
            background: #413c70;
            path {
              fill: #fff;
            }
          }
        }
        .component-body {
          padding: rem-calc(25 0 23 28);
          width: 78%;
          &.comp-text,
          &.comp-section_header,
          &.comp-link_resource {
            padding: rem-calc(12 0 12 28);
            width: 100%;
            display: flex;
            align-items: center;
          }
          &.comp-file_upload,
          &.comp-acknowledgement_checkbox {
            width: 100%;
          }
          &.comp-section_header {
            .section-header-style {
              font-size: rem-calc(22);
              font-weight: normal;
              letter-spacing: 0;
              line-height: rem-calc(30);
              margin: 0;
            }
          }
          &.comp-large_text_area {
            .textfieldlabel {
              padding: 0;
            }
            .textfieldinput {
              margin: 0;
              width: 100%;
              resize: none;
              min-height: rem-calc(108);
              border-radius: rem-calc(3);
              box-sizing: border-box;
              background-color: rgb(240, 240, 240);
              border: 1px solid rgb(138, 138, 138);
              color: rgb(115, 115, 115);
            }
          }
        }
        .component-update-btns {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          padding: rem-calc(13 15 10 53);
          .edit-component-btn {
            margin-bottom: rem-calc(8);
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .components-container {
      width: 85% !important;
      max-width: 85% !important;
    }
    .component-card {
      display: flex;
      align-items: center;
      .component-body {
        padding: rem-calc(25 0 23 10) !important;
      }
      .component-update-btns {
        display: flex;
        flex-direction: row !important;
        align-items: flex-end;
        padding: rem-calc(13 15 10 20) !important;
        .edit-component-btn {
          margin-bottom: rem-calc(0) !important;
        }
      }
    }
  }

  @media screen and (max-width: 1170px) {
    .template-title,
    .select-org {
      max-width: rem-calc(340) !important;
      width: rem-calc(340) !important;
    }
  }

  @media screen and (max-width: 750px) {
    .main-template-panel {
      // padding: rem-calc(100 10 190 10);
    }
    .template-title,
    .select-org {
      max-width: max-content !important;
      width: max-content !important;
    }
    .components-container {
      width: 75% !important;
      max-width: 75% !important;
    }
  }
}

#insert-component-flyout {
  &.half-flyout {
    > .container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: unset;
      left: unset;
      background: rgb(255, 255, 255);
      box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.09);
      border-radius: 0;
      @media screen and (min-width: 600px) {
        left: unset;
        width: rem-calc(500);
        max-width: unset;
      }

      .chooseComponent-header,
      .componentSettings-header,
      .editComponent-header {
        box-shadow: none;
        padding: rem-calc(30 34);
        border-bottom: 1px solid rgb(231, 231, 231);
      }

      .componentSettings-header {
        padding: rem-calc(20 30 16 30);
      }

      .editComponent-header {
        padding: rem-calc(32 30 16 30);
        display: block;
      }

      .flyout-header-title {
        color: rgb(74, 74, 74);
        font-size: rem-calc(18);
        font-weight: 600;
        letter-spacing: 0;
        line-height: rem-calc(26);
        margin: 0;
      }

      .go-back-link,
      .required-fields {
        color: rgb(74, 74, 74);
        font-size: rem-calc(14);
        font-weight: normal;
        letter-spacing: 0;
        line-height: rem-calc(20);
      }

      .main-content-body {
        &.-height {
          height: calc(100vh - 193px);
          height: -webkit-calc(100vh - 193px);
        }
        padding: rem-calc(18 32);
        overflow: auto;

        .resource-content-area,
        .student-input-area {
          margin-bottom: rem-calc(18);

          .section-label {
            align-items: baseline;
            display: flex;
            gap: rem-calc(8);
          }

          .item {
            @include flex(1);
            @include border-radius(5px);
            @include box-shadow(0 2px 10px 0 rgba(0, 0, 0, 0.09));
            font-weight: normal;
            border: none;
            transition: none;
            color: $body-text-color;
            cursor: pointer;
            text-transform: none;
            text-align: left;
            margin-bottom: rem-calc(9);
            width: 100%;
            height: rem-calc(40);
            background: rgb(255, 255, 255);

            .item-container {
              align-items: center;

              .item-icon,
              .item-name {
                color: rgb(87, 81, 149);
                font-size: rem-calc(24);
                font-weight: bold;
                letter-spacing: 0;
                line-height: rem-calc(20);
              }

              .item-name {
                font-size: rem-calc(14);
                margin-left: rem-calc(8);
              }
            }

            .user-is-tabbing &:focus {
              box-shadow: 0 2px 2px 0 rgba(244, 243, 246, 0.2),
                0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 0 4px 3px #61279e;
            }

            &:hover {
              background: rgb(233, 231, 236);
              @include box-shadow(0 2px 10px 0 rgba(0, 0, 0, 0.5));
              @include border-radius(5px);
            }
          }
        }
        .resource-content-title,
        .student-input-title {
          color: rgb(74, 74, 74);
          font-size: rem-calc(16);
          font-weight: 500;
          letter-spacing: 0;
          line-height: rem-calc(24);
          margin: rem-calc(0 0 12 0);
        }
        .component-info {
          color: rgb(74, 74, 74);
          padding: rem-calc(6 0 24 0);
          border-bottom: 1px solid rgb(231, 231, 231);
          .component-category {
            font-size: rem-calc(14);
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: 0;
            line-height: rem-calc(20);
            margin: 0;
          }
          .component-name {
            font-size: rem-calc(18);
            font-weight: 600;
            letter-spacing: 0;
            line-height: rem-calc(26);
            margin: 0;
          }
          .component-info-text {
            font-size: rem-calc(14);
            font-weight: normal;
            font-style: italic;
            letter-spacing: 0;
            margin: 0;
          }
        }
        .text-area-field {
          padding-top: rem-calc(24);
          .textfieldlabel {
            color: rgb(74, 74, 74);
            font-size: rem-calc(14);
            font-weight: 600;
            letter-spacing: 0;
            &.alert-label,
            .alert-icon {
              color: rgb(192, 57, 43);
            }
            .alert-icon,
            .reqd {
              margin-left: rem-calc(5);
            }
          }
          .textfield {
            .alert-box {
              -webkit-box-shadow: 0 0 0 1px rgb(192, 57, 43);
              -moz-box-shadow: 0 0 0 1px rgb(192, 57, 43);
              box-shadow: 0 0 0 1px rgb(192, 57, 43);
              border-color: rgb(192, 57, 43);
            }
            &.is-focused .charcount.-textarea {
              padding-bottom: rem-calc(28);
            }
          }
          textarea.textfieldinput {
            resize: none;
            min-height: rem-calc(240);
            overflow: auto;
            box-sizing: border-box;
            scroll-behavior: smooth;
            border-radius: rem-calc(3);
          }
          .text-error-message {
            font-size: rem-calc(14);
            letter-spacing: 0;
            font-style: italic;
            color: rgb(192, 57, 43);
            display: block;
            margin-top: rem-calc(5);
          }
          &.textarea-acknowledgement_checkbox {
            padding-top: 0;
            textarea.textfieldinput {
              min-height: rem-calc(120);
            }
          }
          .-info {
            font-style: italic;
          }
        }
        .small-text-type-select {
          width: 67%;
        }
        .component-input {
          padding-top: rem-calc(24);
          .checkbox-option {
            padding-bottom: rem-calc(30);
          }
        }
        .comp-info-text {
          color: rgb(74, 74, 74);
          font-size: rem-calc(14);
          font-weight: normal;
          font-style: italic;
          letter-spacing: 0;
          padding-bottom: rem-calc(12);
        }
        .word-limit-field,
        .max-files-field {
          .charcount input.textfieldinput:focus {
            padding-right: rem-calc(16);
          }
        }
        .max-files-field .charcount {
          width: 48%;
        }
        .resource-link-field {
          margin-bottom: rem-calc(24);
          .charcount input.textfieldinput:focus {
            padding-right: rem-calc(14);
          }
        }
        .alert-label,
        .alert-icon {
          color: rgb(192, 57, 43);
        }
        .alert-icon {
          margin-left: rem-calc(5);
        }
        .attach-files-uploader {
          margin: rem-calc(32 0 28 0);
        }
      }
      .flyout-footer {
        background: rgb(255, 255, 255);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
        flex-direction: row-reverse;
        position: absolute;
        bottom: 0;
        width: 100%;
        #back-button {
          margin: 0;
        }
      }
    }
  }
}

.txt-field-wrapper .textfield {
  margin-top: rem-calc(2);
}

.distribute-application-view {
  .distribute-application-header {
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    color: rgb(74, 74, 74);
    position: relative;
    z-index: 15;
    .header-title {
      font-size: rem-calc(24);
      font-weight: 600;
      letter-spacing: 0;
      line-height: rem-calc(32);
      margin: 0;
    }
    .required-fields {
      margin: rem-calc(0 0 -7 0);
    }
  }
  .immersive-container {
    padding-bottom: rem-calc(64);
  }
  .app-details-card,
  .build-app-card,
  .schedule-app-card,
  .external-app-settings-card {
    margin-top: rem-calc(24);
  }
  .app-details-card {
    .reqd {
      font-size: rem-calc(14);
    }
    #term-dropdown-admission-term {
      margin-top: rem-calc(16);
    }
    .description-field {
      margin-top: rem-calc(20);
      .textfield {
        &.is-focused .charcount.-textarea {
          padding-bottom: rem-calc(32);
        }
        textarea.textfieldinput {
          resize: none;
          min-height: rem-calc(120);
          overflow: auto;
          border-radius: rem-calc(3);
          box-sizing: border-box;
        }
      }
    }
    .application-url-field {
      margin-top: rem-calc(16);
      display: flex;
      justify-content: space-between;
      .txt-field-wrapper {
        width: 92%;
        .textfield .textfieldinput:disabled {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .copy-url {
        margin-top: rem-calc(22);
        margin-left: rem-calc(10);
      }
      &.url-populated {
        .textfield .textfieldinput:disabled {
          border: none;
        }
      }
      &.url-blank {
        flex-direction: column;
        .app-url-info {
          font-style: italic;
          margin: 0;
        }
      }
    }
  }
  .build-app-card {
    .card-body {
      padding-bottom: 0;
    }
    .build-app-section {
      padding-bottom: rem-calc(24);
      border-bottom: 1px solid rgb(222, 222, 222);
      .build-app-labels {
        margin: 0;
        color: rgb(74, 74, 74);
        font-size: rem-calc(16);
        font-weight: 500;
        letter-spacing: 0;
        line-height: rem-calc(24);
      }
      .build-app-infoText {
        max-width: rem-calc(565);
        margin: rem-calc(8 0 16 0);
        color: rgb(74, 74, 74);
        font-size: rem-calc(14);
        font-weight: normal;
        letter-spacing: 0;
        line-height: rem-calc(20);
      }
      &.template-section {
        padding-top: rem-calc(24);
        &.external-templates {
          padding-top: 0;
        }
        .align-org-msg {
          color: rgb(74, 74, 74);
          font-size: rem-calc(14);
          font-weight: normal;
          font-style: italic;
          letter-spacing: 0;
          margin: rem-calc(12 0);
        }
        .template-select {
          width: 89%;
          min-width: 89%;
          margin-bottom: rem-calc(22);
        }
        .remove-template-btn {
          margin-bottom: rem-calc(28);
        }
        .hide-add-btn {
          margin-bottom: auto;
        }
        #add-template-btn {
          margin-left: 0;
        }
      }
    }
    .disabled-text-msg {
      font-style: italic;
      margin: 0;
      margin-top: rem-calc(-14);
      display: flex;
      .mdi-lock {
        margin-right: rem-calc(8);
      }
      &.org-select {
        margin-top: rem-calc(8);
      }
    }
    .preview-application-section {
      display: flex;
      justify-content: flex-end;
      padding-top: rem-calc(18);
      padding-bottom: rem-calc(21);
    }
  }
  .schedule-app-card {
    .schedule-card-infotext {
      margin: 0;
      max-width: 100%;
    }
    #schedule-app-btn {
      margin-top: rem-calc(24);
    }
    .schedule-label {
      font-size: rem-calc(14);
      font-weight: 300;
      text-transform: uppercase;
      line-height: rem-calc(20);
      margin: rem-calc(16 0 8 0);
    }
  }
  .external-app-settings-card {
    .external-app-settings-header {
      margin: 0;
      font-size: rem-calc(16);
      font-weight: 500;
      line-height: rem-calc(24);
      margin-bottom: rem-calc(6);
    }
    .toggle-switch input + .switchtoggle {
      width: rem-calc(88);
    }
    .toggle-switch input:checked + .switchtoggle:after {
      left: rem-calc(60);
    }
    .toggle-text {
      text-transform: uppercase;
      position: absolute;
      color: #4a4a4a;
      font-size: rem-calc(14);
      font-weight: 600;
      line-height: rem-calc(24);
      right: rem-calc(6);
    }
    .toggle-text.on {
      left: rem-calc(14);
      right: auto;
    }
    .external-app-settings-info {
      font-style: italic;
      font-weight: 500;
      margin: 0;
      padding-top: rem-calc(4);
    }
  }
  .copy-url-modal-body {
    .app-url {
      max-width: 90%;
      margin: rem-calc(18 0);
      color: rgb(107, 107, 107);
      font-weight: bold;
    }
    .copy-url-info {
      max-width: 87%;
      margin: 0;
      font-style: italic;
    }
  }
}

.schedule-card {
  display: flex;
  align-items: stretch;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
  border-radius: rem-calc(5);
  padding: rem-calc(14);
  margin-bottom: rem-calc(8);
  .schedule-details {
    width: 80%;
  }
  .schedule-title {
    font-size: rem-calc(16);
    font-weight: 500;
    line-height: rem-calc(24);
    margin: 0;
    padding-bottom: rem-calc(2);
  }
  .schedule-date {
    margin: 0;
    padding-bottom: rem-calc(8);
    &.-close {
      padding: 0;
    }
    .schedule-date-label,
    .schedule-date-value {
      font-size: rem-calc(14);
      font-weight: 600;
      line-height: rem-calc(20);
    }
    .schedule-date-value {
      font-weight: normal;
      .close-now-btn {
        margin-left: rem-calc(8);
      }
    }
  }
}

.card-component {
  max-width: rem-calc(614);
  width: rem-calc(614);
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
  border-radius: rem-calc(5);
  margin: auto;
  padding: 0;
  .user-is-tabbing &:focus {
    box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%), 0 2px 2px 0 rgb(0 0 0 / 10%),
      0 0 4px 3px #61279e;
    outline: none;
  }
  .card-header {
    border-bottom: 1px solid rgb(46, 27, 70);
    padding: rem-calc(20 24);
    .header-title {
      margin: 0;
      color: rgb(74, 74, 74);
      font-size: rem-calc(18);
      font-weight: 600;
      letter-spacing: 0;
      line-height: rem-calc(26);
    }
  }
  .card-body {
    padding: rem-calc(15 40 30 24);
  }
  @media screen and (max-width: 700px) {
    max-width: 80%;
    width: 80%;
  }
}

.applications-sub-tab .application-card {
  width: 67%;
  max-width: 67%;
  margin: rem-calc(0 0 26 32);
  @media screen and (max-width: 1024px) {
    width: 82%;
    max-width: 82%;
  }
  .header-title {
    .subheader {
      color: rgb(74, 74, 74);
      font-size: rem-calc(14);
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0;
      line-height: rem-calc(20);
    }
    .header {
      color: rgb(87, 81, 149);
      font-size: rem-calc(18);
      font-weight: 600;
      letter-spacing: 0;
      line-height: rem-calc(26);
    }
  }
  .status {
    border-radius: rem-calc(5);
    font-size: rem-calc(14);
    font-weight: 600;
    letter-spacing: 0;
    text-transform: uppercase;
    margin-right: rem-calc(16);
    &.Open {
      background: rgba(8, 128, 0, 0.15);
      color: rgb(7, 107, 0);
      padding: rem-calc(4 6);
    }
    &.Closed {
      background: rgb(255, 240, 238);
      color: rgb(191, 60, 60);
      padding: rem-calc(4 10);
    }
  }
  .application-card-container {
    padding-bottom: rem-calc(14);
    margin-bottom: rem-calc(16);
    border-bottom: 1px solid rgb(222, 222, 222);
    .other-status-div {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      .status-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: rem-calc(32);
        .status-symbol {
          font-size: rem-calc(32);
          line-height: rem-calc(32);
          padding-top: rem-calc(14);
          &.mdi-check-circle-outline {
            color: rgb(21, 134, 141);
          }
          &.mdi-progress-check {
            color: rgb(87, 81, 149);
          }
          &.mdi-cancel {
            color: rgb(192, 57, 43);
          }
        }
      }
    }
    .status-count {
      font-size: rem-calc(24);
      text-align: center;
    }
    .awaiting-review-div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-left: 1px solid rgb(222, 222, 222);
      padding-left: rem-calc(32);
      &.zero-count {
        justify-content: flex-start;
        padding-left: rem-calc(50);
      }
      .start-reviewing-btn {
        margin-top: rem-calc(10);
      }
    }
  }
  .timestamp-detail {
    .timestamp-label {
      font-size: rem-calc(14);
      font-weight: bold;
      line-height: rem-calc(20);
    }
    .last-update-submission {
      margin-left: rem-calc(20);
      color: rgb(107, 107, 107);
      font-style: italic;
      line-height: rem-calc(20);
    }
  }
  .application-audience {
    margin-left: rem-calc(20);
    font-size: rem-calc(14);
    line-height: rem-calc(20);
  }
  .card-body {
    padding: rem-calc(16 24);
  }
}

#preview-flyout {
  &.half-flyout {
    > .container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: unset;
      left: unset;
      background: rgb(255, 255, 255);
      box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.09);
      border-radius: 0;
      @media screen and (min-width: 1300px) {
        left: unset;
        width: rem-calc(1272);
        max-width: unset;
        &.cohort-source,
        &.view-template-source {
          width: 100%;
        }
      }
      .preview-template-header {
        box-shadow: none;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        border: none;
        .header-section {
          width: 100%;
        }
        .close-btn {
          padding: rem-calc(20 30);
          margin: 0;
        }
        .header-section {
          width: 100%;
        }
        .header-title {
          color: rgb(74, 74, 74);
          letter-spacing: 0;
          .preview-template-label {
            font-size: rem-calc(14);
            font-weight: 300;
            text-transform: uppercase;
            line-height: rem-calc(20);
            margin: 0;
          }
          .-label {
            margin-bottom: rem-calc(4);
          }
          .preview-template-title {
            font-size: rem-calc(18);
            font-weight: 600;
            line-height: rem-calc(26);
            margin: 0;
          }
        }
        .btn-collection {
          margin-right: rem-calc(30);
        }
        .preview-info-content {
          border-top: 1px solid rgb(231, 231, 231);
          background: rgb(238, 237, 244);
          border-radius: 0px;
          width: 100%;
          text-align: center;
          padding: rem-calc(10);
          .preview-info-text {
            color: rgb(87, 81, 149);
            font-size: rem-calc(14);
            font-weight: 500;
            text-align: center;
            letter-spacing: 0;
            line-height: rem-calc(20);
          }
        }
      }
      .preview-template-body {
        padding: rem-calc(36 100);
        .template-item {
          padding-bottom: rem-calc(24);
          font-size: rem-calc(14);
          width: 54%;
          text-align: left;
          &.templateComp-default-field .composite-body {
            width: 88%;
            .composite-components-list > *:not(:last-child) {
              padding-bottom: rem-calc(24);
            }
          }
          &.templateComp-small-text-field .input-field {
            width: 88%;
          }
          &.templateComp-section-header-field h3 {
            font-size: rem-calc(22);
            font-weight: normal;
            letter-spacing: 0;
            line-height: rem-calc(30);
            margin: 0;
          }
          &.templateComp-large-text-area {
            .text-area-field {
              width: 88%;
            }
            .textfieldlabel {
              margin-bottom: rem-calc(3);
            }
            .textfieldinput {
              resize: none;
              min-height: rem-calc(120);
              overflow: auto;
              border-radius: rem-calc(3);
              box-sizing: border-box;
            }
            .charcount.-textarea .isfocused {
              padding-bottom: rem-calc(34);
              .textfieldinput {
                border: none;
                border-radius: none;
              }
            }
          }
          &.templateComp-dropdown,
          &.templateComp-link-input {
            width: 47.5%;
          }
          &.templateComp-file-upload {
            width: 55%;
          }
        }
        &.preview-app-body {
          .preview-app-title {
            font-size: rem-calc(24);
            font-weight: 600;
            line-height: rem-calc(32);
            margin: 0;
            margin-bottom: rem-calc(16);
          }
          .preview-app-empty-view {
            color: rgb(74, 74, 74);
            font-weight: normal;
            text-align: center;
            letter-spacing: 0;
            .empty-view-text {
              font-size: rem-calc(24);
              padding: rem-calc(4 0 8 0);
            }
            .empty-state-text {
              font-size: rem-calc(14);
              line-height: rem-calc(20);
            }
          }
          .division {
            margin: rem-calc(-11 -100 0 -100);
            padding-bottom: rem-calc(28);
            &.view-app-cohort {
              padding-bottom: 0;
            }
            .division-label {
              background: rgb(250, 250, 250);
              border-radius: 0;
              padding: rem-calc(12 0 11 100);
              .template-header {
                margin: 0;
                color: rgb(74, 74, 74);
                font-size: rem-calc(14);
                font-weight: 300;
                text-transform: uppercase;
                letter-spacing: 0;
                line-height: rem-calc(20);
              }
              .template-name-title {
                max-width: 100%;
                margin: 0;
                color: rgb(74, 74, 74);
                font-size: rem-calc(22);
                font-weight: normal;
                letter-spacing: 0;
                line-height: rem-calc(30);
              }
            }
            .division-body {
              padding-top: rem-calc(30);
              &.view-app-cohort {
                padding-top: rem-calc(14);
              }
              .template-item {
                padding-left: rem-calc(100);
                &.templateComp-dropdown,
                &.templateComp-link-input {
                  width: 48.5%;
                }
              }
            }
          }
        }
        @media screen and (max-width: 1300px) {
          .template-item {
            width: 90% !important;
          }
        }
      }
    }
  }
}

#view-submitted-app-flyout {
  &.half-flyout {
    > .container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: unset;
      left: unset;
      background: rgb(255, 255, 255);
      box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.09);
      border-radius: 0;
      @media screen and (min-width: 1300px) {
        left: unset;
        width: 50%;
        max-width: unset;
        &.cohort-source,
        &.view-template-source {
          width: 100%;
        }
      }
      .view-submitted-app-header {
        box-shadow: none;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        border: none;
        .close-btn {
          padding: rem-calc(20 30);
          margin: 0;
        }
        .header-section {
          width: 100%;
          border-bottom: 1px solid rgba(210, 208, 212, 1);
        }
        .header-title {
          color: rgb(74, 74, 74);
          letter-spacing: 0;
          padding: rem-calc(20 30);
          .view-submitted-app-label {
            font-size: rem-calc(14);
            font-weight: 300;
            text-transform: uppercase;
            line-height: rem-calc(20);
            margin: 0;
          }
          .-label {
            margin-bottom: rem-calc(4);
          }
          .view-submitted-app-title {
            font-size: rem-calc(18);
            font-weight: 600;
            line-height: rem-calc(26);
            margin: 0;
          }
          .view-submitted-app-sub-title {
            font-size: rem-calc(14);
            font-weight: 400;
            line-height: rem-calc(22);
            margin: 0;
          }
        }
      }
      .view-submitted-app-body {
        .-application-content {
          width: 100%;
          background: rgb(255, 255, 255);
          border-radius: rem-calc(4);
          padding: rem-calc(16 18);
          color: rgb(74, 74, 74);
          font-size: rem-calc(14);
          font-weight: normal;
          letter-spacing: 0;
          line-height: rem-calc(20);
          .user-is-tabbing &:focus {
            box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%),
              0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
            outline: none;
          }
          .-app-title {
            margin: 0;
            font-size: rem-calc(18);
            font-weight: 600;
            line-height: rem-calc(26);
            padding: rem-calc(8 16);
            background: rgb(244, 243, 246);
          }
          .-app-comp-list {
            .-compo-list-item {
              background: rgb(244, 243, 246);
              .-compo-resource-content {
                padding: rem-calc(20 16);
                &.section-header-field {
                  font-size: rem-calc(18);
                  font-weight: 600;
                  line-height: rem-calc(26);
                }
                &.text-field {
                  max-width: rem-calc(588);
                }
                &.attachment {
                  width: 100%;
                }
              }
              .-compo-student-response {
                padding: rem-calc(20 16);
                background: rgb(255, 255, 255);
                .-compo-label {
                  padding: rem-calc(0 0 6 0);
                  margin: 0;
                  background: rgb(255, 255, 255);
                  font-weight: 600;
                  line-height: rem-calc(16);
                  max-width: 100%;
                }
                &.def-comp-response {
                  padding: rem-calc(0 16);
                  .-compo-label {
                    padding: 0;
                  }
                }
                &.first-comp {
                  padding: rem-calc(20 16 0);
                  .-compo-label {
                    padding: 0;
                  }
                }
                .-compo-answer {
                  border-left: 3px solid rgb(233, 231, 236);
                  padding-left: rem-calc(20);
                  line-height: rem-calc(24);
                  width: 66.79%;
                  .response-option {
                    margin: 0;
                  }
                  &.file-upload {
                    width: 100%;
                    .no-pad {
                      padding: rem-calc(5 0);
                    }
                  }
                  &.acknowledgement-checkbox {
                    .label-display {
                      display: -webkit-inline-box;
                    }
                  }
                  &.checkboxes p {
                    margin: 0;
                    line-height: rem-calc(28);
                  }
                  &.default-field {
                    border-left: none;
                    padding-left: 0;
                    p {
                      margin: 0;
                      font-weight: 600;
                    }
                    .-compo-answer {
                      margin-bottom: rem-calc(20);
                    }
                    .similar-compo-answer {
                      margin-bottom: rem-calc(0);
                    }
                  }
                }
                .-compo-answer-text {
                  font-weight: normal;
                }
              }
              .similar-category {
                padding-bottom: 0;
              }
              .not-similar {
                padding: rem-calc(0 16);
                .-compo-label {
                  padding: 0;
                }
                padding-top: rem-calc(20);
              }
            }
          }
        }

        @media screen and (max-width: 1300px) {
          .template-item {
            width: 90% !important;
          }
        }
      }
    }
  }
}

.status-label {
  border-radius: rem-calc(5);
  font-size: rem-calc(14);
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding: rem-calc(4 8);
  letter-spacing: 0;
  &.draft {
    background: rgb(242, 240, 243);
    color: rgb(58, 58, 58);
    letter-spacing: rem-calc(0.3);
  }
  &.awaiting_review {
    background: rgb(219, 217, 239);
    color: rgb(83, 80, 121);
    padding: rem-calc(4 10);
  }
  &.denied {
    background: rgb(254, 237, 234);
    color: rgb(175, 41, 46);
  }
  &.accepted {
    background: rgb(229, 247, 248);
    color: rgb(13, 105, 110);
  }
  &.conditionally_accepted {
    background: rgb(46, 27, 70);
    color: rgb(233, 231, 236);
    letter-spacing: rem-calc(0.3);
  }
}

#choose-application-flyout {
  &.half-flyout {
    > .container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: unset;
      left: unset;
      background: rgb(255, 255, 255);
      box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.09);
      border-radius: 0;
      @media screen and (min-width: 800px) {
        left: unset;
        width: rem-calc(720);
        max-width: unset;
      }
      .choose-app-header {
        box-shadow: none;
        border-bottom: 1px solid rgb(231, 231, 231);
        .choose-app-header-info {
          color: rgb(74, 74, 74);
          font-size: rem-calc(14);
          font-weight: 300;
          text-transform: uppercase;
          letter-spacing: 0;
          line-height: rem-calc(20);
          margin: rem-calc(0 0 4 0);
        }
        .choose-app-title {
          color: rgb(74, 74, 74);
          font-size: rem-calc(18);
          font-weight: 600;
          letter-spacing: 0;
          line-height: rem-calc(26);
          margin: 0;
        }
      }
      .flyout-footer {
        background: rgb(255, 255, 255);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
        flex-direction: row-reverse;
        position: relative;
        bottom: 0;
        width: 100%;
        #flyout-footer-btn {
          margin-right: rem-calc(4);
        }
        &.close-footer,
        &.no-result-footer {
          position: absolute;
        }
      }
      .data-table .dataheaderrow th {
        background-color: rgb(238, 237, 244);
      }
      .flyout-search-filter {
        padding: rem-calc(21 42 24 29);
        .application-search {
          width: 51%;
          max-width: 51%;
        }
        .application-select {
          width: 46.7%;
          max-width: 46.7%;
        }
      }
      .data-table .dataheader .dataheaderrow {
        background: rgb(255, 255, 255);
        height: rem-calc(40);
        width: 100%;
        display: table;
      }
      .applications-list {
        position: relative;
      }
      .application-list-table {
        .dataheader .dataheaderrow {
          display: table-row;
        }
        thead,
        tbody {
          float: left;
          width: 100%;
        }

        .dataheaderrow th {
          padding-left: rem-calc(24);
          font-size: rem-calc(12);
          text-transform: uppercase;
          text-align: left;
          background-color: rgb(238, 237, 244);
          color: rgb(74, 74, 74);
          font-weight: bold;
          letter-spacing: 0;
          line-height: rem-calc(16);

          &.application-title-header {
            width: 33%;
          }
          &.application-organization-header {
            width: 22%;
          }
          &.application-status-header {
            width: 20%;
          }
          .user-is-tabbing &:focus {
            box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
              inset 0 2px 2px 0 rgb(0 0 0 / 10%), inset 0 0 4px 3px #61279e;
            outline: none;
          }
        }

        .databody .datarow {
          border-bottom: 1px solid rgb(222, 222, 222);
          .td-content {
            word-break: break-word;
            width: 40%;
            padding: rem-calc(14 30);
            font-size: rem-calc(14);
            letter-spacing: 0;
            line-height: rem-calc(20);
            color: rgb(74, 74, 74);
            font-weight: normal;
          }
          .application-name {
            .radio-cell {
              position: relative;
            }
            .application-radio-label {
              display: -webkit-inline-box;
              .application-title {
                font-weight: bold;
                min-width: rem-calc(210);
              }
              .application-description {
                font-style: italic;
                padding-top: rem-calc(4);
                font-weight: normal;
              }
            }
          }
          .org-name {
            width: 28%;
          }
          .application-status {
            width: 23.8%;
            .status-label {
              display: inline-block;
              word-break: normal;
              &.awaiting_review {
                width: 75%;
              }
            }
            @media screen and (max-width: 1513px) {
              margin: rem-calc(10 0);
            }
          }
        }
      }
      tbody {
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 300px);
        max-height: calc(100vh - 300px);
        max-height: -webkit-calc(100vh - 300px);
        vertical-align: top;
        tr {
          background-color: rgb(255, 255, 255);
        }
      }
      @media screen and (max-width: 800px) {
        .data-table .dataheader {
          display: block;
        }
        .application-list-table {
          .dataheader,
          .datarow {
            display: revert;
          }
        }
      }
    }
  }
}

.empty-application-flyout-view {
  margin-top: rem-calc(47);
  text-align: center;
  color: rgb(74, 74, 74);
  letter-spacing: 0;
  .app-icon {
    font-size: rem-calc(40);
  }
  .no-apps-label {
    margin-top: rem-calc(12);
    font-size: rem-calc(24);
    font-weight: normal;
  }
  .no-apps-text {
    margin-top: rem-calc(8);
    font-size: rem-calc(14);
    font-weight: normal;
    line-height: rem-calc(20);
  }
}

.student-application-page {
  .header {
    position: relative;
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    padding: rem-calc(30);
    .student-application-title {
      font-size: rem-calc(24);
      font-weight: 600;
      letter-spacing: 0;
      line-height: rem-calc(32);
      margin: rem-calc(0 0 0 30);
    }
    &.previewheader {
      box-shadow: none;
      .student-application-title {
        margin: 0;
      }
    }
    .-status-tag {
      margin-right: rem-calc(16);
    }
    .draft-label {
      color: rgb(107, 107, 107);
      font-size: rem-calc(14);
      font-style: italic;
      font-weight: normal;
      padding-left: rem-calc(12);
      text-transform: capitalize;
    }
  }
  .archived-app {
    box-shadow: none;
  }
  .preview-info-content {
    background: rgb(238, 237, 244);
    border-radius: 0px;
    width: 100%;
    text-align: center;
    padding: rem-calc(10);
    .preview-info-text {
      color: rgb(87, 81, 149);
      font-size: rem-calc(14);
      font-weight: 500;
      text-align: center;
      letter-spacing: 0;
      line-height: rem-calc(20);
    }
  }
  .immersive-container {
    height: calc(100vh - 103px);
    height: -webkit-calc(100vh - 103px);
  }
  .archived-app-container {
    height: calc(100vh - 143px);
    height: -webkit-calc(100vh - 143px);
  }
  .student-application-content {
    margin: rem-calc(24 26);
    background: rgb(255, 255, 255);
    border-radius: rem-calc(4);
    padding: rem-calc(30 40);
    .app-title {
      font-size: rem-calc(24);
      font-weight: 600;
      letter-spacing: 0;
      line-height: rem-calc(32);
      margin: 0;
      margin-bottom: rem-calc(24);
    }
    .application-component-item {
      padding-bottom: rem-calc(24);
      width: 39.65%;
      @media screen and (max-width: 1200px) {
        width: 90% !important;
      }
      .uploader-container {
        display: flex;
        flex-direction: column;
        gap: rem-calc(5);
        .uploader-help-text {
          font-size: rem-calc(14);
          font-weight: 500;
          font-style: italic;
        }
      }
    }
    .app-comp-default-field {
      padding-bottom: 0;
      .composite-components-item {
        padding-bottom: rem-calc(24);
      }
    }
    .app-comp-text-field,
    .app-comp-section-header-field {
      width: 57.7%;
    }
    .app-comp-small-text-field,
    .app-comp-dropdown,
    .app-comp-link-input,
    .app-comp-checkboxes {
      width: 39.65%;
    }
    .app-comp-attachment {
      width: 45%;
    }
    .app-comp-section-header-field h3 {
      font-size: rem-calc(22);
      font-weight: normal;
      letter-spacing: 0;
      line-height: rem-calc(30);
      margin: 0;
    }
    .app-comp-large-text-area {
      .-textarea {
        display: flex;
      }
      .textfield {
        .textfieldinput:focus {
          -webkit-box-shadow: 0 0 0 1px #20cbd4;
          -moz-box-shadow: 0 0 0 1px #20cbd4;
          box-shadow: 0 0 0 1px #20cbd4;
          outline: none;
          border-color: #20cbd4;
        }
        .charcount.-textarea:focus {
          padding-bottom: rem-calc(32);
        }
        .textfieldinput {
          resize: none;
          min-height: rem-calc(120);
          overflow: auto;
          border-radius: rem-calc(3);
          box-sizing: border-box;
        }
        .textfieldinput:disabled {
          border: none;
        }
      }
    }
  }
}

.resource-link-component {
  .resource-link {
    color: rgb(87, 81, 149);
    font-weight: bold;
    line-height: rem-calc(20);
  }
  a:hover:not(.button):not([role]) {
    background: none;
  }
}

.attachment-component {
  .attachmnt-label {
    padding-bottom: rem-calc(18);
    font-weight: 600;
  }
  &.attchmnt-student-view {
    width: 88%;
    .attachmnt-label {
      padding-bottom: rem-calc(4);
    }
  }
}

.student-link-input-component {
  .student-link-input-fields {
    display: flex;
    align-items: baseline;
  }
  .link-student-label {
    font-weight: 600;
    margin-bottom: rem-calc(12);
    display: inline-block;
  }
  .link-studnt-comp-inputs {
    width: 100%;
    &.url-input {
      .charcount input.textfieldinput:focus {
        padding-right: rem-calc(14);
      }
    }
    &.display-txt {
      margin-left: rem-calc(16);
    }
  }
  .alert-label,
  .alert-icon {
    color: rgb(192, 57, 43);
  }
}

.options-component {
  border-top: 1px solid rgb(231, 231, 231);
  padding: rem-calc(24 0);
  .options-label {
    font-size: rem-calc(16);
    font-weight: 500;
    line-height: rem-calc(24);
    margin: 0;
  }
  .options-info {
    font-size: rem-calc(14);
    font-weight: normal;
    font-style: italic;
    margin: 0;
  }
  .options-container {
    padding: rem-calc(10 0);
    .options-input {
      width: 85%;
    }
    .remove-option-btn {
      padding: rem-calc(16 0 0 16);
    }
  }
  .add-option-btn {
    margin-top: rem-calc(8);
  }
}

.app-component-label {
  white-space: pre-wrap;
  font-weight: 600;
}

.file-upload-component {
  .app-component-label {
    margin-bottom: rem-calc(8);
  }
}

.multiselect-checkbox-component {
  .checkbox-info-msg {
    font-weight: normal;
    font-style: italic;
    margin-top: 0;
  }
  .checkbox-option {
    position: relative;
    + .checkbox-option {
      margin-top: rem-calc(8);
    }
  }
}

.radio-button-component {
  :focus {
    box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
      inset 0 2px 2px 0 rgb(0 0 0 / 10%), inset 0 0 4px 3px #61279e;
    outline: none;
  }
  .app-component-label {
    margin-bottom: rem-calc(12);
    padding: 0;
  }
  .radio-cell {
    position: relative;
    + .radio-cell {
      margin-top: rem-calc(8);
    }
  }
}

.acknowledgement-checkbox-component {
  .app-component-label {
    margin-bottom: rem-calc(4);
  }
  .checkbox-option {
    position: relative;
    + .checkbox-option {
      margin-top: rem-calc(8);
    }
    input[type="checkbox"].chkbx-input + .chkbx {
      display: -webkit-inline-box;
    }
    .ack-label {
      white-space: break-spaces;
    }
  }
}

.open-application-page {
  .application-page-header {
    position: relative;
    .application-page-title {
      margin: 0;
      color: rgb(250, 250, 250);
      font-size: rem-calc(24);
      font-weight: 600;
      letter-spacing: 0;
    }
    .title-desc {
      color: rgb(255, 255, 255);
      line-height: rem-calc(20);
    }
  }
  .cohort-filter,
  .term-filter {
    padding: rem-calc(12 100);
    background: rgba(74, 74, 74, 0.04);
    display: flex;
    align-items: center;
    font-size: rem-calc(14);
    font-weight: 600;
    .filter-label {
      padding-right: rem-calc(10);
    }
    .filter-by {
      background-color: rgb(255, 255, 255);
      width: 33%;
      font-weight: 400;
    }
  }
  .term-filter .filter-by {
    width: 20%;
  }
  .application-content {
    margin: rem-calc(0 100 24 100);
    padding-top: rem-calc(24);
    display: flex;
    flex-direction: column;
    .card-component {
      max-width: 100%;
      margin-bottom: rem-calc(24);
    }
    .status-results-card {
      .card-body {
        padding: rem-calc(24 28 28);
      }
      .awaiting-review-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgb(231, 231, 231);
        padding-bottom: rem-calc(24);
        margin-bottom: rem-calc(20);
        #start-reviewing-btn {
          margin-top: rem-calc(12);
        }
      }
      .other-status-div {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        .status-div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .status-symbol {
            font-size: rem-calc(32);
            &.mdi-check-circle-outline {
              color: rgb(21, 134, 141);
            }
            &.mdi-progress-check {
              color: rgb(87, 81, 149);
            }
            &.mdi-cancel {
              color: rgb(192, 57, 43);
            }
          }
        }
      }
      .status-count {
        color: rgb(74, 74, 74);
        font-size: rem-calc(24);
        font-weight: normal;
        text-align: center;
        letter-spacing: 0;
      }
      .submission-status-label {
        color: rgb(74, 74, 74);
        font-size: rem-calc(14);
        font-weight: normal;
        letter-spacing: 0;
        line-height: rem-calc(20);
        &.awaiting-review-label {
          font-size: rem-calc(16);
          font-weight: 500;
          text-align: center;
          line-height: rem-calc(24);
        }
      }
    }
    .application-details-card {
      .card-body {
        padding: rem-calc(18 24 22 24);
      }
      .app-details-section {
        padding-bottom: rem-calc(16);
        color: rgb(74, 74, 74);
        font-size: rem-calc(14);
        letter-spacing: 0;
        line-height: rem-calc(20);
        .app-details-label {
          padding-bottom: rem-calc(4);
          font-weight: 600;
          margin: 0;
        }
        .app-details-value {
          font-weight: normal;
          padding: 0;
          list-style-type: none;
          margin: 0;
          word-break: break-word;
          &.schedule-value {
            color: rgb(107, 107, 107);
            font-style: italic;
          }
          .template-value {
            padding-bottom: rem-calc(4);
            margin: 0;
          }
        }
        &.schedule-section {
          padding-bottom: 0;
          .app-details-value {
            margin: 0;
            &.open-date {
              margin-bottom: rem-calc(4);
            }
          }
          .date-value {
            color: rgb(107, 107, 107);
            font-size: rem-calc(14);
            font-style: italic;
            line-height: rem-calc(20);
            margin-left: rem-calc(2);
          }
        }
      }
    }
    .student-submissions-card {
      background: rgb(255, 255, 255);
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
      border-radius: rem-calc(5);
      margin: auto;
      padding: 0;
      .card-header {
        border-bottom: 1px solid rgb(46, 27, 70);
        padding: rem-calc(20 24);
        .header-title {
          margin: 0;
          color: rgb(74, 74, 74);
          font-size: rem-calc(18);
          font-weight: 600;
          letter-spacing: 0;
          line-height: rem-calc(26);
        }
      }
      .-search-filter {
        margin: rem-calc(22 28);
      }
      .student-search {
        width: 55%;
      }
      #status-filter {
        width: 43%;
      }
      .pagination-footer {
        padding-right: rem-calc(32);
      }
      .data-table {
        width: 100%;
        margin: 0;
        display: table;
        .dataheader .dataheaderrow {
          display: table-row;
          background: rgb(238, 237, 244);
          border-radius: rem-calc(5 5 0 0);
          height: rem-calc(40);
        }
        .dataheaderrow th {
          padding-left: rem-calc(24);
          &.student-name-label {
            width: 22%;
          }
          &.student-email-label {
            width: 30%;
          }
          &.student-cohort-label {
            width: 23%;
          }
          &.submission-status-label {
            width: 25%;
          }
          &.action-menu-col {
            width: 5%;
          }
          .user-is-tabbing &:focus {
            box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
              inset 0 2px 2px 0 rgb(0 0 0 / 10%), inset 0 0 4px 3px #61279e;
            outline: none;
          }
        }
        .databody .datarow {
          height: rem-calc(5);
          border-bottom: 1px solid rgb(236, 238, 243);
          background-color: rgb(255, 255, 255);
          &:nth-child(even) {
            background: rgb(250, 250, 250);
          }
          td {
            word-break: break-word;
            min-width: 0%;
            padding: rem-calc(14 22);
            font-size: rem-calc(14);
            letter-spacing: 0;
            line-height: rem-calc(20);
          }
        }
        .databody {
          .user-is-tabbing &:focus {
            box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%),
              0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
            outline: none;
          }
        }
      }
      .empty-list .empty-state-body {
        margin: rem-calc(50 0);
      }
      @media screen and (max-width: 800px) {
        .data-table thead {
          display: table-header-group;
        }
      }
    }
  }
}

.merge-application-page {
  .merge-page-header {
    position: relative;
    .merge-page-title {
      margin: 0;
      color: rgb(250, 250, 250);
      font-size: rem-calc(24);
      font-weight: 600;
      letter-spacing: 0;
    }
    .go-back-link {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .program-header .bread-crumbs .link::after {
      vertical-align: top;
      color: white;
    }
    .title-desc {
      color: rgb(255, 255, 255);
      line-height: rem-calc(22);
    }
  }
  .merge-page-content {
    margin: rem-calc(0 100 24 100);
    padding: rem-calc(4 0 24);
    display: flex;
    flex-direction: column;
    .merge-info-text {
      max-width: 45%;
      font-size: rem-calc(14);
      font-weight: 500;
      line-height: rem-calc(22);
    }
    .search-filter-section {
      margin: rem-calc(4 0);
      display: flex;
      gap: rem-calc(20);
      justify-content: inherit;
      @media screen and (max-width: 1024px) {
        width: 90%;
      }
      .search-bar {
        width: 25%;
        background: rgb(255, 255, 255);
      }
      .filter-by {
        width: rem-calc(300);
        #sort-by-order {
          width: rem-calc(276);
        }
        @media screen and (max-width: 1024px) {
          margin-left: rem-calc(4);
        }
      }
    }
  }
}

#modal-content {
  overflow-y: auto;
  .duplicate-modal-content-wrapper {
    margin-right: rem-calc(67);
    .modal-text {
      font-weight: 400;
      font-size: rem-calc(14);
      line-height: rem-calc(22);
    }
    .modal-subtext {
      font-weight: 400;
      font-size: rem-calc(14);
      line-height: rem-calc(22);
      margin: rem-calc(22 0 25);
    }
    .records-container {
      display: flex;
      flex-direction: column;
      gap: rem-calc(20);
      .record {
        @include box-shadow(0 0 12px 0 rgba(46, 27, 70, 0.11));
        display: flex;
        flex-direction: column;
        gap: rem-calc(2);
        border-radius: rem-calc(5);
        padding: rem-calc(15);
        .record-heading {
          font-weight: 500;
          font-size: rem-calc(16);
          line-height: rem-calc(24);
        }
        .applicant-list {
          display: flex;
          flex-direction: column;
          gap: rem-calc(8);
          .applicant {
            display: flex;
            gap: rem-calc(10);
            line-height: rem-calc(20);
            .applicant-header {
              font-weight: 600;
            }
            .applicant-name {
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.student-match-record-card {
  margin-top: rem-calc(24);
  border-radius: rem-calc(5);
  background: rgb(255, 255, 255);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.12),
    0px 0px 4px 0px rgba(0, 0, 0, 0.14);
  .student-match-record-header {
    padding: rem-calc(20 24);
    border-bottom: 1px solid rgb(231, 231, 231);
    .student-match-record-title {
      margin: 0;
    }
    .student-match-record-tag {
      margin-left: rem-calc(10);
      &.no-match {
        display: inline-flex;
        padding: rem-calc(4 10);
        justify-content: center;
        align-items: center;
        border-radius: rem-calc(12);
        background-color: rgb(246, 225, 223);
        color: rgb(154, 46, 34);
        font-size: rem-calc(12);
        font-weight: 600;
        text-transform: uppercase;
      }
      &.updated-match {
        display: inline-flex;
        padding: rem-calc(4 10);
        justify-content: center;
        align-items: center;
        border-radius: rem-calc(12);
        background-color: rgba(233, 231, 236, 1);
        color: rgba(74, 74, 74, 1);
        font-size: rem-calc(12);
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    .main-container {
      position: relative;
      .verified-container {
        display: flex;
        align-items: center;
        gap: rem-calc(6);
        padding-right: rem-calc(20);
        .status-symbol {
          font-size: rem-calc(18);
          &.mdi-check-circle {
            color: rgba(8, 128, 0, 1);
          }
        }
        .verified-text {
          font-style: italic;
          font-weight: 400;
          font-size: rem-calc(14);
          line-height: rem-calc(22);
        }
      }
      .toolTip-container {
        display: none;
        position: absolute;
        width: rem-calc(168);
        height: rem-calc(148);
        top: rem-calc(42);
        left: rem-calc(-102);
        border-radius: rem-calc(5);
        padding: rem-calc(8 12 8 12);
        background-color: rgba(53, 59, 72, 1);
        .toolTip-text {
          font-weight: 400;
          font-size: rem-calc(14);
          line-height: rem-calc(22);
          color: white;
        }
        .toolTip-text::after {
          content: "";
          position: absolute;
          bottom: 100%;
          left: 80%;
          border-width: rem-calc(8);
          border-style: solid;
          border-color: transparent transparent rgba(53, 59, 72, 1) transparent;
        }
      }
      &:hover,
      &:focus-visible {
        .toolTip-container {
          display: flex;
        }
      }
    }
  }
  .student-match-record-content {
    padding: rem-calc(28 22 44 30);
    display: flex;
    align-items: stretch;
    flex-direction: row;
    .student-match-row-header {
      display: grid;
      justify-items: start;
      align-items: center;
      width: 14%;
      :first-child {
        height: rem-calc(34);
      }
      .student-match-row {
        font-size: rem-calc(16);
        font-weight: 500;
        word-break: break-word;
      }
    }
    .data-table {
      background: rgb(255, 255, 255);
      border-radius: rem-calc(5);
      box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.12),
        0px 0px 4px 0px rgba(0, 0, 0, 0.14);
      .dataheader .dataheaderrow {
        display: table-row;
      }
      .dataheaderrow th {
        padding-left: rem-calc(24);
        font-size: rem-calc(12);
        text-transform: uppercase;
        text-align: left;
        background-color: rgb(233, 231, 236);
        &.name-header {
          border-radius: rem-calc(5 0 0 0);
          width: 15%;
        }
        &.email-header {
          width: 20%;
        }
        &.term-header {
          width: 15%;
        }
        &.id-header {
          width: 15%;
        }
        &.btn-header {
          border-radius: rem-calc(0 5 0 0);
          width: 10%;
        }
        .user-is-tabbing &:focus {
          box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
            inset 0 2px 2px 0 rgb(0 0 0 / 10%), inset 0 0 4px 3px #61279e;
          outline: none;
        }
      }
      .databody .datarow {
        .td-content {
          word-break: break-all;
          min-width: 0%;
          padding: rem-calc(24);
          font-size: rem-calc(14);
          letter-spacing: 0;
          line-height: rem-calc(20);
        }
      }
    }
    .data-table .dataheader .dataheaderrow {
      background: rgb(233, 231, 236);
      border-radius: rem-calc(5 5 0 0);
      height: rem-calc(40);
      width: 100%;
    }
    tbody tr {
      background: rgb(250, 250, 250);
      &:nth-child(odd) {
        background-color: rgb(255, 255, 255);
      }
    }
    tbody:last-child tr {
      border-radius: rem-calc(0 0 5 5);
    }
    @media screen and (max-width: 800px) {
      .data-table .dataheader {
        display: table-header-group;
      }
      .template-list-table {
        .dataheader,
        .datarow {
          display: revert;
        }
        .template-status {
          margin-top: rem-calc(28);
        }
      }
    }
  }
}

#review-submission-flyout {
  .review-container {
    overflow: hidden;
    height: calc(100vh - 94px);
    height: -webkit-calc(100vh - 94px);
    &.-height-prop {
      height: calc(100vh - 90px);
      height: -webkit-calc(100vh - 90px);
    }
    &.archived-review-container {
      height: calc(100vh - 130px);
      height: -webkit-calc(100vh - 130px);
    }
    @media screen and (max-width: 1260px) {
      height: calc(100vh - 90px);
      height: -webkit-calc(100vh - 90px);
    }
    .review-submissions-content {
      height: 100%;
      overflow: auto;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      &.navigation-ht {
        height: 85%;
      }
      .review-side-layout {
        display: flex;
        flex-direction: column;
        width: 33%;
        margin-top: rem-calc(18);
        .internal-ref-section {
          height: 100%;
          margin-top: rem-calc(20);
        }
        .internal-ref-materials-section {
          width: 100%;
          max-width: 100%;
          .card-body {
            padding: 0;
            height: 100%;
          }
          .tab-cmpt-main {
            padding-left: rem-calc(24);
            background: rgb(248, 248, 248);
          }
          .uploader-content {
            max-width: rem-calc(588);
            padding: rem-calc(24 24);
            max-height: rem-calc(690);
            overflow: auto;
            .toggletip {
              font-weight: normal;
              margin-left: rem-calc(10);
            }
            .uploader-container {
              margin-top: rem-calc(16);
            }
            .empty-response-text {
              padding-top: rem-calc(24);
            }
          }
          .notes-content {
            padding: rem-calc(24);
            max-height: rem-calc(690);
            overflow: auto;
            .add-note-info {
              font-style: italic;
              display: inline-block;
            }
            .add-note-btns {
              display: flex;
              margin: rem-calc(16 0);
              justify-content: flex-end;
            }
            .internal-notes-list {
              list-style-type: none;
              margin: rem-calc(10 0);
              padding: 0;
              .note-item {
                margin-bottom: rem-calc(16);
                .note-item-label {
                  font-weight: 600;
                  line-height: rem-calc(20);
                  margin-right: rem-calc(14);
                }
                .note-data {
                  color: rgb(107, 107, 107);
                  font-weight: normal;
                  font-style: italic;
                  letter-spacing: 0;
                  line-height: rem-calc(20);
                  margin: rem-calc(8 0 0 0);
                }
                .text {
                  word-break: break-all;
                }
                .clamp {
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  overflow-wrap: break-word;
                }
                .hide {
                  margin-left: rem-calc(12);
                }
              }
            }
          }
        }
      }
      .submission-review-section {
        background: rgb(255, 255, 255);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
        border-radius: rem-calc(5);
        .user-is-tabbing &:focus {
          box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%),
            0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
          outline: none;
        }
        .-header {
          padding: rem-calc(20 22);
          border-bottom: 1px solid rgb(46, 27, 70);
        }
        .-header .title {
          color: rgb(74, 74, 74);
          font-size: rem-calc(18);
          font-weight: 600;
          letter-spacing: 0;
          line-height: rem-calc(26);
          margin: 0;
        }
        .-body {
          padding: rem-calc(18 24);
          .textfieldlabel {
            color: rgb(74, 74, 74);
            font-size: rem-calc(16);
            font-weight: 500;
            letter-spacing: 0;
            line-height: rem-calc(24);
            margin: 0;
            padding-bottom: rem-calc(10);
          }
          .section-header {
            @media screen and (max-width: 1100px) {
              flex-direction: column;
              display: flex;
              align-items: baseline;
              #status-change-btn {
                margin-top: rem-calc(16);
              }
            }
          }
          .button-collection {
            padding: rem-calc(24 0 20 0);
            .actiondd.show {
              display: block;
              margin-left: 6.75rem;
            }
            #accept-now-li {
              padding-right: rem-calc(80);
              margin-right: 0;
            }
            .reject-btn {
              margin-right: rem-calc(16);
            }
            .deny-icon,
            .cond-accept-icon,
            .accept-now-icon {
              margin-right: rem-calc(5);
            }
            .accept-icon {
              margin-left: rem-calc(8);
            }
            .disabled-mode {
              background: rgb(115, 115, 115) !important;
              border-color: rgb(115, 115, 115) !important;
            }
            .accept-external-btn {
              margin-left: rem-calc(16);
            }
          }
          .-btn-align {
            @media screen and (max-width: 1100px) {
              display: flex;
              justify-content: flex-start;
            }
          }
          .application-history {
            &.reviewed-status {
              margin-top: rem-calc(18);
            }
          }
          .submission-history-list {
            list-style-type: none;
            overflow: auto;
            max-height: rem-calc(240);
            margin: 0;
            margin-right: rem-calc(-24);
            padding: rem-calc(6 0 16 12);
            &.reviewed-status {
              max-height: rem-calc(280);
            }
            .submission-history-list-item {
              padding: rem-calc(18);
              padding-right: 0;
              margin-right: rem-calc(20);
              border-bottom: 1px solid rgb(231, 231, 231);
              .history-timestamp {
                font-size: rem-calc(14);
                font-weight: 600;
                line-height: rem-calc(20);
                margin: 0;
                margin-bottom: rem-calc(4);
              }
              .history-status-change-info {
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                color: rgb(107, 107, 107);
                font-style: italic;
                margin: 0;
                max-width: 77%;
              }
              .history-note-to-student {
                margin: 0;
                width: 85%;
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                border-left: 3px solid rgb(233, 231, 236);
                padding-left: rem-calc(16);
                margin-left: rem-calc(16);
                margin-top: rem-calc(14);
                word-break: break-word;
              }
            }
          }
        }
      }
      .application-review-section {
        width: 67%;
        padding: rem-calc(16 24);
        height: calc(100vh - 90px);
        height: -webkit-calc(100vh - 90px);
        &.navigation-ht {
          height: calc(100vh - 180px);
          height: -webkit-calc(100vh - 180px);
        }
        .-application-content {
          width: 100%;
          background: rgb(255, 255, 255);
          border-radius: rem-calc(4);
          padding: rem-calc(16 18);
          color: rgb(74, 74, 74);
          font-size: rem-calc(14);
          font-weight: normal;
          letter-spacing: 0;
          line-height: rem-calc(20);
          .user-is-tabbing &:focus {
            box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%),
              0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
            outline: none;
          }
          .-app-title {
            margin: 0;
            font-size: rem-calc(18);
            font-weight: 600;
            line-height: rem-calc(26);
            padding: rem-calc(8 16);
            background: rgb(244, 243, 246);
          }
          .-app-comp-list {
            .-compo-list-item {
              background: rgb(244, 243, 246);
              .-compo-resource-content {
                padding: rem-calc(20 16);
                &.section-header-field {
                  font-size: rem-calc(18);
                  font-weight: 600;
                  line-height: rem-calc(26);
                }
                &.text-field {
                  max-width: rem-calc(588);
                }
                &.attachment {
                  width: 100%;
                }
              }
              .-compo-student-response {
                padding: rem-calc(20 16);
                background: rgb(255, 255, 255);
                .-compo-label {
                  padding: rem-calc(0 0 6 0);
                  margin: 0;
                  background: rgb(255, 255, 255);
                  font-weight: 600;
                  line-height: rem-calc(16);
                  max-width: 100%;
                }
                &.def-comp-response {
                  padding: rem-calc(0 16);
                  .-compo-label {
                    padding: 0;
                  }
                }
                &.first-comp {
                  padding: rem-calc(20 16 0);
                  .-compo-label {
                    padding: 0;
                  }
                }
                .-compo-answer {
                  border-left: 3px solid rgb(233, 231, 236);
                  padding-left: rem-calc(20);
                  line-height: rem-calc(24);
                  width: 66.79%;
                  .response-option {
                    margin: 0;
                  }
                  &.file-upload {
                    width: 100%;
                    .no-pad {
                      padding: rem-calc(5 0);
                    }
                  }
                  &.acknowledgement-checkbox {
                    .label-display {
                      display: -webkit-inline-box;
                    }
                  }
                  &.checkboxes p {
                    margin: 0;
                    line-height: rem-calc(28);
                  }
                  &.default-field {
                    border-left: none;
                    padding-left: 0;
                    p {
                      margin: 0;
                      font-weight: 600;
                    }
                    .-compo-answer {
                      margin-bottom: rem-calc(20);
                    }
                    .similar-compo-answer {
                      margin-bottom: rem-calc(0);
                    }
                  }
                }
                .-compo-answer-text {
                  font-weight: normal;
                }
              }
              .similar-category {
                padding-bottom: 0;
              }
              .not-similar {
                padding: rem-calc(0 16);
                .-compo-label {
                  padding: 0;
                }
                padding-top: rem-calc(20);
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 1260px) {
      .review-submissions-content {
        flex-direction: column;
        overflow: auto;
      }
      .submission-review-section,
      .application-review-section {
        height: auto !important;
        overflow: visible !important;
        margin: 0 auto;
        margin-top: rem-calc(30);
        width: 70% !important;
        display: inline-table !important;
        padding: 0;
        background: white;
        box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 9%);
        border-radius: 0.3125rem;
        .uploader-content {
          max-width: 100% !important;
        }
      }
      .application-review-section.navigation-ht {
        margin-bottom: rem-calc(120);
      }
      .application-review-section {
        margin-bottom: rem-calc(30);
      }
      .review-side-layout {
        height: auto !important;
        overflow: visible !important;
        margin: 0 auto;
        margin-top: 1.875rem;
        width: 70% !important;
        display: inline-table !important;
        padding: 0;
        .submission-review-section {
          margin: auto;
          width: 100% !important;
        }
        .add-note-info {
          display: block !important;
        }
      }
    }
    .navigate-students {
      height: rem-calc(90);
    }
    .status-change-modal-content {
      height: 100%;
      max-height: inherit;
      overflow: auto;
      .info-text {
        margin: 0;
        font-size: rem-calc(14);
        line-height: rem-calc(20);
        margin-bottom: rem-calc(30);
        max-width: 88%;
      }
      .status-change-note {
        width: 90%;
      }
      .checkbox-option {
        margin-top: rem-calc(16);
      }
      .required-field {
        font-size: rem-calc(14);
        line-height: rem-calc(20);
        text-align: center;
        max-width: 100%;
        margin-top: 0;
        margin-bottom: rem-calc(18);
      }
    }
  }
}

#system-admin-match-flyout {
  .system-admin-match-content {
    .wm-grid-row {
      @media screen and (max-width: 1024px) {
        gap: rem-calc(24);
      }
    }
    padding: rem-calc(24);
    .student-info-card,
    .system-admin-suggested-match-card {
      background: rgb(255, 255, 255);
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
      border-radius: rem-calc(5);
      margin: auto;
      .user-is-tabbing &:focus {
        box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%),
          0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
        outline: none;
      }
      .card-header {
        border-bottom: 1px solid rgba(210, 208, 212, 1);
        padding: rem-calc(20 24);
        .header-title {
          margin: 0;
          font-size: rem-calc(18);
          font-weight: 600;
          letter-spacing: 0;
          line-height: rem-calc(22);
        }
      }
    }
    .card-content {
      padding: rem-calc(24);
      .student-details-section {
        padding-bottom: rem-calc(10);
        .student-info-label {
          margin: 0;
          font-size: rem-calc(14);
        }
        .student-info-value {
          font-size: rem-calc(14);
        }
      }
      #view-submitted-app-btn {
        padding-bottom: rem-calc(40);
      }
      .suggested-matches-content {
        .suggested-matches-text-container {
          margin-bottom: rem-calc(28);
          // margin: rem-calc(28) 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          .suggested-matches-text {
            font-size: rem-calc(22);
            font-weight: 400;
            line-height: rem-calc(27);
            text-align: center;
          }
          .suggested-matches-subtext {
            font-size: rem-calc(14);
            font-weight: 400;
            line-height: rem-calc(22);
            max-width: rem-calc(567);
            margin-top: rem-calc(8);
          }
        }
        .student-name {
          position: relative;
        }
        .no-available-match-radio-btn {
          position: relative;
        }
      }
    }
    .system-admin-search-container {
      padding-top: rem-calc(30);
      .record-search-header {
        font-size: rem-calc(18);
      }
      .search-students {
        width: 50%;
      }
      .search-results {
        margin: 0 rem-calc(-24);
        .search-table {
          padding: 0;
          margin-top: rem-calc(15);
          box-shadow: none;
          .student-name {
            position: relative;
          }
        }
        .search-table-pagination-footer {
          margin-right: rem-calc(85);
          margin-top: rem-calc(17);
          margin-bottom: rem-calc(-7);
        }
      }
      .loader-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: rem-calc(73 0);
        & :first-child {
          height: fit-content;
          width: fit-content;
        }
      }
      .placeholder-text-container {
        margin: rem-calc(124) 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .search-placeholder-text {
          font-weight: 400;
          font-size: rem-calc(22);
          line-height: rem-calc(26);
          text-align: center;
          margin-bottom: rem-calc(10);
        }
        .search-placeholder-subtext {
          font-weight: 400;
          font-size: rem-calc(14);
          line-height: rem-calc(22);
          max-width: rem-calc(567);
          margin: 0;
        }
      }
    }
    .data-table {
      background: rgb(255, 255, 255);
      border-radius: rem-calc(5);
      box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.12),
        0px 0px 4px 0px rgba(0, 0, 0, 0.14);
      .dataheader .dataheaderrow {
        display: table-row;
      }
      .dataheaderrow th {
        padding-left: rem-calc(24);
        font-size: rem-calc(12);
        text-transform: uppercase;
        text-align: left;
        width: 33%;
        background-color: rgb(233, 231, 236);
        &.fname-header {
          padding-left: rem-calc(60);
          border-radius: rem-calc(5 0 0 0);
        }
        &.email-header {
          border-radius: rem-calc(0 5 0 0);
        }
        .user-is-tabbing &:focus {
          box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
            inset 0 2px 2px 0 rgb(0 0 0 / 10%), inset 0 0 4px 3px #61279e;
          outline: none;
        }
      }
      .databody .datarow {
        .td-content {
          word-break: break-all;
          min-width: 0%;
          padding: rem-calc(24);
          font-size: rem-calc(14);
          letter-spacing: 0;
          line-height: rem-calc(20);
        }
      }
    }
    .data-table .dataheader .dataheaderrow {
      background: rgb(233, 231, 236);
      border-radius: rem-calc(5 5 0 0);
      height: rem-calc(40);
      width: 100%;
    }
    tbody tr {
      background: rgb(250, 250, 250);
      &:nth-child(odd) {
        background-color: rgb(255, 255, 255);
      }
    }
    tbody:last-child tr {
      border-radius: rem-calc(0 0 5 5);
    }
    @media screen and (max-width: 800px) {
      .data-table .dataheader {
        display: table-header-group;
      }
      .template-list-table {
        .dataheader,
        .datarow {
          display: revert;
        }
        .template-status {
          margin-top: rem-calc(28);
        }
      }
    }
  }
}

.empty-response-text {
  font-style: italic;
  padding: rem-calc(4 0);
}

.btn-backgrd {
  background: rgb(250, 250, 250);
}

.btn-line {
  color: rgb(87, 81, 149);
  position: relative;
  margin-bottom: rem-calc(12);
  text-align: center;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    height: rem-calc(2.2);
    background-size: rem-calc(11.2 2.2);
    border-bottom: 2.2px solid rgb(87, 81, 149);
  }
}

#content-publish-modal {
  padding-right: rem-calc(150);
}

#view-changestatus-flyout {
  &.half-flyout {
    > .container {
      @media screen and (max-width: 1200px) {
        left: unset;
        width: 400px;
        max-width: unset;
      }
    }
  }
  .flyout-header-change-status {
    box-shadow: none;
    border-bottom: 1px solid rgb(231, 231, 231);
  }
  .change-status-content {
    background: rgb(255, 255, 255);
    .status-change-note-field {
      margin-top: rem-calc(24);
    }
    .checkbox-option {
      margin-top: rem-calc(20);
    }
  }
  .change-status-footer {
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
  }
}

#set-schedule-flyout {
  &.half-flyout {
    > .container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: unset;
      left: unset;
      background: rgb(255, 255, 255);
      box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.09);
      border-radius: 0;
      @media screen and (min-width: 580px) {
        left: unset;
        width: rem-calc(480);
        max-width: unset;
      }

      .set-schedule-header {
        box-shadow: none;
        padding: rem-calc(20 30);
        border-bottom: 1px solid rgb(231, 231, 231);
        .flyout-header-label {
          font-size: rem-calc(14);
          font-weight: 300;
          text-transform: uppercase;
          line-height: rem-calc(20);
          margin: rem-calc(0 0 4 0);
        }
        .flyout-header-title {
          font-size: rem-calc(18);
          font-weight: 600;
          line-height: rem-calc(26);
          margin: 0;
        }
      }

      .schedule-app-body {
        height: calc(100vh - 175px);
        height: -webkit-calc(100vh - 175px);
        padding: rem-calc(18 30);
        .reqd,
        .alert-label,
        .alert-icon {
          color: rgb(192, 57, 43);
          font-size: rem-calc(14);
        }
        .required-fields {
          font-size: rem-calc(14);
          text-align: center;
        }
        .schedule-app-infotext {
          font-size: rem-calc(14);
          line-height: rem-calc(20);
          margin: rem-calc(16 0);
        }
        .schedule-title-input {
          padding-bottom: rem-calc(24);
          border-bottom: 1px solid rgb(222, 222, 222);
        }

        .set-schedule-label {
          font-size: rem-calc(16);
          font-weight: 500;
          line-height: rem-calc(24);
          margin: 0;
          margin-bottom: rem-calc(16);
          display: inline-block;
        }
        .schedule-date {
          margin-bottom: rem-calc(24);
          &.past-schedule-date {
            margin-bottom: rem-calc(12);
          }
          .past-date {
            font-size: rem-calc(14);
            line-height: rem-calc(20);
            margin: 0;
          }
        }
        .schedule-app-open {
          padding: rem-calc(22 0);
          border-bottom: 1px solid rgb(222, 222, 222);
          .past-time {
            font-size: rem-calc(14);
            line-height: rem-calc(20);
            margin: 0;
          }
        }
        .schedule-app-close {
          padding: rem-calc(22 0);
          #close-time-picker,
          #close-date-picker {
            display: flex;
            width: 62.5%;
          }
        }
      }
      .flyout-footer {
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
        flex-direction: row-reverse;
        position: absolute;
        bottom: 0;
        width: 100%;
        #cancel-btn {
          margin: 0;
        }
      }
      .textfield .textfieldinput:focus,
      .is-focused .charcount.-textarea {
        -webkit-box-shadow: 0 0 0 1px rgb(25, 161, 169) !important;
        -moz-box-shadow: 0 0 0 1px rgb(25, 161, 169) !important;
        box-shadow: 0 0 0 1px rgb(25, 161, 169) !important;
        border-color: rgb(25, 161, 169) !important;
      }
    }
  }
}

.text-area-field {
  &.component-text-area {
    .-textarea:focus-within {
      border: 2px solid rgb(32, 203, 212);
    }
    .textfield {
      textarea.textfieldinput {
        resize: none;
        height: rem-calc(150);
        overflow-y: auto;
        box-sizing: border-box;
        margin-bottom: rem-calc(0);
        &:focus {
          margin-bottom: rem-calc(30);
          height: rem-calc(120);
        }
      }
    }
  }
}

#schedule-overlap-modal {
  .modal-body {
    padding: rem-calc(24 28);
    height: 100%;
    max-height: inherit;
    overflow: auto;
    .overlap-info,
    .overlap-note {
      font-size: rem-calc(14);
      font-weight: normal;
      line-height: rem-calc(20);
      margin-top: 0;
      margin-bottom: rem-calc(24);
      max-width: 87%;
    }
    .overlap-note {
      font-weight: bold;
      margin-bottom: rem-calc(16);
    }
  }
}
.archive-modal-content {
  padding: 1.5rem 1.75rem;
  height: 100%;
  max-height: inherit;
  overflow: auto;
  .info-text {
    font-size: rem-calc(14);
    font-weight: normal;
    line-height: rem-calc(20);
    margin-top: 0;
    margin-bottom: rem-calc(24);
    max-width: 90%;
  }
  .active-app-heading {
    font-size: rem-calc(14);
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0;
    line-height: rem-calc(20);
  }
}

.text-error-message {
  font-size: rem-calc(14);
  letter-spacing: 0;
  font-style: italic;
  color: rgb(192, 57, 43);
  display: block;
  margin-top: rem-calc(5);
}

.text-area-field-component {
  .-textarea:focus-within {
    border: 2px solid rgb(32, 203, 212);
  }
  .textfield {
    textarea.textfieldinput {
      resize: none;
      min-height: rem-calc(150) !important;
      max-height: rem-calc(150) !important;
      overflow-y: auto;
      box-sizing: border-box;
      margin-bottom: 0;
      &:focus {
        margin-bottom: rem-calc(30);
        min-height: rem-calc(120) !important;
        max-height: rem-calc(120) !important;
      }
    }
  }
}

#type-selection-modal,
#application-type-flyout {
  .content {
    padding: rem-calc(24 30);
    overflow: auto;
    .audience-heading {
      font-size: rem-calc(14);
      font-weight: 600;
      line-height: rem-calc(20);
      margin: 0;
      padding-bottom: rem-calc(8);
      &.error {
        color: rgb(192, 57, 43);
        padding-bottom: rem-calc(2);
      }
    }
    .audience-selection-error {
      color: rgb(192, 57, 43);
      font-size: rem-calc(14);
      font-weight: normal;
      font-style: italic;
      margin: 0;
      padding-bottom: rem-calc(8);
    }
    #error-text {
      outline: none;
    }
    .audience-category-types {
      display: flex;
      justify-content: space-between;
      .audience-category-container {
        width: 48.5%;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
        border-radius: rem-calc(5);
        display: flex;
        flex-direction: row-reverse;
        .checked-icon {
          color: rgb(255, 255, 255);
          padding-right: rem-calc(14);
          padding-top: rem-calc(14);
          height: rem-calc(34);
          font-size: rem-calc(14);
        }
        .user-is-tabbing &:focus {
          box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
            inset 0 2px 2px 0 rgb(0 0 0 / 10%), inset 0 0 4px 3px #61279e;
          outline: none;
          background: rgb(238, 237, 244);
          .checked-icon {
            color: rgb(238, 237, 244);
          }
        }
      }
      .audience-category {
        padding: rem-calc(40 0 40 28);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .audience-category-icon {
          font-size: rem-calc(25);
          height: rem-calc(25);
          color: rgb(87, 81, 149);
        }
        .audience-category-title {
          font-weight: bold;
          line-height: rem-calc(20);
          font-size: rem-calc(14);
          color: rgb(87, 81, 149);
          padding: rem-calc(8 0);
        }
        .audience-category-desc {
          font-size: rem-calc(14);
          font-weight: normal;
          line-height: rem-calc(16);
        }
      }
      .audience-category-container:hover {
        background: rgb(238, 237, 244);
        .checked-icon {
          color: rgb(238, 237, 244);
        }
      }
      .selected,
      .selected:hover {
        background: rgb(87, 81, 149);
        .audience-category-icon,
        .audience-category-title,
        .audience-category-desc {
          color: rgb(255, 255, 255);
        }
        .user-is-tabbing &:focus {
          box-shadow: inset 0 2px 2px 0 rgb(244 243 246 / 20%),
            inset 0 2px 2px 0 rgb(0 0 0 / 10%), inset 0 0 4px 3px #61279e;
          outline: none;
          background: rgb(87, 81, 149);
        }
      }
    }
  }
}

#application-type-flyout {
  .flyout-body {
    overflow: auto;
    height: calc(100vh - 96px);
    height: -webkit-calc(100vh - 96px);
    padding: rem-calc(80);
    .content {
      max-width: 51%;
      width: 51%;
      padding: 0;
    }
  }
}

.captcha-div {
  width: rem-calc(304);
  .user-is-tabbing &:focus {
    box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%), 0 2px 2px 0 rgb(0 0 0 / 10%),
      0 0 4px 3px #61279e;
    outline: none;
  }
}

.captcha-error {
  color: rgb(192, 57, 43);
  font-size: rem-calc(14);
  font-weight: normal;
  font-style: italic;
  margin: 0;
  padding: rem-calc(8 0 8 8);
}

.submission-message-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .submission-message-text {
    margin: 0;
    padding-top: rem-calc(20);
  }
  .submission-message-sub-text {
    font-size: rem-calc(14);
    text-align: center;
  }
}

#cohort-flyout {
  .content-section {
    width: 100%;
    padding: rem-calc(20 30);
    .select-cohort {
      display: flex;
      align-items: center;
      gap: rem-calc(4);
      .select-cohort-text {
        font-size: rem-calc(14);
        font-weight: 600;
        line-height: rem-calc(22);
        color: rgba(0, 0, 0, 1);
      }
      .required-text {
        color: rgb(192, 57, 43);
        font-size: rem-calc(14);
      }
    }
    .error-text {
      color: rgba(192, 57, 43, 1);
      font-size: rem-calc(14);
      font-style: italic;
      font-weight: 400;
      line-height: rem-calc(22);
    }
    .search-cohort {
      margin-top: rem-calc(16);
      display: flex;
      gap: rem-calc(20);
      justify-content: inherit;
      @media screen and (max-width: 1024px) {
        width: 90%;
      }
    }
    .cohort-card {
      margin-top: rem-calc(18);
      border-radius: rem-calc(4);
      box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
      max-height: calc(100vh - 350px);
      max-height: -webkit-calc(100vh - 360px);
      overflow-y: auto;
      overflow-x: hidden;
      .heading {
        position: sticky;
        top: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        gap: rem-calc(10);
        padding: rem-calc(12);
        background-color: rgba(233, 231, 236, 1);
        .title {
          font-size: rem-calc(12);
          font-weight: 700;
          line-height: rem-calc(15);
          color: rgba(0, 0, 0, 1);
          cursor: pointer;
        }
      }
      .cohort-groups {
        list-style: none;
        padding: 0;
        margin: 0;
        *:not(:last-child) {
          border-bottom: 1px solid rgba(210, 208, 212, 1);
        }
        .cohort-item {
          padding: rem-calc(18 12);
          display: flex;
          gap: rem-calc(12);
          align-items: center;
          &:nth-child(even) {
            background-color: rgba(250, 250, 250, 1);
          }
          .checkbox-option {
            position: relative;
          }
          .cohort-title {
            font-size: rem-calc(14);
            font-weight: 400;
            line-height: rem-calc(22);
            color: rgba(0, 0, 0, 1);
            cursor: pointer;
          }
        }
      }
      .pagination-footer {
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
    .empty-list {
      margin: rem-calc(50 0);
    }
  }
  .footer-section {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem-calc(20 30);
    gap: rem-calc(97);
    border-top: 1px solid rgba(210, 208, 212, 1);
    background-color: #ffffff;
    .info-section {
      display: flex;
      gap: rem-calc(15);
      .count-text {
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(22);
        font-style: italic;
        color: rgba(74, 74, 74, 1);
      }
      .show-selected-section {
        position: relative;
        display: flex;
        gap: rem-calc(10);
        .show-selected-text {
          font-size: rem-calc(14);
          font-weight: 400;
          line-height: rem-calc(22);
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }
}

#term-flyout {
  .content-section {
    width: 100%;
    padding: rem-calc(20 30);
    .select-term {
      display: flex;
      align-items: center;
      gap: rem-calc(4);
      .select-term-text {
        font-size: rem-calc(14);
        font-weight: 600;
        line-height: rem-calc(22);
        color: rgba(0, 0, 0, 1);
      }
      .required-text {
        color: rgb(192, 57, 43);
        font-size: rem-calc(14);
      }
    }
    .error-text {
      color: rgba(192, 57, 43, 1);
      font-size: rem-calc(14);
      font-style: italic;
      font-weight: 400;
      line-height: rem-calc(22);
    }
    .search-term {
      margin-top: rem-calc(16);
      display: flex;
      gap: rem-calc(20);
      justify-content: inherit;
      @media screen and (max-width: 1024px) {
        width: 90%;
      }
    }
    .term-card {
      margin-top: rem-calc(18);
      border-radius: rem-calc(4);
      box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
      max-height: calc(100vh - 350px);
      max-height: -webkit-calc(100vh - 360px);
      overflow-y: auto;
      overflow-x: hidden;
      .heading {
        position: sticky;
        top: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        gap: rem-calc(10);
        padding: rem-calc(12);
        background-color: rgba(233, 231, 236, 1);
        .title {
          font-size: rem-calc(12);
          font-weight: 700;
          line-height: rem-calc(15);
          color: rgba(0, 0, 0, 1);
          cursor: pointer;
        }
      }
      .term-groups {
        list-style: none;
        padding: 0;
        margin: 0;
        *:not(:last-child) {
          border-bottom: 1px solid rgba(210, 208, 212, 1);
        }
        .term-item {
          padding: rem-calc(18 12);
          display: flex;
          gap: rem-calc(12);
          align-items: center;
          cursor: pointer;
          &:nth-child(even) {
            background-color: rgba(250, 250, 250, 1);
          }
          .checkbox-option {
            position: relative;
          }
          .term-title {
            font-size: rem-calc(14);
            font-weight: 400;
            line-height: rem-calc(22);
            color: rgba(0, 0, 0, 1);
            cursor: pointer;
          }
        }
      }
      .pagination-footer {
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
    .empty-list {
      margin: rem-calc(50 0);
    }
  }
  .footer-section {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem-calc(20 30);
    gap: rem-calc(97);
    border-top: 1px solid rgba(210, 208, 212, 1);
    background-color: #ffffff;
    .info-section {
      display: flex;
      gap: rem-calc(15);
      .count-text {
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(22);
        font-style: italic;
        color: rgba(74, 74, 74, 1);
      }
      .show-selected-section {
        position: relative;
        display: flex;
        gap: rem-calc(10);
        .show-selected-text {
          font-size: rem-calc(14);
          font-weight: 400;
          line-height: rem-calc(22);
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }
}

.sideFlyout {
  .container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: unset;
    left: unset;
    background: rgb(255, 255, 255);
    box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.09);
    border-radius: 0;
    @media screen and (min-width: 1300px) {
      left: unset;
      width: rem-calc(720);
      max-width: unset;
    }
    .header-section {
      display: flex;
      align-items: center;
      width: 100%;
      padding: rem-calc(20 30);
      gap: rem-calc(16);
      border-bottom: 1px solid rgba(210, 208, 212, 1);
      .header-title {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: rem-calc(4);
        color: rgb(74, 74, 74);
        .modal-header {
          font-size: rem-calc(14);
          font-weight: 500;
          text-transform: uppercase;
          line-height: rem-calc(17);
          margin: 0;
        }
        .modal-subHeader {
          font-size: rem-calc(18);
          font-weight: 600;
          line-height: rem-calc(22);
          margin: 0;
        }
        .required-fields {
          display: flex;
          align-items: center;
          gap: rem-calc(3);
          font-size: rem-calc(14);
          font-weight: 400;
          line-height: rem-calc(22);
          .reqd {
            color: rgb(192, 57, 43);
            font-size: rem-calc(14);
          }
        }
      }
      .close-btn {
        margin: 0;
      }
    }
  }
}

.export-message-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .primary-text {
    font-size: rem-calc(24);
    font-weight: 600;
    line-height: rem-calc(29);
    color: rgba(0, 0, 0, 1);
    margin-top: rem-calc(32);
    &.error {
      color: rgba(192, 57, 43, 1);
    }
  }
  .secondary-text-container {
    width: rem-calc(480);
    margin-top: rem-calc(16);
    line-height: rem-calc(22);
    color: rgba(0, 0, 0, 1);
    font-size: rem-calc(14);
    text-align: center;
    &.error-text {
      width: rem-calc(453);
    }
    .secondary-text {
      font-weight: 400;
    }
    .help-text {
      font-weight: 600;
    }
  }
  #cancel-export {
    margin-top: rem-calc(20);
  }
}

input[type="checkbox"].chkbx-input + .chkbx:before,
input[type="radio"].radio-input + .chkbx:before {
  margin-bottom: rem-calc(2);
}
