:not(.modal-dialog) .toolbelt {
  @include displayFlex;
  @include alignItems(center);
  @include justifyContent(space-between);
  font-size: rem-calc(14);
  padding-left: $main-content-padding;
  padding-right: $main-content-padding;
  border-top: solid 2px #f4f3f6;
  border-bottom: solid 2px #f4f3f6;
  background: #f4f4f4;
  height: rem-calc(58);

  .dropdown-select {
    > .options {
      top: rem-calc(30);
    }
    > .selectedoption {
      height: rem-calc(30);
      border-color: rgba(35, 35, 35, .6);
      padding-top: 0;
      padding-bottom: 0;
      color: $button-default-text;
    }

    + .dropdown-select {
      margin-left: rem-calc(6);
    }
  }

  wm-select + wm-select {
    margin-left: rem-calc(6);
  }

  .textfieldinput {
    height: rem-calc(30);
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
  }

  @at-root .page-header + .toolbelt {
    margin-left: $neg-main-content-padding;
    margin-right: $neg-main-content-padding;
  }
}
