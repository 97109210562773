@import "../common-style/css/settings";
@import "../common-style/css/mixins";
@import "../common-style/css/functions";
@import "../common-style/css/inter";
@import "../common-style/css/normalize";
@include normalize();

@import "../common-style/css/base";
@import "../common-style/css/objects";
@import "../common-style/css/components";

.center {
  text-align: center;
}

.home {
  height: 100vh;
}

.paper-card {
  padding: rem-calc(24 32);
}

.heading-title {
  margin: 0;
}

.empty-state-content {
  font-size: rem-calc(14);
  margin: 0;
}

.sub-title {
  font-size: rem-calc(14);
  font-weight: 300;
  padding-bottom: rem-calc(12);
  text-transform: uppercase;
}

.plan-title{
  font-size: rem-calc(14);
  font-weight: 300;
  text-transform: uppercase;
}

.org-title {
  font-size: rem-calc(14);
  font-weight: 300;
  text-transform: uppercase;
}

.tab-cmpt-main {
  padding-left: rem-calc(32);
  background: rgba(74, 74, 74, 0.04);
}

.empty-state {
  margin: auto;
  position: relative;
  top: rem-calc(80);
  .wrapper {
    margin-top: 0;
    .sub-title {
      padding-bottom: rem-calc(12);
    }
    .callout {
      padding-bottom: rem-calc(12);
    }
  }
}

.flyout-with-subtitle {
  .title {
    margin: 0;
    font-weight: 500;
    font-size: 1.5rem;
    letter-spacing: 0.3px;
  }

  .sub-title {
    font-size: rem-calc(14);
    margin: 0;
    padding-bottom: 0;
    font-weight: normal;
  }
}

.cancel {
  margin: 0 1.875rem 0 0;
}

.top-bar {
  .logo {
    &.sll-logo {
      img {
        width: rem-calc(300);
        height: rem-calc(32);
      }
    }
  }
}

body {
  overflow: hidden !important;
}

.gateways-icon {
  &:before {
    font-size: rem-calc(24);
  }
}

.flyout-container {
  @media screen and (max-width: 1024px) {
    overflow-y: auto;
    height: 100vh;
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    height: fill-available;
  }
}

.page-content-card{
  padding-bottom: rem-calc(30);
}

.immersive-header{
  &.-immersive{
    position: relative;
  }
}

.error-container{
  display:flex;
  justify-content:center;
  align-items:center;
  padding: rem-calc(80 0 50);
  img{
    width: 276px;
    height: 45px;
    margin-left: rem-calc(60);
  }
  .error-section{
    background: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
    width: 421px;
    margin-top: rem-calc(32);
  }
  .-align{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header{
    border-bottom: 1px solid rgb(227, 227, 227) !important;
    .helper-text{
      color: rgb(74, 74, 74);
      font-size: rem-calc(18);
      font-weight: 600;
      text-align: center;
      margin-top: rem-calc(8);
    }
  }
  .body{
    padding-bottom: 0 !important;
    .error-msg{
      color: rgb(74, 74, 74);
      font-size: rem-calc(14);
      font-weight: normal;
      &.-msg{
        padding: rem-calc(16 0);
      }
      &.-border{
        padding-top: rem-calc(4);
      }
      .home-btn{
        padding-top: rem-calc(12);
        padding-bottom: rem-calc(16);
        border-bottom: 1px solid rgb(227, 227, 227);
      }
    }
    .error-options{
      margin-top: rem-calc(12);
      .-icon{
        color: #575195;
      }
      .-option{
        margin-bottom: rem-calc(12);
        margin-left: rem-calc(16);
        &:last-child{
          margin-bottom: rem-calc(24);
        }
      }
      .-space{
        margin-right: rem-calc(4);
      }
    }
  }

  .technical-details{
    border-top: 1px solid rgb(227, 227, 227);
    margin: rem-calc(0 -30);
    .label{
      margin: rem-calc(20 30 0);
      color: rgb(74, 74, 74);
      font-size: rem-calc(14);
      font-weight: 600;
    }
    button{
      font-size: rem-calc(24);
    }
  }

  .details-section{
    color: rgb(74, 74, 74);
    font-size: rem-calc(14);
    font-weight: normal;
    margin: rem-calc(20 30 0);
    word-wrap: break-word;
  }
}

.dragging {
  width: rem-calc(612);
  min-height: rem-calc(72);
  background: rgb(229, 229, 239);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
  color: rgb(74, 74, 74);
  align-items: center;
  font-size: rem-calc(14);
  font-weight: 500;
  padding: rem-calc(28);
  display: none;
  border-radius: rem-calc(4);
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;

  &:active {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing;
  }

  .inner-Text {
    padding-left: rem-calc(32);
    font-weight: 600;
    font-size: rem-calc(14);
  }

  .inner-Text:before {
    content: "";
    background: url("#{$staticpath}src/images/drag-drop.svg") no-repeat;
    width: rem-calc(24);
    height: rem-calc(24);
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: rem-calc(24);
  }
}

.drop-zone {
  background: rgb(65, 60, 112);
  border-radius: rem-calc(5);
  height: rem-calc(74);
  margin: rem-calc(20 30);
  display: none;
}

.section-drop-zone {
  margin: 0;
}

.drop-zone.isDragged {
  display: block;
}

.isDragging {
  display: none;
}