/*
1. Flex
2. Box-shadow
3. Border-radius
4. Transform
5. CSS Triangle
6. Shadows
7. Helpers
8. position:sticky
9. Input Range
*/

/* --------------------------------------
   1. Flex
   -------------------------------------- */
@mixin displayFlex() {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@mixin flex($value) {
  -webkit-box-flex: $value;
  -webkit-flex: $value;
  -ms-flex: $value;
  flex: $value;
}
@mixin flexShrink($value) {
  -webkit-flex-shrink: $value;
  -ms-flex-negative: $value;
  flex-shrink: $value;
}
@mixin flexGrow($value) {
  -webkit-flex-grow: $value;
  -ms-flex-positive: $value;
  flex-grow: $value;
}
@mixin flexBasis($value) {
  -webkit-flex-basis: $value;
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}
@mixin flexWrap($value) {
  -webkit-flex-wrap: $value;
  -ms-flex-wrap: $value;
  flex-wrap: $value;
}
@mixin alignItems($direction) {
  -webkit-box-align: $direction;
  -webkit-align-items: $direction;
  -ms-flex-align: $direction;
  align-items: $direction;
}
@mixin alignSelf($direction) {
  -webkit-align-self: $direction;
  -ms-flex-item-align: $direction;
  align-self: $direction;
}
@mixin justifyContent($placement) {
  @if ($placement == space-between) {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else {
    -webkit-box-pack: $placement;
    -ms-flex-pack: $placement;
  }
  -webkit-justify-content: $placement;
  justify-content: $placement;
}
@mixin flexDirection($direction, $reverse: null) {
  @if ($direction == column) {
    -webkit-box-orient: vertical;
  }
  @if ($direction == row) {
    -webkit-box-orient: horizontal;
  }
  @if ($reverse == true) {
    -webkit-box-direction: reverse;
  } @else {
    -webkit-box-direction: normal;
  }
  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

/* --------------------------------------
   2. Box-shadow
   -------------------------------------- */
@mixin box-shadow($settings...) {
  -webkit-box-shadow: $settings;
  -moz-box-shadow: $settings;
  box-shadow: $settings;
}

/* --------------------------------------
   3. Border-radius
   -------------------------------------- */
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

/* --------------------------------------
   4. Transforms
   -------------------------------------- */
@mixin translate($distanceX: null, $distanceY: null, $sameVal: null) {
  @if ($distanceX and $distanceY) {
    -webkit-transform: translate($distanceX, $distanceY);
    -moz-transform: translate($distanceX, $distanceY);
    -ms-transform: translate($distanceX, $distanceY);
    -o-transform: translate($distanceX, $distanceY);
    transform: translate($distanceX, $distanceY);
  }
  @if ($distanceX and not $distanceY) {
    -webkit-transform: translateX($distanceX);
    -moz-transform: translateX($distanceX);
    -ms-transform: translateX($distanceX);
    -o-transform: translateX($distanceX);
    transform: translateX($distanceX);
  }
  @if ($distanceY and not $distanceX) {
    -webkit-transform: translateY($distanceY);
    -moz-transform: translateY($distanceY);
    -ms-transform: translateY($distanceY);
    -o-transform: translateY($distanceY);
    transform: translateY($distanceY);
  }
  @if ($sameVal) {
    -webkit-transform: translate($sameVal, $sameVal);
    -moz-transform: translate($sameVal, $sameVal);
    -ms-transform: translate($sameVal, $sameVal);
    -o-transform: translate($sameVal, $sameVal);
    transform: translate($sameVal, $sameVal);
  }
}

@mixin transition($settings...) {
  -ms-transition: $settings;
  -webkit-transition: $settings;
  -moz-transition: $settings;
  transition: $settings;
}

@mixin transitionDelay($settings) {
  -webkit-transition-delay: $settings;
  -ms-transition-delay: $settings;
  -moz-transition-delay: $settings;
  transition-delay: $settings;
}

@mixin scale($xVal: null, $yVal: null) {
  @if ($xVal and $yVal) {
    -ms-transform: scale($xVal, $yVal);
    -webkit-transform: scale($xVal, $yVal);
    -moz-transform: scale($xVal, $yVal);
    transform: scale($xVal, $yVal);
  }
  @if ($xVal and not $yVal) {
    -ms-transform: scaleX($xVal);
    -webkit-transform: scaleX($xVal);
    -moz-transform: scaleX($xVal);
    transform: scaleX($xVal);
  }
  @if ($yVal and not $xVal) {
    -ms-transform: scaleY($yVal);
    -webkit-transform: scaleY($yVal);
    -moz-transform: scaleY($yVal);
    transform: scaleY($yVal);
  }
}

@mixin transformOrigin($settings) {
  -ms-transform-origin: $settings;
  -webkit-transform-origin: $settings;
  -moz-transform-origin: $settings;
  transform-origin: $settings;
}

/* --------------------------------------
   5. CSS Triangle
   -------------------------------------- */
@mixin css-triangle($triangle-size, $triangle-color, $triangle-direction) {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset $triangle-size;
  @if ($triangle-direction == top) {
    border-color: $triangle-color transparent transparent transparent;
    border-top-style: solid;
  }
  @if ($triangle-direction == bottom) {
    border-color: transparent transparent $triangle-color transparent;
    border-bottom-style: solid;
  }
  @if ($triangle-direction == left) {
    border-color: transparent transparent transparent $triangle-color;
    border-left-style: solid;
  }
  @if ($triangle-direction == right) {
    border-color: transparent $triangle-color transparent transparent;
    border-right-style: solid;
  }
}

/* --------------------------------------
   6. Shadows
   -------------------------------------- */
@mixin shadow1 {
  @include box-shadow(
    0 0 2px 0 rgba(0, 0, 0, 0.14),
    0 2px 2px 0 rgba(0, 0, 0, 0.12)
  );
}
@mixin shadow2 {
  @include box-shadow(
    0 0 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2)
  );
}
@mixin shadow3 {
  @include box-shadow(
    0 3px 3px 0 rgba(0, 0, 0, 0.14),
    0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 8px 0 rgba(0, 0, 0, 0.2)
  );
}
@mixin shadow4 {
  @include box-shadow(
    0 2px 4px 0 rgba(0, 0, 0, 0.14),
    0 4px 5px 0 rgba(0, 0, 0, 0.12),
    0 1px 10px 0 rgba(0, 0, 0, 0.2)
  );
}
@mixin shadow6 {
  @include box-shadow(
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px 0 rgba(0, 0, 0, 0.2)
  );
}
@mixin shadow8 {
  @include box-shadow(
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 3px rgba(0, 0, 0, 0.12),
    0 4px 15px 0 rgba(0, 0, 0, 0.2)
  );
}
@mixin shadow9 {
  @include box-shadow(
    0 9px 12px 1px rgba(0, 0, 0, 0.14),
    0 3px 16px 2px rgba(0, 0, 0, 0.12),
    0 5px 6px 0 rgba(0, 0, 0, 0.2)
  );
}
@mixin shadow12 {
  @include box-shadow(
    0 12px 17px 2px rgba(0, 0, 0, 0.14),
    0 5px 22px 4px rgba(0, 0, 0, 0.12),
    0 7px 8px 0 rgba(0, 0, 0, 0.2)
  );
}
@mixin shadow16 {
  @include box-shadow(
    0 12px 17px 2px rgba(0, 0, 0, 0.14),
    0 5px 22px 4px rgba(0, 0, 0, 0.12),
    0 7px 8px 0 rgba(0, 0, 0, 0.2)
  );
}
@mixin shadow24 {
  @include box-shadow(
    0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12),
    0 11px 15px 0 rgba(0, 0, 0, 0.2)
  );
}

/* --------------------------------------
   7. Helpers
   -------------------------------------- */
@mixin section-padding {
  margin-top: rem-calc(40);
  padding-top: rem-calc(34);
  border-top: 1px solid $taskstream-header-gray;
}

@mixin type-level-1 {
  font-size: 1.625rem;
  font-weight: 700;
}
@mixin type-level-2 {
  font-size: 1.125rem;
  font-weight: 700;
}
@mixin type-level-3 {
  font-size: 1.125rem;
}
@mixin type-level-4 {
  font-size: 0.875rem;
}
@mixin type-level-4-bold {
  font-size: 0.875rem;
  font-weight: 700;
}
@mixin type-level-5 {
  font-size: 0.75rem;
  font-weight: 700;
}
@mixin type-level-6 {
  font-size: 0.75rem;
}
@mixin type-level-7 {
  font-style: italic;
  font-size: 0.75rem;
}

/* --------------------------------------
   7. Input Range
   -------------------------------------- */
@mixin mdi-icon {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* --------------------------------------
   8. position: sticky
   -------------------------------------- */
@mixin sticky() {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: sticky;
}
/* --------------------------------------
   9. Input Range
   -------------------------------------- */

@mixin track() {
  width: $track-width;
  height: $track-height;
  cursor: pointer;
  transition: all 0.2s ease;
}

@mixin thumb() {
  @include box-shadow(
    $thumb-shadow-size,
    $thumb-shadow-blur,
    $thumb-shadow-color
  );
  border: $thumb-border-width solid $thumb-border-color;
  height: $thumb-height;
  width: $thumb-width;
  border-radius: $thumb-radius;
  background: $thumb-color;
  cursor: pointer;
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin grid-calc($cols, $size: "") {
  @for $i from 1 through $cols {
    @if $size != "" {
      .wm-grid-col-#{$size}-#{$i} {
        @include flexBasis($i / $cols * 100%);
        max-width: ($i / $cols * 100%);
      }
    } @else {
      .wm-grid-col-#{$i} {
        @include flexBasis($i / $cols * 100%);
        max-width: ($i / $cols * 100%);
      }
    }
  }
}
