.legend-box {
  @include displayFlex;
  @include alignItems(center);
  @include flexWrap(wrap);
  text-align: center;
  padding-bottom: rem-calc(20);

  .legend-contain {
    position: relative;
    margin-top:rem-calc(8);
    padding:rem-calc(0 10);

    > .color-box {
      position: absolute;
      top:0;
      left:0;
      bottom: 0;
      margin: auto;
      width:rem-calc(11);
      height:rem-calc(11);
    }
    > .legend-text {
      padding-left: rem-calc(5);
      margin-right:rem-calc(0);
      font-size:rem-calc(14);
      font-weight: normal;
      line-height: 1;

      > .status-text{
        font-weight: bold;
        padding-right:rem-calc(5);
      }
    }
  }
}
