.data-table {
  border-collapse: collapse;
  border-spacing: 0;
  line-height: 1.5;
  width: 100%;

  .highlighted {
    //TODO: actual styling
    background-color: yellow;
  }

  &.-topaligned .dataheadercell,
  .datacell {
    vertical-align: top;
  }

  &.-centeraligned .dataheadercell,
  &.-centeraligned .datacell {
    vertical-align: middle;
  }

  &.-editable .datacell {
    border-right: 2px #f4f4f4 solid;

    &:last-child {
      border-right: none;
    }
  }

  .dataheadercell {
    background-color: rgba(#2e1b46, 0.05);
    font-size: rem-calc(12);
    font-weight: bold;
    text-transform: uppercase;
    vertical-align: top;
  }

  .datarow .datacell {
    &.-loading,
    &.-noresults {
      height: rem-calc(100);
      padding: rem-calc(20);
      text-align: center;
    }
    &.-noresults {
      font-style: italic;
      vertical-align: inherit;
    }
  }

  .datarow:nth-child(even) {
    background-color: #fafafa;
  }

  .datacell {
    border-bottom: 2px #f4f4f4 solid;
    min-width: calc(0%);
    position: relative;
    vertical-align: top;
  }

  .datarow:last-child .datacell {
    border-bottom: 0;
  }

  .dataheadercell,
  .datacell {
    padding: rem-calc(12);
    text-align: left;

    &:first-child {
      padding-left: rem-calc(20);
    }
    &:last-child {
      padding-right: rem-calc(20);
    }
  }

  .paper-card > &:first-child {
    .dataheadercell:first-child {
      border-top-left-radius: 5px;
    }
    .dataheadercell:last-child {
      border-top-right-radius: 5px;
    }
  }

  &.-clickablerows {
    display: block;

    .dataheader,
    .databody {
      display: block;
    }
    .datarow,
    .dataheaderrow {
      display: -ms-grid;
      display: grid;
    }
    .datacell,
    .dataheadercell {
      display: block;
    }

    .datarow:hover {
      @include box-shadow(
        0 2px 10px 0 rgba(0, 0, 0, 0.05),
        0 1px 18px 0 rgba(0, 0, 0, 0.12)
      );
      cursor: pointer;
      position: relative;
      z-index: 2;
    }

    .datacell {
      vertical-align: middle;

      .actions {
        .-actionbutton {
          @include border-radius(30);
          width: rem-calc(30);
          height: rem-calc(30);
          line-height: normal;
        }
      }
    }
  }
}

[dir="RTL"] {
  .datacell,
  .dataheadercell {
    text-align: right;

    &:first-child {
      padding-left: rem-calc(10);
      padding-right: rem-calc(20);
    }
    &:last-child {
      padding-left: rem-calc(20);
      padding-right: rem-calc(10);
    }
  }
}

@media screen and (max-width: 1000px) {
  .data-table {
    .dataheadercell,
    .datacell {
      padding: rem-calc(10 5);

      &:first-child {
        padding-left: rem-calc(10);
      }
      &:last-child {
        padding-right: rem-calc(10);
      }
    }

    .datarow .datacell {
      &.-loading,
      &.-noresults {
        padding: rem-calc(20 10);
      }
    }
  }

  [dir="RTL"] .data-table {
    .datacell,
    .dataheadercell {
      &:first-child,
      &:last-child {
        padding-left: rem-calc(10);
        padding-right: rem-calc(10);
      }
    }
  }
}

@media screen and (max-width: $datatableBreakpoint) {
  .data-table thead {
    display: none;
  }
  .data-table .datacell {
    @include displayFlex;
    @include alignItems(baseline);
    padding: rem-calc(10);

    &:first-child {
      padding-left: rem-calc(10);
    }
    &:last-child {
      padding-right: rem-calc(10);
    }

    &:not(:last-child) {
      border-bottom: none;
    }

    &.-noresults:before {
      width: auto;
    }
    &.-loading:before {
      display: none;
    }
  }
  .data-table.-editable .datacell {
    border-right: none;
  }
  .data-table .datarow:last-child .datacell {
    border-bottom: 2px #f4f4f4 solid;
  }
  .data-table .datarow:last-child .datacell:last-child {
    border-bottom: none;
  }
  .data-table .datacell:before {
    @include flex(none);
    content: attr(data-th);
    font-weight: 700;
    display: inline-block;
    padding-right: rem-calc(10);
    width: 30%;
    vertical-align: inherit;
  }
  [dir="RTL"] {
    .datacell:before {
      padding-right: initial;
      padding-left: rem-calc(10);
    }
  }
}
