.search-form {
  @include displayFlex;
  @include flexDirection(column);
  position: relative;

  &.-withicon {
    .textfield {
      + .icon, + .custom-chkbx {
        @include translate($distanceY: -50%);
        position: absolute;
        left: rem-calc(7);
        top: 50%;

        [dir="RTL"] & {
          left:auto;
          right:rem-calc(7);
        }
      }
    }
    .textfieldinput {
      padding-left: rem-calc(30);

      [dir="RTL"] & {
        padding-left:rem-calc(15);
        padding-right:rem-calc(30);
      }
    }
  }

  > .mdl-textfield, > .field {
    &:focus, &.is-focused {
      outline: none;

      + .icon {
        color: #20cbd4;
      }
    }
  }

  > .field, .mdl-textfield__input {
    @include flex(1 1 auto);
    background: transparent;
  }

  &.-navigate {
    @include flexDirection(row);
    @include alignItems(center);
    padding: 4px 20px;
    position: relative;
    font-size: rem-calc(14);

    .search-icon {
      position: absolute;
      left: 11px;
    }

     .field {
      border: none;
      border-bottom: solid 1px rgba(35, 35, 35, 0.6);
      height: 100%;
      padding-right: rem-calc(110);
      padding-bottom: 8px;

      &.-warning {
        outline: none;
        border-bottom: 3px solid #f15a22;
        padding-bottom: 4px;
      }
      &:focus, &.is-focused {
        outline: none;
        border-bottom: solid 2px #20cbd4;
      }
    }

    > .count {
      white-space: nowrap;
      margin-left: rem-calc(8);
    }

    > .button-collection {
      margin-left: rem-calc(8);
      
      &.active {
        color: $body-text-color;
      }
    }

    .search.button {
      font-size: rem-calc(20);
      width: rem-calc(30);

      + .search.button {
        margin-left: 0;
      }
    }
  }
}
