.org-node {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  font-size: rem-calc(14);

  .node {
    list-style-type: none;
    padding-left: 0;

    [dir="RTL"] & {
      padding-right: 0;
    }
  }

  .node-info {
    font-size: rem-calc(14);
    margin: 0;
    line-height: 1.3;

    .infoheader,
    .infodesc {
      display: inline;
    }
    .infoheader {
      font-weight: 300;
    }
    .infodesc + .infoheader:before {
      content: "\A";
      white-space: pre;
    }
  }

  .orgnode {
    border-top: 2px solid #f3f4f6;
    position: relative;
    padding: rem-calc(20 10 20 50);

    [dir="RTL"] & {
      padding: rem-calc(20 50 20 10);
    }

    .column {
      position: relative;
      margin-left: rem-calc(20);
      margin-right: rem-calc(20);

      &.view {
        min-width: rem-calc(60);
      }
    }

    &.grid-row .column:first-child {
      margin-left: 0;

      [dir="RTL"] & {
        margin-right: 0;
      }
    }
  }

  > .treeitem > .orgnode {
    border-top: none;
  }

  .button.-toggle {
    position: absolute;
    left: rem-calc(-36);
    font-size: rem-calc(14);
    top: rem-calc(-12);
    width: rem-calc(40);
    height: rem-calc(40);
    margin: 0;
    color: #575195;

    [dir="RTL"] & {
      right: rem-calc(-36);
      left: auto;
    }
  }

  .radio {
    margin-right: rem-calc(10);
    vertical-align: middle;
  }

  .treeitemlabel {
    font-weight: 400;
    font-size: rem-calc(14);
    display: inline-block;
    margin: 0;

    .highlight {
      background: #f0c2c2;

      &.focus {
        background: #c8dfcc;
      }
    }
  }

  $sel: "";
  @for $i from 1 through 8 {
    $sel: if($i == 1, ".node", selector-nest($sel, ".node"));

    #{$sel} .column:first-child {
      margin-left: $i * rem-calc(27);
      position: relative;
    }

    #{$sel} h3 + .outcomesets {
      padding-left: $i * rem-calc(27);
    }
  }

  &.node {
    $sel2: ".org-node.node";
    @for $i from 2 through 9 {
      $sel2: if($i == 2, ".node", selector-nest($sel2, ".node"));

      #{$sel2} .column:first-child {
        margin-left: ($i - 1) * rem-calc(27);
        position: relative;
      }

      #{$sel2} h3 + .outcomesets {
        padding-left: ($i - 1) * rem-calc(27);
      }
    }
  }

  .orglevelselect {
    margin-right: 10px;
  }

  [dir="RTL"] & {
    $sel: "";
    @for $i from 1 through 8 {
      $sel: if($i == 1, ".node", selector-nest($sel, ".node"));

      #{$sel} .column:first-child {
        margin-left: rem-calc(20);
        margin-right: $i * rem-calc(27);
      }

      #{$sel} h3 + .outcomesets {
        padding-right: $i * rem-calc(27);
        padding-left: 0;
      }
    }

    &.node {
      $sel2: ".org-node.node";
      @for $i from 2 through 9 {
        $sel2: if($i == 2, ".node", selector-nest($sel2, ".node"));

        #{$sel2} .column:first-child {
          margin-left: rem-calc(20);
          margin-right: ($i - 1) * rem-calc(27);
        }

        #{$sel2} h3 + .outcomesets {
          padding-right: ($i - 1) * rem-calc(27);
          padding-left: 0;
        }
      }
    }
  }
}

li.org-node {
  border-bottom: none;
}

.org-nodes {
  $sel: "";
  @for $i from 1 through 8 {
    $sel: if($i == 1, ".node", selector-nest($sel, ".node"));

    #{$sel} h3 {
      padding-left: $i * rem-calc(49) + rem-calc(54);
    }
    #{$sel} h3 + .outcomesets > li .grid-row > .main-col {
      padding-left: $i * rem-calc(49);
    }
    #{$sel} h3 + .outcomesets > li .grid-row + .outcomes li.-expandable {
      padding-left: $i * rem-calc(49) + rem-calc(30);
    }
    #{$sel}
      h3
      + .outcomesets
      > li
      .grid-row
      + .outcomes
      > li:not(.-expandable) {
      padding-left: $i * rem-calc(49) + rem-calc(48);
    }
    #{$sel} h3 + .outcomesets > li .grid-row + .outcomes li.-subexpanded {
      padding-left: $i * rem-calc(49) + rem-calc(83);
    }
  }
  [dir="RTL"] & {
    $sel: "";
    @for $i from 1 through 8 {
      $sel: if($i == 1, ".node", selector-nest($sel, ".node"));

      #{$sel} h3 {
        padding-right: $i * rem-calc(49) + rem-calc(54);
        padding-left: 0;
      }
      #{$sel} h3 + .outcomesets > li .grid-row > .main-col {
        padding-right: $i * rem-calc(49);
        padding-left: 0;
      }
      #{$sel} h3 + .outcomesets > li .grid-row + .outcomes li.-expandable {
        padding-right: $i * rem-calc(49) + rem-calc(30);
        padding-left: 0;
      }
      #{$sel}
        h3
        + .outcomesets
        > li
        .grid-row
        + .outcomes
        > li:not(.-expandable) {
        padding-right: $i * rem-calc(49) + rem-calc(48);
        padding-left: 0;
      }
      #{$sel} h3 + .outcomesets > li .grid-row + .outcomes li.-subexpanded {
        padding-right: $i * rem-calc(49) + rem-calc(83);
        padding-left: 0;
      }
    }
  }
}
