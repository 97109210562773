.bread-crumbs {
  margin: rem-calc(0 0 20);

  .link {
    text-decoration: none;

    &:after {
      content: "\f142";
      display: inline-block;
      color: inherit;
      margin: rem-calc(0 6);
      font: normal normal normal 24px/1 "Material Design Icons";
      font-size: rem-calc(14);
      vertical-align: text-bottom;
      text-rendering: auto;
      line-height: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &:hover {
      background-image: none !important;

      span {
        background: linear-gradient($button-default-text, $button-default-text) no-repeat;
      	background-size: 100% 1px;
      	background-position: 0 1em;
      }
    }

    .user-is-tabbing &:focus {
      background-image: none !important;

      span {
        background: linear-gradient(90deg, #3862E9 66%, transparent 0) repeat-x;
      	background-size: 6px 3px;
      	background-position: 0 1em;
      }
    }
  }

  [dir="RTL"] & {
    .link:after {
      content: "\f141";
    }
  }
}
