.paper-card {
  @include border-radius(5px);
  @include box-shadow(0 2px 4px 0 rgba(0, 0, 0, 0.09));
  padding: rem-calc(20 30);
  background: #fff;
  font-size: rem-calc(14);

  &.-notoppadding {
    padding-top: 0;
  }
  &.-nobottompadding {
    padding-bottom: 0;
  }
  &.-nosidepadding {
    padding-right: 0;
    padding-left: 0;

    > .header {
      margin-right: 0;
      margin-left: 0;
    }
  }
  &.-nopadding {
    padding: 0;

    > .header {
      margin-right: 0;
      margin-left: 0;
    }
  }
  &.-inaset {
    margin-bottom: rem-calc(20);
  }
  &.-collapsed {
    > .header {
      border-bottom: none;
    }
  }

  > .header {
    border-bottom: 1px solid #2e1b46;
    margin-left: rem-calc(-30);
    margin-right: rem-calc(-30);
    padding: rem-calc(0 30 20);
    position: relative;

    .subtitle,
    .eyebrowtitle {
      font-size: rem-calc(12);
      margin: rem-calc(0 0 3);
      font-weight: 400;
      text-transform: uppercase;
    }

    .title {
      font-size: rem-calc(18);
      margin: 0;
      font-weight: 600;
    }

    + .body .data-table {
      .dataheadercell:first-child,
      .datacell:first-child {
        padding-left: rem-calc(30);
      }
    }
  }

  &.-tabbed > .header {
    background: rgba($header-background, 0.1);
    border-bottom: none;
  }

  > .body {
    padding: rem-calc(20 30);
    margin-left: rem-calc(-30);
    margin-right: rem-calc(-30);
  }

  > .footer {
    margin-left: rem-calc(-30);
    margin-right: rem-calc(-30);
    padding: rem-calc(20 30 0);
    text-align: right;

    &:not(.-noborder) {
      border-top: 1px solid #2e1b46;
    }
  }

  .paper-card {
    padding-top: 0;
    border: 1px solid rgb(229, 229, 229);
    @include box-shadow(none);

    > .header {
      background: #e5e5e5;
      border-bottom: none;
      padding-top: rem-calc(20);
    }
  }

  .data-table {
    margin: rem-calc(-20 -30);
    width: calc(100% + 3.78rem);
  }
}

@media screen and (max-width: 800px) {
  .paper-card > .header + .body .data-table .datacell {
    padding-left: rem-calc(30);
  }
}
