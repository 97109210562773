.file-manager {
  font-size: rem-calc(14);
  > .singlefile {
    @include border-radius(3px);
    position: relative;
    padding: rem-calc(16 30);
    background: #ffffff;
    font-size: rem-calc(14);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.14);
    &.-uploading {
      color: #0da1ae;
    }

    &.-saved {
      padding-top: rem-calc(16);
      padding-bottom: rem-calc(16);
    }

    &.-error {
      color: #c0392b;
      background: rgba(229, 110, 73, 0.25);
    }

    + .singlefile {
      margin-top: rem-calc(10);
    }

    .info{
      padding-right: rem-calc(30);
       span {
          font-weight: 500;
        }
        .filename{
          font-weight: 500;
        }
        .file-date{
          margin-left: rem-calc(60);
          font-weight: normal;
          font-style: italic;
        }
      }

    .button-collection.-icons > .button + .button {
        margin-left: rem-calc(10);
    }
  }
}

.user-is-tabbing{
  input[type="file"],.input-file{
    &:focus{
      +label, .button{
        -webkit-box-shadow: 0 2px 2px 0 rgba(244, 243, 246, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 0 4px 3px #61279e;
        -moz-box-shadow: 0 2px 2px 0 rgba(244, 243, 246, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 0 4px 3px #61279e;
        box-shadow: 0 2px 2px 0 rgba(244, 243, 246, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 0 4px 3px #61279e;
        outline: none;
      }
    }
  }
}
