.flyout-panel {
  $panel-left: 0;
  $panel-right: 0;

  @include transitionDelay(.5s);
  @include displayFlex;
  @include flexDirection(column);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 100vw;
  z-index: 2000;
  text-align: left;
  opacity: 0;

  [dir="RTL"] & {
    left: 0;
    right: 100vw;
    text-align:right;
  }

  > .container {
    @include flex(1);
    @include displayFlex;
    @include flexDirection(column);
    min-height: 0;
  }

  > .overlay,
  > .container > .overlay {
    @include transition(opacity .5s ease-out);
    background-color: rgba(25,25,25,.7);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    opacity: 0;
  }

  > .content,
  > .header,
  > .container > .content,
  > .container > .header {
    @include transition(all .2s cubic-bezier(0.4, 0, 0.2, 1));
    top: 0;
    right: 0;
    left: $panel-left;
    margin-left: 100%;
    width: 100%;

    [dir="RTL"] & {
      left: 0;
      right: $panel-right;
      margin-left:0;
      margin-right:100%;
    }
  }

  > .header,
  > .container > .header {
    @extend .immersive-header;
    background: $sliding-panel-header-background;

    + .content {
      padding-top: rem-calc(30);
    }

    @media screen and (-webkit-min-device-pixel-ratio:0) {
        top: rem-calc(-40);
    }
  }

  > .content,
  > .container > .content {
    @include flex(1);
    @include flexDirection(column);
    position: relative;
    margin-left: 100%;
    background: #fafafa;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 16;
    padding: rem-calc(0 30);

    [dir="RTL"] & {
      margin-left: 0;
      margin-right: 100%;
    }

    .flex-row {
      display: none;
    }

    .empty-state {
      top: 0;
    }
    @media screen and (min-height: 650px) {
      .empty-state {
        top: 105px;
      }
    }
  }

  &:not(.show) {
    a, button, input, textarea {
      display: none !important;
    }
  }
  > .course-outcome {
      > .header + .content {
        padding-top: 0;
      }
      >.content{
        padding: 0;
      }
      @media screen and (-webkit-min-device-pixel-ratio:0) {
          > .header{
            top: 0;
          }
      }
  }
  &.show {
    @include transitionDelay(0s);
    left: 0;
    opacity: 1;

    [dir="RTL"] & {
      right: 0;
      left: 0;
    }

    > .overlay,
    > .container > .overlay {
      @include transition(opacity .5s ease-out);
      opacity: 1;
    }

    > .content, > .header,
    > .container > .content,
    > .container > .header {
      transition-duration: .1s;
    }

    > .header,
    > .container > .header {
      background: $sliding-panel-header-background;
      color: set-color($sliding-panel-header-background, $body-text-color, #fff);
      margin-left: 0;

      [dir="RTL"] & {
        margin-left: auto;
        margin-right: 0;
      }

      .button {
        &.-secondary:not(.-disabled):not(:disabled), &.-icononly {
          background: set-color($sliding-panel-header-background, $button-default-background, $button-dark-background);
          color: set-color($sliding-panel-header-background, $button-default-text, #fff);
          border-color: set-color($sliding-panel-header-background, $button-default-text, #fff);

          &:hover {
            background: set-color($sliding-panel-header-background, mix(black, $button-primary-background, 20%), #fff);
            color: set-color($sliding-panel-header-background, #fff, $button-default-text);
          }
        }

        &.-secondary:disabled, &.-secondary.-disabled {
          $shadowBG: set-color($sliding-panel-header-background, $light-shadow, $dark-shadow);
          @include box-shadow($shadowBG);
          background: set-color($sliding-panel-header-background, $button-default-disabled-background, $button-dark-background)
        }

        &.-primary:not(.-disabled):not(:disabled), &.-icononly {
          background: set-color($sliding-panel-header-background, $button-primary-background, $button-primary-dark-background);
          color: set-color($sliding-panel-header-background, #fff, $button-primary-background);
          border-color: set-color($sliding-panel-header-background, $button-default-text, #fff);


          &:hover {
            background: set-color($sliding-panel-header-background, mix(black, $button-primary-background, 20%), mix(black, $button-primary-dark-background, 10%));
            color: set-color($sliding-panel-header-background, #fff, $button-default-text);
          }
        }

        .user-is-tabbing &.-primary:focus, .user-is-tabbing &.-secondary:focus {
          @if (lightness($sliding-panel-header-background) > 50) {
            @extend %focus-styles;
          } @else {
            @extend %focus-on-dark;
          }
        }

        &.-navigational {
          color: set-color($sliding-panel-header-background, $button-default-text, #fff);

          &:hover {
            background: set-color($sliding-panel-header-background, #d7d6d9, #fff);
            color: set-color($sliding-panel-header-background, $button-default-text, $sliding-panel-header-background);
          }
        }
      }

      .search-form.-navigate {
        .field {
          background: $sliding-panel-header-background;
        }
      }
    }

    > .content,
    > .container > .content {
      margin-left: 0;

      [dir="RTL"] & {
        margin-left: auto;
        margin-right: 0;
      }

      .flex-row {
        @include displayFlex;
      }
    }
  }
  &.-sliding-panel{
    @include transition(left 0s ease-out);
    @include transitionDelay(.3s);
    overflow: hidden;
    left: 100vw;
    display: block;
    opacity:1;

    &.show {
      @include transitionDelay(0s);
      left: 0;

      .sliding-panel-overlay {
        opacity: 1;
      }
    }
    .header{
      @include transition(all .3s ease-out);
      @include translate($distanceX: 100%);
      position: fixed;
      top: 0; right: 0; left: 0;
      z-index: 20;
      width:auto;
      margin:0;
      left:rem-calc(100);

      &.open{
        @include translate($distanceX: 0);
      }
    }
    .content{
      @include transition(all .3s ease-out);
      @include translate($distanceX: 100%);
      overflow: auto;
      width:auto;
      margin:0;
      -webkit-overflow-scrolling: touch;
      position: fixed;
      top: 90px;
      right: 0;
      bottom: 0;
      z-index: 16;
      left:rem-calc(100);

      &.open{
        @include translate($distanceX: 0);
      }
    }
  }
}
