.mdl-textfield {
  position: relative;
  font-size: rem-calc(18);
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
}

.mdl-textfield .mdl-button {
  position: absolute;
  bottom: rem-calc(20);
}

.mdl-textfield--align-right {
  text-align: right;
}

.mdl-textfield--full-width {
  width: 100%;
}

.mdl-textfield--expandable {
  min-width: 32px;
  width: auto;
  min-height: 32px;
}

.mdl-textfield--expandable .mdl-button--icon {
  top: 16px;
}

.mdl-textfield__input {
  border: none;
  border-bottom: 1px solid #979797;
  display: block;
  font-size: rem-calc(18);
  margin: 0;
  padding: 0;
  width: 100%;
  background-position: 0 0;
  text-align: left;
  color: #4a4a4a;
  height: rem-calc(37);
}

.mdl-textfield__input[type="number"] {
  -moz-appearance: textfield;
}

.mdl-textfield__input[type="number"]::-webkit-inner-spin-button,
.mdl-textfield__input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mdl-textfield.is-focused .mdl-textfield__input {
  outline: none;
}

.mdl-textfield.is-invalid .mdl-textfield__input {
  border-color: #d50000;
  box-shadow: none;
}

fieldset[disabled] .mdl-textfield .mdl-textfield__input,
.mdl-textfield.is-disabled .mdl-textfield__input {
  background-color: transparent;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.mdl-textfield textarea.mdl-textfield__input {
  display: block;
}

.mdl-textfield__label {
  bottom: 0;
  color: #949494;
  font-size: rem-calc(18);
  left: 0;
  right: 0;
  pointer-events: none;
  position: absolute;
  display: block;
  top: rem-calc(8);
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.mdl-textfield.is-dirty .mdl-textfield__label,
.mdl-textfield.has-placeholder .mdl-textfield__label {
  visibility: hidden;
}
.mdl-textfield--floating-label {
  padding: rem-calc(24 0 0);
}

.mdl-textfield--floating-label .mdl-textfield__label {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: #4a4a4a;
  top: rem-calc(26);
}

.mdl-textfield--floating-label .mdl-textfield__input {
  padding-bottom: rem-calc(4);
  height: rem-calc(32);
}

.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
  transition: none;
}

fieldset[disabled] .mdl-textfield .mdl-textfield__label,
.mdl-textfield.is-disabled.is-disabled .mdl-textfield__label {
  color: rgba(0, 0, 0, 0.26);
}

.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
  font-size: rem-calc(14);
  top: rem-calc(2);
  visibility: visible;
}

.mdl-textfield--floating-label.is-focused .mdl-textfield__input,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__input,
.mdl-textfield--floating-label.has-placeholder .mdl-textfield__input {
  padding: rem-calc(0 0 8);
}

.mdl-textfield--floating-label.is-focused .mdl-textfield__label {
  color: #5697e4;
}

.mdl-textfield--floating-label.is-focused
  .mdl-textfield__expandable-holder
  .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty
  .mdl-textfield__expandable-holder
  .mdl-textfield__label,
.mdl-textfield--floating-label.has-placeholder
  .mdl-textfield__expandable-holder
  .mdl-textfield__label {
  top: -16px;
}

.mdl-textfield--floating-label.is-invalid .mdl-textfield__label {
  color: #d50000;
}

.mdl-textfield__label:after {
  background-color: #5697e4;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  visibility: hidden;
  width: 100%;
}

.mdl-textfield.is-focused .mdl-textfield__label:after {
  visibility: visible;
}

.mdl-textfield.is-invalid .mdl-textfield__label:after {
  background-color: #d50000;
}

.mdl-textfield__error {
  @extend .error, .-textfield-error;
}

.mdl-textfield.is-invalid .mdl-textfield__error {
  visibility: visible;
}

.mdl-textfield__expandable-holder {
  display: inline-block;
  position: relative;
  margin-left: 32px;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-block;
  max-width: 0.1px;
}

.mdl-textfield.is-focused .mdl-textfield__expandable-holder,
.mdl-textfield.is-dirty .mdl-textfield__expandable-holder {
  max-width: 600px;
}

.mdl-textfield__expandable-holder .mdl-textfield__label:after {
  bottom: 0;
}

.mdl-progress {
  display: block;
  position: relative;
  height: 10px;
  max-width: 100%;
  > .bar {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0%;
    transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }
  > .progressbar {
    background-color: rgb(0, 150, 136);
    z-index: 1;
    left: 0;
  }
  > .bufferbar {
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.7)
      ),
      linear-gradient(to right, rgb(0, 150, 136), rgb(0, 150, 136));
    z-index: 0;
    left: 0;
  }
  &:not(.mdl-progress--indeterminate):not(.mdl-progress--indeterminate)
    > .auxbar,
  &:not(.mdl-progress__indeterminate):not(.mdl-progress__indeterminate)
    > .auxbar {
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.7)
      ),
      linear-gradient(to right, rgb(0, 150, 136), rgb(0, 150, 136));
    -webkit-mask: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEyI…IwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L2VsbGlwc2U+Cjwvc3ZnPgo=);
    mask: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEyI…IwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L2VsbGlwc2U+Cjwvc3ZnPgo=);
  }
}

.light-tooltip + .mdl-tooltip {
  background: rgba(255, 255, 255, 0.9);
  color: $body-text-color;
}

.mdl-tooltip {
  @include border-radius(rem-calc(2));
  @include scale($xVal: 0, $yVal: 0);
  @include transformOrigin(top center);
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  border-radius: rem-calc(2);
  color: #fff;
  display: inline-block;
  font-size: rem-calc(12);
  font-weight: normal;
  line-height: rem-calc(14);
  //max-width: 170px;
  padding: rem-calc(5);
  position: fixed;
  top: rem-calc(-500);
  left: rem-calc(-500);
  padding: rem-calc(8);
  text-align: left;
}

.mdl-tooltip.is-active {
  -webkit-animation: pulse 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
  animation: pulse 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes pulse {
  0% {
    @include scale($xVal: 0, $yVal: 0);
    opacity: 0;
  }
  50% {
    @include scale($xVal: 0.99, $yVal: 0.99);
  }
  100% {
    @include scale($xVal: 1, $yVal: 1);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes pulse {
  0% {
    @include scale($xVal: 0, $yVal: 0);
    opacity: 0;
  }
  50% {
    @include scale($xVal: 0.99, $yVal: 0.99);
  }
  100% {
    @include scale($xVal: 1, $yVal: 1);
    opacity: 1;
    visibility: visible;
  }
}
