.tag-list {
  font-size: rem-calc(14);

  > .container {
    @include border-radius(3px);
    position: relative;
    border: 1px solid rgba(35, 35, 35, 0.6);
    padding: rem-calc(8 10 0 5);
    min-height: rem-calc(48);

    .taginput {
      border: none;
      margin: rem-calc(6 10 10 7);

      &:focus {
        outline: none;
      }

      &:focus + .searchborder {
        position: absolute;
        left: 0;
        bottom: -1px;
        height: 2px;
        width: 100%;
        background: #4a90e2;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    .count {
      position: absolute;
      right: 0;
      padding: 0 10px;
    }
  }
  .createdtags {
    list-style-type: none;
    padding: 0;
    display: inline;
    font-size: rem-calc(14);
    letter-spacing: normal;

    .tag {
      @include border-radius(2px);
      display: inline-block;
      margin: rem-calc(0 0 8 5);
      background: #ececec;
      white-space: nowrap;
      height: rem-calc(30);
      line-height: rem-calc(30);
      padding: rem-calc(0 10);
      transition: background-color .3s cubic-bezier(.4,0,.2,1);

      &.-deletable {
        display: inline-flex;
        align-items: center;
        padding-right: rem-calc(10);
      }

      &.highlight {
        background-color: #c8dfcc;
      }

      &:focus {
        background-color: #d1d1d1;
        outline: none;
      }

      .-lock{
        margin-left:rem-calc(8);
          &:before{
            line-height: 1.875;
          }
      }

      .delete {
        margin-left: rem-calc(8);
        font-size: rem-calc(14);
        min-height: 0;
        min-width: 0;
        height: rem-calc(20);
        width: rem-calc(20);

        &:before {
          @include mdi-icon;
          content: "\F156";
        }
      }
    }
  }
  &.-light {
    .createdtags .tag {
      background-color: #fff;
    }
  }
  &.-dark {
    .createdtags .tag {
      background-color: #d8d8d8;
    }
  }
  .tagsuggestions {
    @include box-shadow(0 0 14px 0 rgba(0, 0, 0, 0.14));
    padding: rem-calc(11 20 0);
    margin-bottom: rem-calc(30);
    background: #fff;
    position: absolute;
    z-index: 10;

    .selectionlist {
      list-style: none;
      padding: 0;
      margin: rem-calc(0 -20);
    }
    .taginstructions {
      margin: rem-calc(0 0 11);
    }
    .tag {
      padding: rem-calc(11 20);
      border-top: 2px solid #f4f3f6;

      &.selected {
        color: rgba(#4a4a4a, .5);
        font-style: italic;
      }

      &.active {
        background: #f4f4f4;
      }

      &:hover {
        background: rgba(46, 27, 70, 0.05);
        cursor: pointer;
      }

      a:hover {
        background: none;
      }
    }
  }

  .hide-sugestive-tags{
    display: none;
  }
  &.is-focused {
    > .container {
      @include box-shadow(0 0 0 1px #20cbd4);
      outline: none;
      border-color: #20cbd4;
    }
  }

  .focus{
    background: rgba(46, 27, 70, 0.05);
  }
}
