.main-nav {
  @include box-shadow(0 0 14px 0 rgba(0, 0, 0, 0.14));
  @include displayFlex;
  @include flexDirection(column);
  -ms-flex-preferred-size: auto;
  width: $main-nav-width;
  padding: 0;
  background: #fff;
  transform: translateZ(0);
  transform-style: preserve-3d;
  transition: width $nav-transition-duration, transform $nav-transition-duration;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  font-size: rem-calc(14);
  z-index: 13;
  @media only screen and (max-width: $mobile-nav-width) {
    @include translate($distanceX: -100%);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
  }

  &.expanded {
    .menuitem .menuname {
      display: block;
    }

    .hamburger-inner:before {
      transform: translate3d(-5px, 0, 0) rotate(-45deg) scaleX(0.6);
    }

    .hamburger-inner:after {
      transform: translate3d(-5px, 0, 0) rotate(45deg) scaleX(0.6);
    }
    @media only screen and (max-width: $mobile-nav-width) {
      @include translate($distanceX: 0);
    }
  }

  + #navoverlay {
    background: rgba(52, 55, 62, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 12;
    cursor: pointer;
  }

  .icon {
    @include flex(none);
    width: 26px;
    font-size: rem-calc(22);
    text-align: center;
  }

  .wmrklogo {
    @include flex(none);
    width: 20px;
    height: 23px;
    margin: 20px auto;
    transition: 0.3s;
    overflow: hidden;

    img {
      height: 23px;
      width: 137px;
    }
  }

  a {
    cursor: pointer;

    &:focus,
    &:hover {
      background-image: none !important;
    }
  }

  .menu,
  .submenu {
    list-style-type: none;
    margin: 0;
  }

  > .menu {
    @include flex(1);
    padding: 0;
    @media only screen and (max-width: $mobile-nav-width) {
      margin-top: rem-calc(80);
    }
  }

  .menu > .menuitem {
    background: #fff;

    &:last-child {
      margin-bottom: rem-calc(10);
    }
  }

  .menu > .active-parent .active:after {
    content: "\a0";
    display: inline-block;
    width: 5px;
    background: $button-default-text;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    box-shadow: 5px 0 16px 0 rgba($button-default-text, 0.46);
  }

  .submenu {
    padding: 0;
    display: none;
    transform: translateZ(0);
    transform-style: preserve-3d;
    transition-property: transform;
    transition-delay: 50ms;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    background: #fff;
  }

  .menuitem {
    position: relative;

    .submenu a,
    a {
      display: block;
      color: #6e6e6e;
      text-decoration: none;

      &:not(.active):hover {
        background-color: #f2f2f2;
      }

      &:hover {
        padding-bottom: rem-calc(20);

        .icon {
          &.selfstudy-icon {
            svg {
              #shape-batch {
                fill: #f2f2f2;
              }

              polyline {
                fill: #f2f2f2;
              }
            }
          }
        }
      }

      &.active {
        color: $button-default-text;
        background: #eeedf4;

        .icon {
          &.selfstudy-icon {
            svg {
              path {
                stroke: $button-default-text;
              }

              #shape-scroll {
                stroke: none;
                fill: $button-default-text;
              }

              #shape-batch {
                fill: #eeedf4;
              }

              polyline {
                fill: #eeedf4;
                stroke: $button-default-text;
              }
            }
          }
        }
      }
    }

    > a {
      padding: rem-calc(20 8);
      text-align: center;
      @media only screen and (max-width: $mobile-nav-width) {
        padding-top: rem-calc(16);
        padding-bottom: rem-calc(16);

        &:hover {
          padding-bottom: rem-calc(16);
        }
      }
    }

    .menuname {
      @include flex(1 1 auto);
      display: block;
      letter-spacing: normal;
    }
  }

  button {
    cursor: pointer;

    &.-icononly {
      border: none;
      padding: 0;
    }
  }

  .toggle-nav {
    font-size: 1.5rem;
    width: 3rem;
    height: 3rem;
    position: absolute;
    right: rem-calc(6);
  }
}

#mobile-nav-toggle,
#nav-toggle {
  @include border-radius(50%);
  border: none;
  width: 44px;
  height: 44px;
  padding: 0 12px;

  &:hover {
    background: #d7d6d9;
  }
}

body.user-is-tabbing #mobile-nav-toggle:not(:focus),
body.user-is-tabbing #nav-toggle:not(:focus),
body:not(.user-is-tabbing) #mobile-nav-toggle,
body:not(.user-is-tabbing) #nav-toggle {
  @include box-shadow(none);
}

#nav-toggle {
  position: absolute;
  right: 0;
  left: 0;
  top: 11px;
  margin: auto;
  background: #efeef1;
  display: none;
  @media only screen and (max-width: $mobile-nav-width) {
    display: block;
  }
}

#mobile-nav-toggle {
  margin-left: -60px;
  width: 60px;
  height: 60px;
  border-radius: 0;
  padding-left: 20px;
  display: none;

  &:not(:hover) {
    background: rgba($button-primary-background, 0);
  }
  @media only screen and (max-width: $mobile-nav-width) {
    margin-left: 0;
    display: block;

    &:after {
      content: "";
      background: #e6e4ef;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
    }
  }
}

.main-nav:not(.expanded) #nav-toggle:not(:hover) {
  background: none;
}

.hamburger-inner {
  top: 50%;
  display: block;
  margin-top: -2px;

  &,
  &:after,
  &:before {
    position: absolute;
    width: 20px;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: $nav-transition-duration;
    transition-property: transform;
    transition-delay: 0.3s;
    border-radius: 2px;
    background-color: $button-primary-background;
  }

  &:after,
  &:before {
    display: block;
    content: "";
  }

  &:before {
    top: -4px;
  }

  &:after {
    bottom: -4px;
  }
}

[dir="RTL"] .main-nav {
  @include box-shadow(
    -2px 0 4px 0 rgba(0, 0, 0, 0.14),
    -2px 0 4px 0 rgba(0, 0, 0, 0.24)
  );

  .icon {
    right: 29px;
    left: auto;
  }

  .menuitem {
    .submenu a.active,
    > a,
    a.active {
      padding-left: 0;
      padding-right: rem-calc(60);
    }
  }

  .toggle-subnav {
    left: -157px;

    &:before {
      content: "\f141";
    }
  }

  .menu > .active-parent a:after {
    right: 0;
    left: auto;
  }
}

.user-is-tabbing
  .menu
  .navlink:focus:not(:disabled):not(.-disabled):not(.-textonly):not(.-actionbutton):not(.selectedoption) {
  @extend %focus-styles-inset;
  z-index: 1;
  padding-bottom: rem-calc(20);
  @media only screen and (max-width: 1340px) {
    padding-bottom: rem-calc(16);
  }
}
