.top-bar {
  @include displayFlex;
  @include box-shadow(0 2px 4px 0 rgba(0, 0, 0, 0.09));
  @include flex(none);
  background: #fff;
  font-size: rem-calc(14);
  align-items: center;
  justify-content: space-between;
  min-height: rem-calc(60);
  height: rem-calc(32); // hack to get IE11 to vertically center logo
  z-index: 15;
  position: relative;

  @media only screen and (max-width: $mobile-nav-width) {
    z-index: 10;
  }

  [dir="RTL"] & {
    padding: rem-calc(0 30 0 0);

    @media only screen and (max-width: $mobile-nav-width) {
      padding-right: 0;
    }
  }

  .logo {
    @include flex(1);
    margin: 0 0 0 20px;

    img {
      display: block;
      width: 100px;
      height: 16px;
    }
  }

  .menu-bar {
    @include displayFlex;
    @include alignItems(center);

    > li {
      min-height: rem-calc(40);
      border-left: solid 1px rgba(46, 27, 70, 0.05);
    }
  }

  .oa-link {
    cursor: pointer;
  }

  .topbar-menu {
    position: relative;

    .icon {
      font-size: 1.0625rem;
      vertical-align: middle;
    }

    .topbar:last-child {
      margin-right: 0;
    }
  }

  .dropdown-select > .username.selectedoption:after {
    @include mdi-icon;
    @include translate($distanceY: -50%);
    position: absolute;
    top: 50%;
    pointer-events: none;
    content: "\f009";
    left: rem-calc(15);
  }

  .dropdown-select {
    min-width: rem-calc(160);

    > .selectedoption {
      border: none;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 35 / 14 * 1em;
      height: auto;

      &:after {
        @include mdi-icon;
        content: "\f5d8";
        position: absolute;
        left: rem-calc(15);
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }

    > .options {
      @include border-radius(0);
      top: rem-calc(60);

      &#oa-menu-listbox {
        width: rem-calc(200);
      }
    }
  }

  .dropdown-select > .selectedoption {
    @include border-radius(0);
    line-height: rem-calc(60);
    letter-spacing: 0.2px;
    color: #575195;

    &:hover:not(:disabled):not(.-disabled):not(.-raised) {
      background: rgba(0, 0, 0, 0.1);
    }
    .user-is-tabbing &:focus {
      @extend %focus-styles-inset;
    }
  }
  .open.dropdown-select > .selectedoption {
    background: rgba(0, 0, 0, 0.1);
  }

  #oa-menu .username {
    min-width: rem-calc(100);
  }

  #usersettings .username {
    min-width: rem-calc(50);
  }

  &.sso {
    height: 60/14 * 1em;

    .menu-bar {
      height: 60/14 * 1em;
    }

    .dropdown-select > .selectedoption {
      @include border-radius(0);
      line-height: rem-calc(60);
      height: 100%;
    }
  }

  .products.dropdown-select {
    height: 100%;
    min-width: 0;

    #switcher-menu {
      @include border-radius(0);
      position: relative;
      display: block;
      background: transparent;
      padding: 0 1.25rem;
      height: 100%;
      border: none;

      body:not(.user-is-tabbing) & {
        @include box-shadow(none);
      }

      &:hover {
        background: #dcdbde;
      }
      &:active {
        @include scale(1, 1);
      }

      img {
        height: 36px;
        width: 36px;
        display: block;
      }
    }

    .options {
      min-width: 210/14 * 1em;
      max-height: 80vh;
      width: auto;

      li {
        display: block;
        color: #404248;
        padding: 0.9375rem 1.25rem;

        &:hover,
        &.-active {
          background: #e5e5e5;
        }

        &.-active {
          cursor: default;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #e0dde4;
        }
      }

      #logprod {
        text-align: center;
      }

      .user,
      .producthdr {
        background: rgb(244, 244, 244);
        padding: 0.625rem 1.25rem;
        font-size: 0.875rem;
        letter-spacing: normal;

        p {
          margin: 0;
          line-height: normal;

          &.username {
            font-weight: 700;
          }
        }
      }

      .producthdr {
        background: #2e1b46;
        color: #fff;
        font-weight: 700;
        margin: 0;
      }
    }
    .iconlogo {
      height: 1.375rem;
      width: auto;
    }
  }

  @media only screen and (max-width: 767px) {
    #usersettings .username {
      white-space: nowrap;
      overflow: hidden;
      max-width: rem-calc(160);
      text-overflow: ellipsis;
    }
  }
}

[dir="RTL"] .top-bar {
  .menu-bar > li {
    border-left: none;
    border-right: solid 1px rgba(46, 27, 70, 0.05);
  }

  .dropdown-select > .selectedoption {
    padding-left: 30 / 14 * 1em;
    padding-right: 35 / 14 * 1em;

    &:before {
      left: rem-calc(9);
      right: auto;
    }
    &:after {
      right: rem-calc(15);
      left: auto;
    }
  }
}
