.node-list {
  &.-sections {
    max-width:rem-calc(700);
    width:100%;

    .node > .title {
      padding-right:rem-calc(150);
      position:relative;
    }

    .date {
      @include type-level-6;
      font-weight: normal;
      position:absolute;
      right:0;
      top:3px;
    }
  }

  > .children {
    padding:rem-calc(0 0 0 15);
  }
  .children .node > .title {
      font-weight: bold;
      text-decoration: none;
  }

  .node {
    line-height:1.5;

    .title {
      @include type-level-4;
      color:#7d7d7d;
      line-height:2.0;
      margin:rem-calc(10 0);

      > .link {
        cursor:pointer;
        text-decoration:underline;
      }

      > .info {
        @include type-level-6;
        font-weight: normal;
        font-style: italic;
      }
    }
  }
}
