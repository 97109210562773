body {
  @include displayFlex;
  @include flexDirection(column);
}

.home {
  @include displayFlex;
  @include flex(1);
  @include flexDirection(column);

  .largearea {
    @include flex(1);
    padding: rem-calc(40);
  }

  img {
    height: 36px;
  }
  #logo {
    max-width: rem-calc(220);
    margin: 0 auto rem-calc(40);
  }
  .page-header {
    font-weight: normal;
    font-size: 2.25rem;
    color: #2e1b46;
    text-align: center;
  }
  .page-header.welcome {
    margin: 0;
    letter-spacing: 0.1px;
  }
  .paper-card.-margintop25 {
    margin-top: rem-calc(25);
  }
  .login .buttoncontainer,
  .findemail .buttoncontainer,
  .setpassword .button[type="submit"],
  .resetpassword .button[type="submit"] {
    margin-top: rem-calc(25);
  }
  .login .buttoncontainer .button,
  .findemail .buttoncontainer .button,
  .checkemail .buttoncontainer .button {
    margin-right: rem-calc(25);
  }
  .termsandconditions {
    &.paper-card {
      max-width: rem-calc(506);
      padding: rem-calc(20 30 0 30);

      .body {
        @include box-shadow(inset 0px -4px 10px -10px rgba(0, 0, 0, 0.7));
        padding: 0;
      }

      > .footer {
        @include box-shadow(0 -4px 10px 0 rgba(46, 27, 70, 0.11));
        border-top: none;
        margin: rem-calc(0 -30);
        padding: 20px 30px;
        text-align: left;
      }
    }
    .alert {
      background-color: #f4f3f6;
      padding: rem-calc(20 30);
    }
    .agreementcontainer {
      color: #444;
      letter-spacing: rem-calc(0.17);
      max-height: rem-calc(234);
      overflow-x: hidden;
      overflow-y: scroll;
      padding: rem-calc(20 30 0);

      h3 {
        @include type-level-4;
        font-weight: normal;
        margin: 0;
      }

      p {
        margin: rem-calc(0 0 20);
      }
      span {
        display: block;
      }
    }
    .agreementaccept {
      margin-bottom: rem-calc(20);
      position: relative;
    }
    .chkbx-label.chkbx {
      @include type-level-4;
      color: $body-text-color;
      display: block;
      padding-left: rem-calc(25);
      margin: 0;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .-primary {
      margin-right: rem-calc(25);
    }
  }
  ul.chooselang {
    display: none;
    font-size: 0.875rem;
    position: relative;
    text-align: center;
    margin-top: rem-calc(30);
  }
  .chooselang:focus {
    outline: none;
  }
  .chooselang .langoption {
    display: inline-block;
    line-height: 1.85;
    font-weight: 500;
  }
  .chooselang .langoption:first-child:before {
    display: inline-block;
    font: normal normal normal 24px/1 Material Design Icons;
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\F1E7";
    font-size: 1.5rem;
    vertical-align: middle;
    margin-right: 1.25rem;
  }
  .productsuite {
    padding: rem-calc(30 60);
    margin: rem-calc(116 -40 0);
    background: rgba(#575195, 0.04);

    .icon {
      height: rem-calc(24);
      width: auto;
    }

    h3 {
      text-align: center;
      color: #0a808b;
      font-size: 1.375rem;
      letter-spacing: rem-calc(0.2);
      font-weight: 500;
    }
    .watermarksuite {
      @include displayFlex;
      @include flexWrap(wrap);
      @include justifyContent(center);
      @include alignItems(center);
      margin: rem-calc(0 -25);
    }
    .product {
      @include flex(none);
      margin: rem-calc(24 20 0);
    }
  }

  .paper-card .information {
    margin-top: rem-calc(20);
  }

  .checkemail .paper-card p {
    margin-bottom: rem-calc(20);
  }

  div[dir="RTL"] .chooselang .langoption:first-child:before {
    margin-right: 0;
    margin-left: 1.25rem;
  }

  .chooselang .langoption:not(:first-child):before {
    content: "|";
    display: inline-block;
    margin: 0 0.75rem;
  }

  .chooseproduct #logo {
    margin-bottom: rem-calc(64);
  }
  .chooseproduct .availableproducts {
    margin-top: rem-calc(10);
    padding-left: calc(50% - 193px);
    padding-right: calc(50% - 193px);
  }
  .chooseproduct .availableproducts .product {
    margin: rem-calc(30) auto 0;
  }
  .chooseproduct .availableproducts .product .button {
    height: rem-calc(76);
    width: 100%;
    text-align: center;
    border-radius: rem-calc(12);
  }
  .chooseproduct .availableproducts .product svg {
    fill: #fff;
    height: rem-calc(24);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  .chooseproduct .availableproducts .product svg.logo {
    height: rem-calc(32);
  }
  .chooseproduct .availableproducts .product svg.largelogo {
    height: rem-calc(36);
  }
  .chooseproduct .availableproducts .product.via svg,
  .chooseproduct .availableproducts .product.livetext svg {
    height: rem-calc(20);
  }
  .paper-card {
    max-width: rem-calc(420);
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.14);
    border-radius: rem-calc(5);
    margin: 0 auto;
  }
  .paper-card .header {
    border-bottom: 1px solid #2e1b46;
  }
  .paper-card .header .title {
    font-size: 1.125rem;
    letter-spacing: rem-calc(0.3);
  }
  .paper-card .body {
    padding: rem-calc(20 30 0);
  }
  .textfield + .textfield {
    margin-top: 1.25rem;
  }
  .textfieldlabel {
    font-weight: 500;
    display: block;
    letter-spacing: rem-calc(0.3);
  }
  .textfieldinput {
    @include border-radius(3);
    border: 1px solid rgba(35, 35, 35, 0.6);
    font-size: 0.875rem;
    height: rem-calc(44);
    padding: 0.625rem 0.9375rem 0.5rem;
    width: 100%;
    -webkit-appearance: none;
  }
  .textfieldinput:focus {
    @include box-shadow(0 0 0 1px #20cbd4);
    outline: none;
    border-color: #20cbd4;
  }

  .textfield.-error .textfieldlabel {
    color: #c0392b;
  }

  .textfield.-error .textfieldlabel::after {
    display: inline-block;
    font: normal normal normal 24px/1 Material Design Icons;
    font-size: 24px;
    line-height: 1;
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\F026";
    margin-left: 0.3125rem;
  }

  .textfield.-error .textfieldinput {
    @include box-shadow(0 0 0 1px #c0392b);
    border-color: #c0392b;
  }

  .textfield.-error .input-error {
    font-style: italic;
    display: block;
    margin-top: 0.3125rem;
    color: #c0392b;
  }

  .error.input-error {
    margin-bottom: rem-calc(15);
    position: relative;
  }
}

.contentwrapper > .footer,
.home > .footer,
.program-identity .main-panel > .footer,
main + .footer,
.maincontentfooter {
  background-color: #dedede;
  color: $body-text-color;
  font-size: rem-calc(14);
  padding: rem-calc(7 40);
  text-align: center;

  a {
    color: $body-text-color;
  }

  & > div {
    display: inline-block;
    text-align: center;
    margin: rem-calc(4 25);
  }

  .companyname,
  .footerlink {
    font-weight: 600;
  }

  .footerlink:hover {
    background-image: linear-gradient($body-text-color, $body-text-color);
  }

  .footernav li {
    display: inline-block;
    border-right: 1px solid $body-text-color;
    padding: rem-calc(3 18);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  }
}

.home > .footer {
  background-color: #2e1b46;
  color: #fff;
  padding: rem-calc(14 40);

  a {
    color: #fff;
  }

  .footernav li {
    border-right-color: #c8c8c8;
  }

  .footerlink:hover {
    background-image: linear-gradient(#c8c8c8, #c8c8c8);
  }
}

.user-is-tabbing .chooselang:focus .focused,
.user-is-tabbing .login a:focus {
  background: linear-gradient(90deg, #3862e9 66%, transparent 0) repeat-x;
  background-size: 6px 3px;
  background-position: 0 1em;
}
