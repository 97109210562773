.charcount {
  position: relative;
  background: #fff;

  input.textfieldinput:focus {
    padding-right: rem-calc(90);

    [dir="RTL"] & {
      padding-right: rem-calc(15);
      padding-left: rem-calc(90);
    }
  }
  &.-textarea {
    @include border-radius(3px);
    border: 1px solid rgba(35, 35, 35, 0.6);

    .textfieldinput {
      @include box-shadow(none);
      border: none;

      + .count {
        top: auto;
        left: 0;
        height: auto;
      }
    }
  }

  .count {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    font-size: rem-calc(14);
    text-align: right;
    display: none;
    padding: rem-calc(1 20);
    background: rgba(74, 74, 74, 0.05);
    height: 100%;
    line-height: rem-calc(36);
    letter-spacing: 0.3px;

    [dir="RTL"] & {
      text-align: left;
      right: auto;
      left: 0;
    }
  }

  .is-focused &.-textarea {
    padding-bottom: rem-calc(38);
    @extend %field-focus;

    .textfieldinput {
      @include box-shadow(none);
      border: none;
    }
  }

  .invalid &.-textarea {
    @extend %invalid;
  }
}

.textfieldlabel {
  font-weight: 600;
  display: inline;

  &.required::after {
    color: #c4431c;
    content: "*";
    font-size: rem-calc(16);
    margin-left: rem-calc(3);
  }

  + .textfieldinput,
  + .charcount,
  + wm-select {
    margin-top: rem-calc(10);
    display: block;
  }
}

.textfield {
  position: relative;
  font-size: rem-calc(14);

  + .textfield {
    margin-top: rem-calc(30);
  }

  .textfieldinput {
    @include border-radius(3px);
    border: 1px solid rgba(35, 35, 35, 0.6);
    font-size: rem-calc(14);
    padding: rem-calc(0 15);
    width: 100%;
    height: rem-calc(40);

    @media only screen and (max-width: 768px) {
      height: rem-calc(44);
    }

    &:focus {
      @extend %field-focus;

      + .count {
        display: block;
      }
    }
    &.invalid {
      @extend %invalid;
    }
    &:disabled,
    &.-disabled {
      background-color: #f0f0f0;
      border: 1px solid #8a8a8a;
      color: #737373;
    }
  }
  textarea.textfieldinput {
    resize: vertical;
    min-height: rem-calc(36);
    padding: rem-calc(10 15 8);

    &:disabled,
    &.-disabled {
      background-color: #f0f0f0;
      border: 1px solid #8a8a8a;
      color: #737373;
    }
  }

  .help-text {
    margin-top: rem-calc(5);
    font-style: italic;
  }
}

.error {
  color: $error-color;

  &.-textfield-error {
    color: #d50000;
    position: absolute;
    font-size: 12px;
    margin-top: 3px;
    visibility: hidden;
    display: block;
    top: 100%;
    left: 0;
  }

  &.textfieldlabel:after {
    @include mdi-icon;
    content: "\f026";
    margin-left: rem-calc(5);

    [dir="RTL"] & {
      margin-left: 0;
      margin-right: rem-calc(5);
    }
  }

  &.input-error {
    font-style: italic;
    display: block;
    margin-top: rem-calc(5);
  }
}

.textfield.error {
  color: $body-text-color;

  .textfieldlabel {
    color: $error-color;

    &:after {
      @include mdi-icon;
      color: $error-color;
      content: "\f026";
      margin-left: rem-calc(5);

      [dir="RTL"] & {
        margin-left: 0;
        margin-right: rem-calc(5);
      }
    }
  }

  .textfieldinput {
    @extend %invalid;
  }

  .input-error {
    color: $error-color;
    font-style: italic;
    display: block;
    margin-top: rem-calc(5);
  }
}

.is-invalid .error {
  visibility: visible;
}
