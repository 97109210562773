.grid{
    position:relative;
    overflow-x: auto;
    overflow-y:hidden;
    margin:0;
    padding:0;
    -webkit-overflow-scrolling: touch;
    >.empty-state{
      margin-top: 150px;
    }
    .common-cell{
      position: fixed;
      z-index: 3;
      min-width: rem-calc(362);
      box-sizing: border-box;
      padding:rem-calc(20 0 20 50);
      background: #fff;
      text-align: center;
      vertical-align: middle;
      border-right: solid 3px rgba(74, 74, 74, 0.5);
      font-size: rem-calc(14);
      font-weight: 500;
      letter-spacing: rem-calc(0.3);
      text-align: left;
      text-transform: uppercase;
    }
    .columnheader{
      text-align: center;
      vertical-align: middle;
    }

    .grid-header+.row{
      margin-top: rem-calc(58);
    }
    .row{
      width: 100%;
      background: #fff;
      display: flex;
      flex-wrap: nowrap;
      box-sizing: border-box;
    }
    .rowheader{
      flex-direction: column;
      position: fixed;
      left: 0;
      z-index: 1;
      background: #fff;
      min-width: rem-calc(362);
      border-right: solid 3px rgba(74, 74, 74, 0.5);
      border-bottom: solid 2px rgba(74, 74, 74, 0.1);
      box-sizing: border-box;
      height: rem-calc(79);
      padding:rem-calc(20 0 20 50);
      background: #fff;
      text-align: left;
      >span{
        display: block;
        font-size: 14px;
        letter-spacing: 0.3px;
      }
      +.gridcell{
          margin-left: rem-calc(364);
      }
    }
    .gridcell{
      flex-direction: column;
      margin-left: 0;
      width: rem-calc(132);
      min-width: rem-calc(132);
      box-sizing: border-box;
      height: rem-calc(79);
      padding: 0;
      border-right: solid 2px rgba(74, 74, 74, 0.1);
      border-bottom: solid 2px rgba(74, 74, 74, 0.1);
      .textfieldinput{
        min-width: rem-calc(130);
        height: rem-calc(77);
        border: none;
        text-align: center;
        -webkit-border-radius: 0;
        border-radius: 0;
        &:hover{
          background-color: rgba(46, 27, 70, 0.05);
        }
        &:focus{
          border: solid 2px #0da1ae;
          box-shadow: none;
        }
        .invalid{
          color: #c0392b;
          border: solid 2px #c0392b;
          box-shadow: none;
        }
      }
    }
    .invalid{
      &:focus{
        border: solid 2px #c0392b;
        box-shadow: none;
      }
    }
    .button-wrap{
      position:fixed;
      top: rem-calc(8);
      right: rem-calc(8);
      z-index:3;
    }

    .grid-header{
       position: fixed;
       z-index: 2;
       left:0;
       right: 0;
       overflow: hidden;
       @include shadow4;
       background: #fff;
       display: flex;
       flex-wrap: nowrap;
       box-sizing: border-box;
       text-transform: uppercase;
       >span{
         &:first-child{
           min-width: rem-calc(362);
         }
         padding:rem-calc(20 0) ;
         font-size: rem-calc(14);
         font-weight: 500;
         letter-spacing: rem-calc(0.3);
         text-align: center;
         min-width: rem-calc(132);
         box-sizing: border-box;
         cursor: context-menu;
         &:hover{
           background-color: rgba(46, 27, 70, 0.05);
         }
       }
    }
  }
