.main-footer {
  @include displayFlex;
  align-items: center;
  justify-content: space-between;
  background: #f8f8f8;
  height: 56px;
  padding: 12px 30px;
  color: #4a4a4a;
  font-family: "Open Sans", sans-serif;
  font-size: .75rem;

  .getassistance {

    .icon {
      margin-right: 8px;
      font-weight: normal;
    }
  }
}
