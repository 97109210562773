.snackbar{
 font-size: rem-calc(14);
 color: #fff ;
 border-radius: 2px;
 padding: rem-calc(30 230 30 30);
 position: fixed;
 bottom: rem-calc(30);
 left: rem-calc(30);
 >.mdi{
   position: absolute;
   right: rem-calc(30);
   top:rem-calc(20);
 }
}

.snack-bars {
  position: fixed;
  bottom: rem-calc(0);
  left: rem-calc(20);
  right: rem-calc(20);
  padding: rem-calc(4 10 30);
  z-index: 2001;
  max-width: rem-calc(568);
  max-height: rem-calc(280);
  -webkit-overflow-scrolling: touch;
  overflow: auto;

  &.empty {
    @include transition(all 0.25s 0ms cubic-bezier(0.4, 0, 1, 1));
    padding-top: 0;
    padding-bottom: 0;
  }
}

.snack-bar {
  @include shadow8;
  @include displayFlex;
  @include alignItems(center);
  @include transition(all 0.25s 0ms cubic-bezier(0.4, 0, 1, 1));
  font-size: rem-calc(14);
  color: #fff ;
  padding:rem-calc(20 30);
  background-color: $snackbar-color;
  min-height:rem-calc(60);
  opacity: 0;
  position: relative;

  .link {
    color:$snackbar-link-color;
    margin:rem-calc(0 50 0 10);

    &:hover {
      text-decoration:underline;
    }
  }

  &:not(:last-child) {
    margin-bottom: rem-calc(20);
  }

  &.active {
    opacity: 1;
  }

  &.deleted {
    @include scale($xVal: 1, $yVal:0);
    overflow: hidden;
    margin-bottom: 0;
    // opacity: 1;

    .close {
      display: none;
    }
  }
  .snackbarmsg {
    @include flex(1);

    + .close .tooltip.-right {
      position: absolute;
      left: rem-calc(-150);
      top: rem-calc(2);
      opacity: 0;
    }
  }
  .close {
    color: set-color($snackbar-color, $button-default-text, #fff);

    &:hover {
      background: set-color($snackbar-color, #d7d6d9, #fff);
      color: set-color($snackbar-color, $button-default-text, $snackbar-color);
    }
  }
}

.user-is-tabbing .snack-bar .link:not(.button):not([role]):focus {
  background: linear-gradient(90deg, $snackbar-link-color 66%, transparent 0) repeat-x;
  background-size: rem-calc(6 3);
  background-position: 0 1em;

  &:hover {
    text-decoration:none;
  }
}
