@import "../../common-style/css/settings";
@import "../../common-style/css/mixins";
@import "../../common-style/css/functions";
@import "../../common-style/css/normalize";
@include normalize();

@import "../../common-style/css/base";
@import "../../common-style/css/objects";
@import "../../common-style/css/components";

#student-submission-flyout {
  .heading {
    color: rgb(74, 74, 74);
    font-size: rem-calc(14);
    font-weight: 300;
    text-transform: uppercase;
  }
  .flyout-title {
    @media screen and (max-width: 1204px) {
      max-width: 350px;
    }
    max-width: 650px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
  .requirement-status {
    margin-right: rem-calc(16);
  }
  .req-details-card {
    .description-block {
      padding-top: rem-calc(20);
    }
    .title-block {
      color: rgb(74, 74, 74);
      font-size: rem-calc(16);
      font-weight: 500;
    }
  }
  .paper-card {
    &.-card {
      position: relative;
      z-index: 20;
    }
  }
  .panels {
    height: calc(100vh - 90px);
    height: -webkit-calc(100vh - 90px);
  }
  .panels .mini-panel .body {
    margin-top: 0;
  }
}

#export-submission-btn {
  margin: rem-calc(26 58 0 );
}

.export-disabled-text {
  margin: 0;
  padding: rem-calc(16 30);
  font-weight: 500;
  font-style: italic;
  font-size: rem-calc(14);
  border-bottom: 1px solid rgb(231, 231, 231);
}

.student-extract-btn {
  margin-left: rem-calc(16);
}

#timelog-submission-flyout {
  .content-height {
    height: -webkit-calc(100vh - 194px);
    height: calc(100vh - 194px);
  }
  .admin-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .go-back-link .bread-crumbs {
        color: rgb(74, 74, 74);
        font-size: rem-calc(14);
        font-weight: normal;
        letter-spacing: 0;
        line-height: rem-calc(20);
        margin-bottom: 0;
      }
  }
  .paper-card {
    &.-card {
      position: relative;
      z-index: 20;
    }
    .heading {
      color: rgb(74, 74, 74);
      font-size: rem-calc(14);
      font-weight: 300;
      text-transform: uppercase;
    }
    .flyout-title {
      @media screen and (max-width: 1204px) {
        max-width: 350px;
      }
      max-width: 650px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
    .requirement-status {
      margin-right: rem-calc(16);
    }
  }
  .req-submission-content {
    margin-left: rem-calc(32);
    margin-right: rem-calc(32);
    #requirement-submission-details {
      .user-is-tabbing &:focus {
        box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%), 0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
        outline: none;
      }
      .body {
          padding: rem-calc(16 24 0);
          .status-section {
            padding-bottom: rem-calc(24);
            .status-label {
              margin: 0;
              font-size: rem-calc(14);
              font-weight: 600;
              line-height: rem-calc(20);
              text-align: left;
              text-transform: capitalize;
            }
            .requirement-status {
              padding-left: rem-calc(8);
            }
          }
          .body-section {
            border-top: 1px solid rgb(229, 229, 229);
            padding: rem-calc(24 0);
            .description-block, .no-requirements {
              padding-top: rem-calc(12);
            }
            .app-details-section {
              margin-bottom: rem-calc(16);
              .app-details-value {
                padding: 0;
                list-style-type: none;
                margin: 0;
                margin-bottom: rem-calc(4);
              }
              .app-details-label {
                font-size: rem-calc(14);
                font-weight: 600;
                padding-bottom: rem-calc(4);
                padding-right: rem-calc(4);
                margin: 0;
              }
            }
          }
      }
    }
    #submission-details-card {
      .user-is-tabbing &:focus {
        box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%), 0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
        outline: none;
      }
      .body {
        padding: rem-calc(30 28);
        width: 100%;
        height: rem-calc(232);
        display: flex;
        align-items: flex-start;
        .body-section {
          width: 45%;
          &.-chart {
            width: 55%;
          }
          .approved-section {
            padding: rem-calc(0 0 20);
          }
          .approved-vs-threshold {
            font-size: rem-calc(32);
            font-weight: normal;
            line-height: rem-calc(26);
          }
          .approved-hours-text {
            padding: rem-calc(0 16);
            font-size: rem-calc(14);
            line-height: rem-calc(20);
          }
          .total-submitted {
            .total-submitted-label {
              margin: 0;
              font-size: rem-calc(14);
              font-weight: 600;
              line-height: rem-calc(20);
              padding-bottom: rem-calc(2);
            }
          }
        }
      }
    }
    #logged-entries-card {
      .user-is-tabbing &:focus {
        box-shadow: 0 2px 2px 0 rgb(244 243 246 / 20%), 0 2px 2px 0 rgb(0 0 0 / 10%), 0 0 4px 3px #61279e;
        outline: none;
      }
      .data-table {
        .dataheaderrow th {
          padding-left: rem-calc(30);
          font-size: rem-calc(12);
          text-transform: uppercase;
          width: auto;
          background: rgb(238, 237, 244);
          border-radius: rem-calc(5 5 0 0);
          height: rem-calc(40);
          text-align: left;
          &.date-at-site-header {
            width: 25%;
          }
          &.category-header {
            width: 15%;
          }
          &.duration-header {
            width: 15%;
          }
          &.files-header {
            width: 15%;
          }
          &.status-header {
            width: 20%;
          }
        }
        .databody .datarow .td-content {
          word-break: break-all;
          min-width: 0%;
          padding: rem-calc(14 24);
          font-size: rem-calc(14);
          line-height: rem-calc(20);
          padding-left: rem-calc(30);
          .updated-at {
            margin: 0;
            padding-top: rem-calc(4);
            font-style: italic;
          }
          .no-files {
            font-style: italic;
          }
        }
        tbody tr {
          background: rgb(250, 250, 250);
          &:nth-child(odd) {
              background-color: rgb(255, 255, 255);
          }
        }
        tbody:last-child tr {
          border-radius: rem-calc(0 0 5 5);
        }
      }
    }
  }
}

.requirement-status {
  margin-right: rem-calc(20);
}

#submission-details-card {
  .no-file-state {
    color: rgb(107, 107, 107);
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
    padding-top: rem-calc(4);
  }
  .ack-label {
    color: rgb(74, 74, 74);
    font-size: 16px;
    font-weight: 600;
    padding-bottom: rem-calc(12);
  }
  .-marker {
    &:before {
      content: "";
      display: block;
      margin: rem-calc(20 0);
      border: 1px solid rgb(229, 229, 229);
    }
  }
  .-no-marker {
    margin-bottom: rem-calc(8);
  }
  .descriptions {
    .admin-textfieldlabel {
      color: rgb(74, 74, 74);
      font-size: 14px;
      font-weight: 600;
    }
    .textfieldlabel {
      color: rgb(74, 74, 74);
      font-size: 16px;
      font-weight: 600;
    }
    .options {
      margin-top: rem-calc(12);
      .textfieldlabel-option {
        color: rgb(74, 74, 74);
        font-size: 14px;
        font-weight: 600;
      }
    }
    .no-file-empty-msg {
      color: rgb(74, 74, 74);
      font-size: rem-calc(22);
      font-weight: normal;
      text-align: center;
      padding: rem-calc(40 0 60);
    }
  }
  .file-container {
    padding: rem-calc(0 0 20 0);
  }
  .helper-text {
    margin-top: rem-calc(-12);
    color: rgb(74, 74, 74);
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
  }
}

.infos {
  text-align: center;
  height: rem-calc(113);
  margin-top: rem-calc(22);
  margin-bottom: rem-calc(22);
}

.infos .met-student-info-label {
  font-size: 1.5rem;
}

.infos .notmet-student-info-label {
  font-size: rem-calc(14);
  margin: rem-calc(0 96);
  @media screen and (max-width: 689px) {
    margin: rem-calc(0 12);
  }
}

.infos .notmet-student-info-label-admin {
  font-size: rem-calc(14);
  margin: rem-calc(0 80);
  @media screen and (max-width: 689px) {
    margin: rem-calc(0 12);
  }
}

.notmet-student-info-text {
  width: rem-calc(472);
}

.show-hide-btn {
  padding-bottom: rem-calc(20);
}
.from-admin {
  margin-top: 10px;
  color: rgb(74, 74, 74);
  font-size: 14px;
  font-weight: bold;
}

.student-archive-content {
  background: rgb(238, 237, 244);
  border-radius: 0;
  height: rem-calc(40);
  margin-left: rem-calc(-100);
  margin-right: rem-calc(-100);
  margin-top: rem-calc(-24);
  margin-bottom: rem-calc(8);
  z-index: 0;
  .archive-warning-text {
    color: rgb(87, 81, 149);
    font-size: rem-calc(14);
    font-style: italic;
    font-weight: 500;
    text-align: center;
    padding-top: rem-calc(11);
  }
}

.export-message-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .export-pending {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .export-message-text {
      margin: 0;
      padding-top: rem-calc(20);
  }
  .export-failed-message {
    color: rgb(192, 57, 43);
  }
  .export-message-sub-text {
      font-size: rem-calc(14);
      text-align: center;
      margin-bottom: rem-calc(28);
  }
  .export-primary-button{
    align-items: center;
    text-align: center;
  }
}