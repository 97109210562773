
#skip-to-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .skip-to {
    position: absolute;
    top: -5em;
    left: 50%;
    z-index: 1000;
    transform: translateX(-50%) translateZ(0);
    transform-style: preserve-3d;
    transition-delay: .1s;
    transition-duration: .2s;
    transition-property: transform, top;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    padding: 3px 10px;
    background: #fff;
    font-size: rem-calc(16);
    margin: 0 auto;

    &:focus {
      top: 1rem;
      background: linear-gradient(90deg, #3862E9 66%, transparent 0) repeat-x;
      background-size: rem-calc(6 3);
      background-position: 0 rem-calc(20);
    }

    &.-dark {
      color:#fff;
    }
  }
  #skip-menu-options {
    @include box-shadow(0 4px 15px 0 rgba(0, 0, 0, 0.2));
    @include border-radius(5px);
    display: none;
    position: absolute;
    top: 1.5rem;
    left: 50%;
    z-index: 1000;
    transform: translateX(-50%);
    list-style-type: none;
    padding:0;
    margin: 0;
    text-align: center;
    background: #fff;
    font-size: rem-calc(14);

    .skiplink {
      padding: rem-calc(10 20);
      display: block;
      color: $body-text-color;
      font-weight: 400;

      &:not(:last-child) {
        border-bottom: 2px solid rgba(46, 27, 70, 0.05);
      }

      &:hover {
        background-image: none;
      }

      &.focus {
        background: rgba(46, 27, 70, 0.05);
      }
    }
  }
  &.open {
    #skip-menu-options {
      display: block;
    }
  }
}
