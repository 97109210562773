.page-banner {
  @include box-shadow(0 2px 19px 0 rgba($primary-color, .05));
  background: $banner-header-background;
  color: set-color($banner-header-background, $body-text-color, #fff);
  padding-top: rem-calc(35);
  padding-bottom: rem-calc(35);
  padding-left: $main-content-padding;
  padding-right: $main-content-padding;
  position: relative;
  z-index: 9;

  &.-withbreadcrumbs {
    padding-top: rem-calc(17);
  }

  &.-mainheader {
    background: #fff;
    color: $body-text-color;
  }

  .pagecontent > & {
    margin-left: $neg-main-content-padding;
    margin-right: $neg-main-content-padding;

    + .tab-list {
      margin-left: $neg-main-content-padding;
      margin-right: $neg-main-content-padding;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: rem-calc(35 20);
  }
}

.selectstatus {
  font-size: rem-calc(14);
  padding-right: rem-calc(60);
  font-style: italic;
  text-align: left;
  color: #ffffff;
  white-space: nowrap;
}
