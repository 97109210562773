.accordion-list {
  @extend .nobullets;

  > .panel {
    -ms-transition: width 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94), margin 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transition: width 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94), margin 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition: width 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94), margin 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: width 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94), margin 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    padding: rem-calc(20);
    position: relative;

    &.-expanded {
      width: calc(78% + 40px);
      margin-top: rem-calc(20);

      &:not(:last-child) {
        margin-bottom: rem-calc(20);
      }
    }
  }
}
