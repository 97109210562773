$header-height: rem-calc(87);

.page-header {
  @include flex(1);

  > .title {
    margin: rem-calc(0 0 40);
    font-weight: 500;
    font-size: rem-calc(28);
    letter-spacing: 0.3px;

    .subtitle,
    .flyout-panel & .subtitle {
      font-size: rem-calc(14);
      display: block;
      text-transform: none;
      font-weight: 700;
      line-height: rem-calc(24);
    }

    .supertitle,
    .flyout-panel & .supertitle {
      font-size: rem-calc(14);
      display: block;
      text-transform: uppercase;
      font-weight: 400;
      line-height: 1.71;
      letter-spacing: 0.2px;
    }

    &.-small {
      font-size: rem-calc(14);
      display: inline-block;
      margin: 0;
      text-transform: uppercase;
      font-weight: 600;
    }

    + p {
      margin-top: rem-calc(8);
    }
  }

  &.-titleonly > .title {
    margin: 0;
  }

  .button.cancel + .title,
  .button.goback + .title {
    display: inline-block;
    margin: 0;
  }

  .assessmentlead {
    font-size: rem-calc(14);

    .icon {
      font-size: rem-calc(15);
      margin-right: rem-calc(4);
    }
  }

  .flyout-panel & {
    margin-bottom: 0;

    > .title {
      margin-bottom: 0;
    }
  }
  [dir="RTL"] & {
    &:not(.-titleonly) {
      margin: rem-calc(0 0 0 40);
    }
  }
}
