.stepper {
  @include displayFlex;
  @include justifyContent(space-between);
  position: relative;
  padding: 1.125rem calc(50% - 26.5625rem);
  margin: rem-calc(0 -30 25);
  background: #f4f4f4;

  &.-double {
    padding-left: calc(50% - 20.1325rem);
    padding-right: calc(50% - 20.1325rem);
  }
  $class-slug: step !default;
  @for $i from 1 through 5 {
    .#{$class-slug}:nth-child(#{$i}) .ordinal:after {
      content: "#{$i}";
    }
  }

  .step {
    @include displayFlex;
    @include alignItems(center);
    font-size: rem-calc(14);
    font-weight: 500;
    background: #f4f4f4;
    position: relative;
    padding: rem-calc(0 38);

    &.active {
      .ordinal {
        @include box-shadow(0 0 0 3px #f4f4f4, 0 0 0 5px $body-text-color);
        border-color: $body-text-color;
        background: $body-text-color;
        color: #fff;
        width: rem-calc(22);
        height: rem-calc(22);
        line-height: rem-calc(22);
        margin-right: rem-calc(13);
      }
    }

    &.completed {
      .ordinal {
        @include box-shadow(0 2px 4px 0 rgba(0, 0, 0, 0.5));
        background: $body-text-color;

        &:after {
          content: "\f12c";
          font-family: "Material Design Icons";
          color: #fff;
        }
      }

      &:not(:last-child):after {
        background: $body-text-color;
      }
    }

    &:not(:last-child):after {
      content: "";
      position: absolute;
      left: 100%;
      right: -75%;
      height: 1px;
      background: $body-text-color;
    }
  }

  .ordinal {
    @include flex(none);
    @include box-shadow(0 0 0 1px $body-text-color);
    display: inline-block;
    border-radius: rem-calc(15);
    width: rem-calc(29);
    height: rem-calc(29);
    line-height: rem-calc(30);
    text-align: center;
    margin-right: rem-calc(8);
  }
}