.tab-list {
  $tab-list-background: mix(white, $header-background, 8%);
  font-size: rem-calc(14);
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  z-index:8;
  background: $tab-list-background;

  &.sticky {
    position: fixed;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
  }

  > .button {
    position: absolute;
    top: 0;
    bottom: 0;
    width: rem-calc(25);
    display: none;
    background: #10233b;
    z-index: 10;

    &:hover {
      background: #10233b;
    }

    &.prev-tab {
      left: 0;
    }

    &.next-tab {
      right: 0;
    }

    &.show {
      display: block;
    }
  }

  .tabcontainer {
    overflow: hidden;
    padding-left: $main-content-padding;
    padding-right: $main-content-padding;
    height: rem-calc(65);
    line-height: rem-calc(65);
  }
  .tab {
    @include border-radius(0);
    color: set-color($tab-list-background, $body-text-color, #fff);
    letter-spacing: .7px;
    font-size: rem-calc(14);
    font-weight: 500;
    padding: 3px 0;
    margin: rem-calc(0 25);
    opacity: .8;

    &.active {
      $new-active-color: set-color($tab-list-background, $tab-active-color, #fff);
      color: $new-active-color;
      font-weight: 700;
      border-bottom: 3px solid $new-active-color;
      opacity: 1;
    }

    &, &:hover, &:active {
      background: transparent;
    }

    &:first-child {
      margin-left: 0;

      [dir="RTL"] & {
        margin-right: 0;
        margin-left:rem-calc(25);
      }
    }
  }

  .-mainheader + & {
    background: #f2f2f2;

    .tab {
      color: $body-text-color;
      opacity: 1;

      &.active {
        color: $tab-active-color;
        border-color: $tab-active-color;
      }
    }
  }

  .-tabbed & {
    background: transparent;
    margin-bottom: 0;

    .tabcontainer {
      padding: 0;
    }

    .tab {
      color: $tab-active-color;
      opacity: 1;
      height: 100%;
      line-height: 94px;
      padding: 0;

      &.active {
        color: $tab-active-color;
        border-color: $tab-active-color;
      }
    }
  }
}
