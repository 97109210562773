/* =====================================
   TSTK20 variables
   ===================================== */
$asphalt: #353b48;
$charcoal: #4a4a4a;
$cyan: #19a1a9;
$darkgray: #979797;
$firetruck: #c0392b;
$forest: #088000;
$gray: #6b6b6b;
$lavender: #8b86ca;
$midnight: #2e1b46;
$palelilacgray: #e9e7ec;
$periwinkle: #575195;
$salmon: #ff5f4e;
$sleet: #7f97ad;
$snow: #fafafa;

$base-font-family: "Inter", sans-serif !default;
$body-text-color: $charcoal !default;
$primary-color: $midnight !default;
$error-color: $firetruck !default;
$disabled-color: $gray;

$header-background: $asphalt !default;
$banner-header-background: #fff !default;
$sliding-panel-header-background: #fff !default;

$nav-transition-duration: 0.3s !default;

/* Buttons */

$button-default-background: #fff !default;
$button-primary-background: $periwinkle !default;
$button-cta-background: #35bb7a !default;
$button-small-background: #bfe0f9 !default;
$button-default-text: $periwinkle !default;
$button-primary-text: #fff !default;

$button-primary-dark-background: #fff !default;
$button-dark-background: transparent !default;

$button-primary-disabled-background: #737373 !default;
$button-default-disabled-background: inherit !default;
$button-disabled-border-color: $button-primary-disabled-background !default;
$button-default-disabled-color: $button-primary-disabled-background !default;
$button-primary-disabled-color: #fff !default;

/* Inputs */
$input-checked: $periwinkle !default;

/* Panels */
$mini-panel-large: 37.5rem; // 600px
$mini-panel-medium: 30rem; // 480px
$mini-panel-small: 18.75rem; // 300px

/* Other Elements */
$snackbar-color: $charcoal !default;
$snackbar-link-color: #85dadf !default;
$tab-active-color: $button-primary-background !default;
$mobile-nav-width: 1023px;
$main-nav-width: 121px !default;
$main-nav-collapsed-width: 86px !default;
$main-content-padding: calc((100vw - #{$main-nav-width}) * 0.05) !default;
$neg-main-content-padding: calc((100vw - #{$main-nav-width}) * -0.05) !default;

$datatableBreakpoint: 800px !default;
